import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";

import ym from "react-yandex-metrika";
import styled, { css } from "styled-components";
import { Warning } from "../../../assets/icons/system/system-icons";
import DelimiterWithText from "../../../components/DelimiterWithText";
import { WindowFormContainer } from "../../../components/layout/elements";
import { Button } from "../../../components/ui/Button";
import ConfirmCodeInput, { IRefConfirmCodeInput } from "../../../components/ui/ConfirmCodeInput";
import CountDownTimer, { IRefCountDownTimer } from "../../../components/ui/CountDownTimer";
import api, { HTTPMessages, getHTTPMessage, s3urls } from "../../../helpers/api";

import { gtmSend, searchParams } from "../../../helpers/url";
import store from "../../../store/Store";
import { windowsStore } from "../../../store/Windows";
import { WindowHeader } from "../../windows/components/WindowHeader";
import CountryList from "../CountryList";
import { CopyRight, Footer, MiniTitle, RegistrationContent } from "../Registration";
import EmailAuth, { WINDOW_ID_EMAIL_AUTH } from '../email/index';
import dayjs from "dayjs";
import { isMobile } from "react-device-detect";
import { Device, Indi, InputContainer, PhoneInput } from "../../../libs";
import { openPaymentWindow } from "../../paywall/Paywall";
import { FreePeriod } from "../../payment/helpers/tariffs";

import ReRecaptcha, { TReReCaptchaApi } from "../../../components/ReRecaptcha";

export const WINDOW_ID_SMS_AUTH = 'WINDOW_ID_SMS_AUTH';
export enum AUTH_STEP {
  PHONE = 1,
  CODE = 2
}

export interface IAuthSmsProps {
  title?: string;
  subtitle?: string;
  codeConfirmSubtitle?: string;
  onlyPhoneNumberConfirm?: boolean;
  onSuccess?: (channel: string, sessionData?: any) => void;
}

export default observer(function SmsAuth(props: IAuthSmsProps) {
  const [step, setStep] = React.useState<AUTH_STEP>(AUTH_STEP.PHONE);
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [code, setCode] = React.useState<string>('');
  const [countryCode, setCountryCode] = React.useState<string>('ru');
  const [showCountryList, setShowCountryList] = React.useState<boolean>(false);
  const [isRegistration, setIsRegistration] = React.useState<boolean>(false);

  const [phoneMessage, setPhoneMessage] = React.useState<string>('');
  const [codeMessage, setCodeMessage] = React.useState<string>('');
  const [isCorrectNumber, setIsCorrectNumber] = React.useState(false);
  const [phoneCountry, setPhoneCountry] = React.useState('');

  const [disabledUi, setDisabledUi] = React.useState<boolean>(true);

  const [codeRequestNumber, setCodeRequestNumber] = React.useState<number>(0);

  const captchaRef = React.useRef<TReReCaptchaApi | null>(null)

  const CodeInputRef = React.useRef<IRefConfirmCodeInput>(null);
  const CountDownRef = React.useRef<IRefCountDownTimer>(null);

  const userId = store.sessionData?.id || '';

	const sp = searchParams();
	const partner = sp.get('partner') ?? undefined

  useEffect(() => {
    gtmSend({ 'event': 'chronos_mobile_number', 'user_id': userId });
  }, []);

  useEffect(() => {
		if (store.isAuth && !props.onlyPhoneNumberConfirm) {
			windowsStore.close(WINDOW_ID_SMS_AUTH);
		}
  }, [store.isAuth]);

  useEffect(() => {
    setDisabledUi(!Boolean(captchaRef.current));
  }, [captchaRef.current]);

  const stateStepErrorHandler = (err: any) => {
    const messages = getHTTPMessage(err._errors)
    step === AUTH_STEP.PHONE
      ? (setPhoneMessage(`${messages}`))
      : (setCodeMessage(`${messages}`))
    throw err
  }

  const successAuthHandler = (sessionData?: any) => {
    if (props.onSuccess) {
      props.onSuccess(phoneNumber, sessionData);
    } else if (isRegistration) {
      const tariff = store.events?.tariffs?.find(item => item.period === 1)!
      openPaymentWindow(tariff, { freePeriod: FreePeriod.TRIAL });
      gtmSend({ 'event': 'chronos_mobile_done', 'user_id': userId });
    }

    windowsStore.close(WINDOW_ID_SMS_AUTH);
  }



  const sendPhone = useCallback(async (phoneNumber: string) => {
    try {
      captchaRef.current?.reset();
      const token = await captchaRef.current?.executeAsync();

      if (token) {
        let result: any;

        if (props.onlyPhoneNumberConfirm) {
          result = await api.verifyPhone(`+${phoneNumber}`, token);
        } else {
					const addition: Indi.AuthenticateWithPhone.Addition = {
						partner
					}

          result = await api.authenticateWithPhone(`+${phoneNumber}`, token, addition);

					if (!result.isExists) {
						await store.logger.createLog({
							date: dayjs().toString(),
							device: isMobile ? Device.MOBILE : Device.DESKTOP,
							phoneNumber,
							country: phoneCountry,
						})
					}
        }

        setCodeRequestNumber(codeRequestNumber + 1);
        setIsRegistration(!result?.isExists)
        return result;
      } else {
        console.error('Captcha error')
        throw Error('Captcha error')
      }
    } catch (err) {
      console.error('sendPhone err -', err)
      throw err
    }
  }, [partner, codeRequestNumber, props.onlyPhoneNumberConfirm, phoneCountry])

  const onSendPhone = (evt?: any) => {
		if (checkNumber()) return

    setPhoneMessage('');
    setCodeMessage('');
    sendPhone(phoneNumber)
      .then(res => {
        setStep(AUTH_STEP.CODE);

				store.logger.updateLog({
					phoneNumber,
					inputWindowOpened: true
				})

        gtmSend({ 'event': 'chronos_mobile_code', 'user_id': userId });
        res?.isExists ? ym('reachGoal','auth-chronos-plus') : ym('reachGoal','reg-chronos-plus')
      })
      .catch((err) => {
        stateStepErrorHandler(err);
      })
  }

  const sendCode = async (phoneNumber: string, code: string) => {
    try {
      let result: any = null;
      let sessionData: any = null;
      if (props.onlyPhoneNumberConfirm) {
        result = await api.verifyPhoneCode(`+${phoneNumber}`, code)
      } else {

        captchaRef.current?.reset();
        const captchaToken = await captchaRef.current?.executeAsync();
        result = await api.signUpConfirm(`+${phoneNumber}`, code, captchaToken);

        if (result.code) {
          sessionData = await store.initLogin(result.code)
        };
      }

      return sessionData;
    } catch (err: any) {
      stateStepErrorHandler(err);
      return null
    }
  }

  const onCodeFilling = (code: string) => {
    setPhoneMessage('');
    setCodeMessage('');

    if (code) {
      setCode(code);
			store.logger.updateLog({
				phoneNumber,
				confirmationCodeWritten: true
			})

      setTimeout(() => {
        sendCode(phoneNumber, `${code}`)
          .then((sessionData: any) => {

            ym('reachGoal','indi_auth_confirmation-code_done');
            gtmSend({'event': 'indi_auth_confirmation-code_done'});

            successAuthHandler(sessionData);
          })
      }, 16);
    }
  }

  const onClose = () => {
    if (step === AUTH_STEP.PHONE && !showCountryList) {
      windowsStore.close(WINDOW_ID_SMS_AUTH);
    } else if (step === AUTH_STEP.CODE) {
      setStep(AUTH_STEP.PHONE);
    } else if (showCountryList) {
      setShowCountryList(false);
    }
  }

  const onFlagClick = () => {
    setShowCountryList(true);
  }

  const onCountrySelect = (countryCode: string) => {
    setPhoneNumber('');
    setCountryCode(countryCode);
    setPhoneMessage('');
    setShowCountryList(false);
  }

  const requestCode = () => {
    setPhoneMessage('');
    setCodeMessage('');

    if (codeRequestNumber >= 5) {
      setCodeMessage(HTTPMessages.default);
      return;
    }

    sendPhone(phoneNumber)
      .then((result) => {
        console.log('again requestCode ok - ', result);
      })
      .catch((err) => {
        stateStepErrorHandler(err);
      })
      .finally(() => {
        CodeInputRef.current?.clear();
        CountDownRef.current?.restart();
      })
  }

	const checkNumber = () => {
		if (!isCorrectNumber) {
			setPhoneMessage(getHTTPMessage(['wrong channel']))
			return true
		}
	}

  const onSuccessMailAuth = (channel: string, sessionData?: any) => {
    props.onSuccess?.(channel, sessionData);
    windowsStore.close(WINDOW_ID_EMAIL_AUTH);
    windowsStore.close(WINDOW_ID_SMS_AUTH);
  }

  const openMailAuth = () => {
    windowsStore.open(
      WINDOW_ID_EMAIL_AUTH,
      <EmailAuth onSuccess={onSuccessMailAuth}/>,
      'fade'
    )
  }

	const onChangeHandler = (value: string) => {
		setPhoneNumber(value)
		setPhoneMessage('')
	}

  return (
    <WindowFormContainer>
      <StyledWindowHeader title={!showCountryList ? (step === AUTH_STEP.PHONE ? (props.title || '') : 'Введите код из смс') : 'Выбор страны'} onBack={onClose} />
      <SmsRegistrationContent paddingBlock={!showCountryList} morePadding={step === AUTH_STEP.CODE}>
        {
          !showCountryList
            ? <>
              <MiniTitle>
                { step === AUTH_STEP.PHONE
                  ? <PhoneMessage>
                      <h2>{!props.title ? 'Введите номер' : ''}</h2>
                      <span>{props.subtitle || 'Для входа или регистрации'}</span>
                    </PhoneMessage>
                  : <CodeMessage>{`${props.codeConfirmSubtitle || 'Мы отправили код для входа'} на номер +${phoneNumber}`}</CodeMessage>
                }
              </MiniTitle>
              {
                step === AUTH_STEP.PHONE
                  ? <InputContainer
                      label=""
                      message={phoneMessage && <><Warning/>{phoneMessage}</>}
                    >
                    <PhoneInput
                      size="big"
                      country={countryCode}
                      lang="ru"
                      value={phoneNumber}
                      onCountryFlagClick={onFlagClick}
                      onChange={onChangeHandler}
                      mode={phoneMessage ? 'error' : 'normal'}
											isCorrectNumber={isCorrectNumber}
											setIsCorrectNumber={setIsCorrectNumber}
											setPhoneCountry={setPhoneCountry}
                    />
                  </InputContainer>
                  : <>
                    <ConfirmCodeInput ref={CodeInputRef} length={4} onFilling={onCodeFilling} errorMessage={codeMessage} />
                    <CountDownTimer ref={CountDownRef} minutes={1} onClick={requestCode} />
                  </>
              }
            </>
            : <CountryList onSelect={onCountrySelect} />
        }

        {!showCountryList &&
          <FooterStyled>
            {step === AUTH_STEP.PHONE &&
              <Button onClick={onSendPhone} disabled={disabledUi}>{'Получить код'}</Button>
            }
            {step === AUTH_STEP.PHONE &&
              <>
              {!props.onlyPhoneNumberConfirm &&
                <>
                  <StyledDelimiterWithText text="Уже есть аккаунт?" />
                  <Button color="dark-gray" onClick={openMailAuth}>Войти по почте</Button>
                </>
              }
                <br />
                <CopyRight>
                  Нажимая кнопку <strong>«Получить код»</strong>, вы соглашетесь&nbsp;
                  <a href={s3urls.privacyPolicyRU} target="_blank" rel="noreferrer">с политикой конфиденциальности</a>&nbsp;
                  и&nbsp;
                  <a href={s3urls.termsOfUseRU} target="_blank" rel="noreferrer">пользовательским соглашением</a>
                </CopyRight>
              </>
            }
          </FooterStyled>
        }
        <ReRecaptcha
          size="invisible"
          badge="bottomleft"
          ref={captchaRef}
        />
      </SmsRegistrationContent>
    </WindowFormContainer>
  );
});

let SmsRegistrationContent = styled(RegistrationContent) <{ paddingBlock: boolean; morePadding: boolean }>`
  box-sizing: border-box;
  height: calc(100% - 3.5rem);
  padding-top: 0;

  ${p => !p.paddingBlock && css`
    padding-left: 0;
    padding-right: 0;
  `}

  ${p => p.morePadding && css`
    padding-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  `}
`;

const StyledWindowHeader = styled(WindowHeader)`
  justify-content: flex-start;
`;

const FooterStyled = styled(Footer)`
  padding: 0;
  margin-top: 1rem;
`;

const PhoneMessage = styled.div`
  position: relative;
  margin: 0 auto;
  text-align: center;

  & > h2 {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--text-primary);
  }
`;

const CodeMessage = styled.span`
  line-height: 150%;
`;

const StyledDelimiterWithText = styled(DelimiterWithText)`
  margin: 1.5rem 0;
`;
