import styled from "styled-components";
import bgPath from '../../../assets/images/purple-bg.jpg';
import { MainPadding } from "../../../components/layout/elements";
import { LogoWhiteIcon } from "../../../assets/icons/system/system-icons";
import { OnboardingForm } from "./onboarding-form";
import { useEffect, useState } from "react";
import { OnboardingLoading } from "./onboarding-loading";
import store from "../../../store/Store";
import { observer } from "mobx-react-lite";

interface OnboardingBlockProps {
  setStep(value: number): void
}

export const OnboardingBlock = observer((props: OnboardingBlockProps) => {
  const [loading, setLoading] = useState(false)
  const profile =  store.profile

  useEffect(() => {
    if (profile.firstName) setLoading(true)
  }, [profile])

  const finishLoading = () => {
    props.setStep(0)
    setLoading(false)
  }

  return (
    <Container>
      <LogoContainer>
        <LogoWhiteIcon/>
      </LogoContainer>

      {
        loading
            ? <OnboardingLoading finishLoading={finishLoading}/>
            : <OnboardingForm startLoading={() => setLoading(true)}/>
      }

    </Container>
  )
}
)
const Container = styled(MainPadding)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-paywall-bg) url(${bgPath});
  background-size: cover;
  background-position: center center;
  padding: 1.5rem;
  z-index: var(--z-index-modal);

  overflow: scroll;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 5rem;
  padding-bottom: 3.5rem;
`
