import React, { useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from './layout/elements';
import openMobilePopupPath from '../assets/images/open-mobile-popup-without-qr.jpg';
import { Button } from './ui/Button'
import { Cross } from '../assets/icons/system/system-icons';
import QRCode from 'qrcode.react';

const openMobilePopupHiddenLS = 'openMobilePopupHidden_v1.0'

export const OpenMobileModal = () => {
  const [closed, setClosed] = useState(
    window.sessionStorage.getItem(openMobilePopupHiddenLS) ||
    window.localStorage.getItem(openMobilePopupHiddenLS)
  );

  const onSessionClose = () => {
    setClosed(String(Date.now()));
    window.sessionStorage.setItem(openMobilePopupHiddenLS, String(Date.now()));
  }

  const onLocalClose = () => {
    onSessionClose();
    window.localStorage.setItem(openMobilePopupHiddenLS, String(Date.now()));
  }

  const qrUrl = window.location.href;

  if(closed) return <></>;

  return <OpenMobileModalContainer>
    <Popup>
      <CloseIcon onClick={onSessionClose}><Cross /></CloseIcon>
      <Phone>
        <Img src={openMobilePopupPath} />
        <QRCodeContainer>
          <QRCode value={qrUrl} size={84} />
        </QRCodeContainer>
      </Phone>

      <Title>С телефона удобнее</Title>
      <Text>Приложение разрабатывалось для мобильных устройств. Чтобы использовать мобильную версию, <span title="Откройте камеру на телефоне и наведите на QR-код">отсканируйте QR-код с помощью камеры телефона.</span></Text>
      <ButtonContainer>
        <Button
          onClick={onLocalClose}
          small
          color="gray"
        >Больше не показывать</Button>
      </ButtonContainer>
    </Popup>
  </OpenMobileModalContainer>
}

const OpenMobileModalContainer = styled(FlexContainer)`
  position: fixed;
  background: rgba(29, 29, 29, 0.1);
  backdrop-filter: blur(36px);
  z-index: 10000;
  inset: 0;
  align-items: center;
  justify-content: center;
`

const Popup = styled(FlexContainer)`
  position: relative;
  padding: 1.5rem;
  background: var(--color-white);
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px rgba(0, 0, 0, 0.11);
  border-radius: 16px;
  width: 30.25rem;
  flex-direction: column;
  align-items: center;
`

const Phone = styled.div`
  display: inline-block;
`

const Img = styled.img`
  width: 15.25rem;
  position: relative;
`

const QRCodeContainer = styled.div`
  position: absolute;
  top: 7rem;
  left: 50%;
  transform: translate(-50%) ;
`

const Title = styled.div`
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin: 0.5rem 0rem 0.75rem;
`

const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.1rem;
  width: 20.375rem;
  text-align: center;

  > span {
    color: var(--color-gradus-blue);
  }
`

const ButtonContainer = styled.div`
  padding: 2.25rem 2rem 1.25rem;
  width: 100%;
`

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border-radius: 50%;
  background: var(--input-background);
  color: var(--text-third);

  > svg {
    width: 1rem;
    height: 1rem;
  }
`
