import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ArrowRight, Ok, StarMenuIcon } from '../../assets/icons/system/system-icons';
import { WindowContainer } from '../../components/layout/elements';
import { Button } from '../../components/ui/Button';
import { windowsStore } from '../../store/Windows';
import store from '../../store/Store';
import { gtmSend } from '../../helpers/url';
import { useBackSoftRestart } from '../../hooks/router-hooks';
import { ROUTES } from '../../helpers/routes';

export const WINDOW_ID_PAYMENT_RESULT: string = 'WINDOW_ID_PAYMENT_RESULT';

interface IPaymentResultProps {
  email: string,
  trial?: boolean,
  exist?: boolean
}

export const openPaymentResultWindow = (email: string, options?: {
  trial?: boolean,
  exist?: boolean
}) => {
  windowsStore.open(
    WINDOW_ID_PAYMENT_RESULT,
    (() => <PaymentResult email={email} exist={options?.exist} trial={options?.trial} />)()
  );
}

export const PaymentResult = (props: IPaymentResultProps) => {
  useBackSoftRestart(ROUTES.PAYMENT_RESULT);

  const userId = store.sessionData?.id || '';

  useEffect(() => {
    if(props.trial) {
      gtmSend({'event': 'ind_prognosis_trial_card_done', 'user_id': userId})
    } else if(props.exist) {
      gtmSend({'event': 'ind_prognosis_old_user', 'user_id': userId})
    }
  }, [props.trial, props.exist, userId])

  const onClose = async () => {
    if(props.trial && props.exist) {
      await store.getProfile();
      windowsStore.closeAll();
    } else if(props.trial || props.exist) {
      store.signOut();
    } else {
      await store.getProfile();
      windowsStore.closeAll();
    }
  }

  let title = '';
  let text = '';

  if(props.trial) {
    title = 'Проверьте почту';
    text = 'Мы отправили доступ к прогнозу на <br/><span>{email}</span>';
  } else if(props.exist) {
    title = 'Проверьте почту';
    text = 'У вас уже есть аккаунт в Chronos. Мы отправили письмо на <span>{email}</span>. <br/>Перейдите по ссылке в письме, чтобы авторизоваться';
  } else {
    title = 'Оплата прошла';
    text = 'Вы можете пользоваться сервисом. Чек мы отправили на <br/><span>{email}</span>';
  }

  text = text.replace('{email}', props.email);

  return (
  <Container>
    <Wrapper>
        <Circle><Ok/></Circle>

        <div>{title}</div>
        <div
          className={'email'}
          dangerouslySetInnerHTML={{__html: text}}>
        </div>

        <Button onClick={onClose}>
          <ButtonContent><ArrowRight />На главную</ButtonContent>
        </Button>
    </Wrapper>

    <Info>
      <div className={'icon-container'}><StarMenuIcon/></div>
      <div className={'text'}>Если у вас возникли проблемы, свяжитесь с поддержкой по адресу support@chronos.mg</div>
    </Info>

  </Container>
)
}

const Container = styled(WindowContainer)`
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0 1rem;

  svg {
    width: 4rem;
    height: 4rem;
    color: var(--color-green);
  }

  .email {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 3rem;
    line-height: 140%;

    span {
      color: var(--color-gradus-blue);
    }
  }
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 3.5rem;
  height: 3.5rem;
  background: #73B230;
  border-radius: 50%;
  margin-bottom: 1rem;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    color: var(--color-white);
  }
`

const Info = styled.div`
  display: flex;
  align-items: start;

  font-size: 0.875rem;
  position: absolute;
  bottom: 1.5rem;
  padding: 1rem;
  margin: 0 1rem;
  background-color: #FEF5E6;
  border-radius: 0.5rem;

  .icon-container {
    margin-right: 0.5rem;

    svg {
      width: 1rem;
      height: 1rem;
      color: #D8A219;
    }
  }

  .text {
    width: 80%;
  }
`

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  letter-spacing: 0.2px;

  & svg {
    color: var(--color-white);
    transform: rotate(180deg);
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`
