import React from "react";
// import { BackIcon, ExternalLinkIcon } from "src/assets/img/icons";
import Phone1 from '../../../assets/images/banners/Phone.png';
import Phone2 from '../../../assets/images/banners/Phone-1.png';
import styled, { css } from "styled-components";
import { ArrowRight } from "../../../assets/icons/system/system-icons";
// import { getUser } from 'src/helpers/localStorage';

export default function IndiBunner({
  link,
  from,
}: {
  link: string;
  from: 'synastry' | 'emotions'
}) {
  // const user = getUser(); 
  const clickHandler = () => {
    const target = from === 'synastry' ? 
    {
      event: 'ind_prognosis_synastry_banner',
      userId: '123abc'
    } : 
    {
      event: 'ind_prognosis_emotions_banner',
      userId: '123abc'
    } 
    // @ts-ignore
    window.dataLayer.push({'event': target.event, 'user_id': target.userId});
    window.open(link);
  }

  return (
    <Container onClick={clickHandler}>
      <ContentWrapper>
        <TextWrapper>
          <Header>
            <div>Chronos</div><StyledPlus>Plus</StyledPlus>
          </Header>
          <Text>
            Персональный ежедневный <br />прогноз по 7 сферам жизни
          </Text>
    
        </TextWrapper>
        <Imgs>
          <Img1 ><img src={Phone1} /></Img1>
          <Img2 ><img src={Phone2} /></Img2>
        </Imgs>
      </ContentWrapper>

      <StyledButton><div>Попробовать 7 дней</div>{<ArrowRight />}</StyledButton>
    </Container>
  )
}

const Container = styled.div<{fromModule?: boolean, isMobile?: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.9rem 1rem;
  gap: 0.75rem;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  color: var(--color-white);
  margin-top: 1.5rem;
  width: 100% !important;
  height: 7.5rem;

  h2 {
    position: relative;
    font-size: 1.5rem;
    font-weight: 500;
    z-index: 2;
  }

  background: radial-gradient(220.97% 2360.96% at -102.33% -98.97%, #000A40 0%, rgba(73, 98, 231, 0.71) 74.23%, #BCC8F6 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(158.54% 1374.04% at -23.29% -28.8%, #4760E7 0%, rgba(73, 98, 231, 0.71) 63.5%, #BCC8F6 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  z-index: 1;

    @media (min-width: 1900px) {
      width: 80%;
    } 

    @media (max-width: 1800px) {
      width: 90%;
    } 

    @media (max-width: 768px) {
      width: 90%;
    } 

  img {
    width: 4rem;
    height: 4rem;
  }
`

const Img1 = styled.div`
  position: relative;
  margin-right: 0.5rem;

  & > img {
      position: relative;
      width: 3.75rem;
      height: 6.8125rem;
      top: 1rem;
    }
`
const Img2 = styled.div`
  position: relative;
  right: 0.8rem;
  & > img {
    position: relative;
      width: 3.75rem;
      height: 8.125rem;
      bottom: 0.8rem;      
    }
`

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

const Header = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  color: var(--color-white);
  font-size: 0.625rem;
  font-weight: 350;
  margin-bottom: 0.4rem;
  z-index: 2;
`

const StyledPlus = styled.span`
  padding: 0.2rem 0.4rem 0.155rem;
  font-size: 0.625rem;
  background: linear-gradient(113.08deg, #6EC7F9 5.98%, #2D5BFF 105.58%);
  border-radius: 0.5rem;
  margin-left: 0.5rem;
`

const Text = styled.div`
  position: relative;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  z-index: 2;
`

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 0.4rem 0.6rem;
  gap: 0.2rem;
  width: fit-content;
  height: 1rem;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 2.5rem;
  z-index: 2;

  div {
    height: 1rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 115%;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const Imgs = styled.div`
  position: absolute;
  width: fit-content;
  display: flex;
  justify-content: center;
  z-index: 1;
  right: -1.5rem;
`
