import React from 'react';
import styled from 'styled-components';
import { Warning } from '../../../assets/icons/system/system-icons';
import { FlexContainer, MainPadding } from '../../../components/layout/elements';

export const MyDayEventRisks = ({
  risks
}: {
  risks: string[]
}) => {
  return <Container>
    <Header>
      <Warning />
      Риски
    </Header>
    <Content>{risks.join('. ')}</Content>
  </Container>
}

const Container = styled(MainPadding)`
  border-radius: 20px;
  background: var(--input-background);
`

const Header = styled(FlexContainer)`

  > svg {
    width: 1rem;
    height: 1rem;
  }
  
  margin-bottom: 0.5rem;
  align-items: center;
  gap: 0.375rem;
  font-size: 0.75rem;
  color: var(--text-secondary);
`

const Content = styled.div`
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--text-primary);
`