import { useState, useEffect } from 'react';
import { SERVICE_ID } from '../helpers/ls/consultations';
import { Indi } from 'src/libs';


const getKeyVal = (key: string) => {
  const item = localStorage.getItem(key);
  try {
    if(item) {
      const parsed = JSON.parse(item);
      return parsed;
    }
    return {};
  } catch {
    return {};
  }
}

const setKeyVal = (key: string, val: object) => {
  localStorage.setItem(key, JSON.stringify(val));
}

export const usePredictionsStorage = (key: string, defaultValue?: any) => {
  const predictions = getKeyVal('predictions');
  const [predictionsField, setPredictionsField] = useState(predictions[key] || defaultValue);

  useEffect(() => {
    setKeyVal('predictions', {
      ...getKeyVal('predictions'),
      [key]: predictionsField,
    });
  }, [predictionsField, key]);

  return [predictionsField, setPredictionsField];
}

const getDataLS = (key: string, dflt: any = null) => {
  try {
    const dataLS = localStorage.getItem(key)
    return dataLS ? JSON.parse(dataLS) : dflt
  } catch(e) {
    console.error(e)
    return dflt
  }
}

export const getPaymentsRestrictionsLS = localStorage.getItem('payments_restrictions')

export const setPaymentsRestrictionsLS = (value: string) => localStorage.setItem('payments_restrictions', value)

export const getServiceId = () => Number(localStorage.getItem(SERVICE_ID))

export const setProductTokenLS = (value: string) => localStorage.setItem('productToken', value)
export const getProductTokenLS = () => Number(localStorage.getItem('productToken'))

export const setProductDataLS = (value: any) => localStorage.setItem('productData', JSON.stringify(value))
export const getProductDataLS = () => getDataLS('productData')

export const setSynastryPartnerDataLS = (value: {}, index: number) => localStorage.setItem(`synastryPartner${index}`, JSON.stringify(value))
export const getSynastryPartnerDataLS = (index: number) => getDataLS(`synastryPartner${index}`)

export const setEmotionsPersonDataLS = (value: {}) => localStorage.setItem('emotionsPersonData', JSON.stringify(value))
export const getEmotionsPersonDataLS = () => getDataLS('emotionsPersonData')

export const setSolarPersonDataLS = (value: {}) => localStorage.setItem('solarPersonData', JSON.stringify(value))
export const getSolarPersonDataLS = () => getDataLS('solarPersonData')

export const setPaymentIdLS = (value: string) => localStorage.setItem('paymentId', value)
export const getPaymentIdLS = () => localStorage.getItem('paymentId')

export const setEmailLS = (value: string) => localStorage.setItem('email', value)
export const getEmailLS = () => localStorage.getItem('email')

export const setPromocodeLS = (value: string) => localStorage.setItem('promocode', value)
export const getPromocodeLS = () => localStorage.getItem('promocode')

export const setPromocodeStateLS = (value: string) => localStorage.setItem('promocodeState', value)
export const getPromocodeStateLS = () => localStorage.getItem('promocodeState')

export const setPromocodeDataLS = (value: any) => localStorage.setItem('promocodeData', JSON.stringify(value))
export const getPromocodeDataLS = () => getDataLS('promocodeData')

export const setConfirmationStepLS = (value: string) => localStorage.setItem('confirmation-step', value)
export const getConfirmationStepLS = () => getDataLS('confirmation-step')

export const setPartnerLS = (value: string) => localStorage.setItem('partner', value)
export const getPartnerLS = () => localStorage.getItem('partner')

// my-day
export const setMyDayLS = (value: any) => localStorage.setItem('my-day', JSON.stringify(value))
export const getMyDayLS = () => getDataLS('my-day', {})

export const getPinnedGroups = () => {
  const myDayLS = getMyDayLS();
  return myDayLS.pinnedGroups || [];
}

// profile
interface ProfileLS {
	rectificationDelay?: string
}

export const setProfileLS = (value: ProfileLS) => localStorage.setItem('profile', JSON.stringify(value))
export const getProfileLS = (): ProfileLS => getDataLS('profile', {})

// consultations
interface Offer {
  type?: Indi.IndiUseOffer.Offers
  hide?: boolean
}

interface Onboarding {
	showedModal?: boolean
	showedInfo?: boolean
}

interface ConsultationLS {
	offers?: Offer[]
  onboarding?: Onboarding
}

export const setConsultationsLS = (value: ConsultationLS) => localStorage.setItem('consultations', JSON.stringify(value))
export const getConsultationsLS = (): ConsultationLS => getDataLS('consultations', {})

// notes
interface Onboarding {
  showedOnboarding?: boolean
}

interface NotesLS {
  onboarding?: Onboarding
}

export const setNotesLS = (value: NotesLS) => localStorage.setItem('notes', JSON.stringify(value))
export const getNotesLS = (): NotesLS => getDataLS('notes', {})

// main-page
interface Widgets {
  collapsed?: boolean
}

interface InfoBanner {
  closed?: boolean
}

interface MainPageLS {
  widgets?: Widgets
  infoBanner?: InfoBanner
}

export const setMainPageLS = (value: MainPageLS) => localStorage.setItem('main-page', JSON.stringify(value))
export const getMainPageLS = (): MainPageLS => getDataLS('main-page', {})

//app
interface AppLS {
  sessionCount: number
  lastSession: string
}

export const setAppLS = (value: AppLS) => localStorage.setItem('app', JSON.stringify(value))
export const getAppLS = (): AppLS => getDataLS('app', {})
