import React from 'react';
import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../components/layout/elements';
import { getDaysAheadString } from '../helpers/events';
import { getFormattedInterval } from '../../../helpers/dates';
import { Harmony, Tension } from '../../../assets/icons/system/system-icons'
import dayjs from 'dayjs';
import store from '../../../store/Store'
import { observer } from 'mobx-react-lite';
import { MyDaySandWatchesIcon } from '../../../assets/icons/myday';
import { Indi } from '../../../libs';

export const MyDayEventNew = observer(({
  event,
  openEvent,
  current,
  isImportant,
	collapsed,
	index,
	refEvent,
	height
}: {
  event: Indi.Events.Event,
  openEvent: (event: Indi.Events.Event) => void;
  current: dayjs.Dayjs,
  isImportant: boolean
	collapsed: boolean,
	index: number
	refEvent: any
	height?: number
}) => {
  const profile = store.profile;

  const dates = getFormattedInterval(
      dayjs(event.startDate).format('DD.MM.YYYY HH:mm:ss'),
      dayjs(event.endDate).format('DD.MM.YYYY HH:mm:ss'),
  )

  const daysAhead = getDaysAheadString(event.startDate, event.endDate, current, profile.email);

  const TypeIcon = event.type === 'harmonic' ? Harmony : Tension;

  const onClick = () => {
    openEvent(event);
  }

  return <Container onClick={onClick}
										isImportant={isImportant}
										collapsed={collapsed}
										index={index}
										height={height}
										ref={index === 0 ? refEvent : null}>
    <Title>{event.title}</Title>
    <Footer>
      {event.duration === 'long' && <DurationIcon>
        <MyDaySandWatchesIcon />
      </DurationIcon> }
      {dates} &bull; {daysAhead.text}
      <TypeIcon />
    </Footer>
  </Container>;
})

const Container = styled(FlexContainer)<{isImportant: boolean, collapsed: boolean, index: number, height?: number}>`
  flex-direction: column;
  padding: 1rem;

  border: 1px solid rgba(0, 0, 0, 0.03);
	box-shadow: 0px 0.75px 2px 0px rgba(137, 146, 172, 0.12), 0px 4px 14px 0px rgba(129, 136, 157, 0.08);
  border-radius: 1.5rem;
	background: var(--color-white);
	transition: 0.2s linear;

  ${p => p.isImportant && css`
    background: var(--color-bg-important-event);
  `}

  ${p => p.collapsed && css`
		transform: ${p.index > 0 ? `translate(0,-${p.index > 2 ? 300 : p.index * 100}px) scale(${1 - p.index * 0.05})` : '0'};
		z-index: ${`${10 - p.index}`};
		height:  ${p.index > 0 ? '6.25rem' : 'inherit'};
  `}

	${p => p.collapsed && p.index > 2 && css`
		display: none;
	`}

	${p => p.index === 0 && css`
		z-index: 10;
	`}
`

const Title = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  line-height: 1.25;
  font-weight: 500;

  margin-bottom: 1rem;
`

const Footer = styled(FlexContainer)`
  align-items: center;

  font-size: 0.75rem;
  color: var(--text-secondary);

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
  }
`

const DurationIcon = styled.div`
  margin-right: 0.375rem;
`
