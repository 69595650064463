import React, { memo } from "react";
import styled from "styled-components";
import { windowsStore } from "../../../../store/Windows";
import { MyDayEvent } from "../my-day-event";
import dayjs, { Dayjs } from "dayjs";
import { useBackWindow } from '../../../../hooks/router-hooks';
import { getFormattedInterval } from "../../../../helpers/dates";
import { Indi } from "../../../../libs";

export const WINDOW_ID_MYDAY_SCROLL_VIEW: string = 'MYDAY_SCROLL_VIEW';

export interface IMyEventsScrollViewprops {
  list: Indi.Events.Event[];
  currentId?: number;
  className?: string;
}

export default memo(function MyEventsScrollView(props: IMyEventsScrollViewprops = {
  list: [],
  currentId: -1
}) {
  useBackWindow(String(props.currentId), WINDOW_ID_MYDAY_SCROLL_VIEW);

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [eventWindowHeight, setEventWindowHeight] = React.useState<number>(0);

  const currentDate: Dayjs = dayjs();
  const [currentIndex, setCurrentIndex] = React.useState<number>(props.list?.findIndex(({ id }) => +id === props.currentId));

  const eventsListResult = React.useMemo(() => {
    return props.list.map((event) => {
      return event;
    });
  }, [props.list]);

  React.useEffect(() => {
    if (!scrollContainerRef.current) return;
    const height = (scrollContainerRef.current as HTMLElement).getBoundingClientRect().height;
    setEventWindowHeight(height);
  }, [scrollContainerRef])

  React.useEffect(() => {
    if (!scrollContainerRef.current) return;

    scrollToEvent(currentIndex);
  }, [eventWindowHeight]);


  const scrollToEvent = (index: number, behavior?: ScrollBehavior) => {
    const element = document.getElementById(`event_window_${index}`);
    if (!element) return;
    setDocumentTitle(props.list[index]);
    element?.scrollIntoView({ block: 'start', inline: 'start' , behavior: behavior || 'auto', })
  }

  const setDocumentTitle = (event: Indi.Events.Event) => {
    const { title, startDate, endDate } = event;
    const dates = getFormattedInterval(
      dayjs(startDate).format('DD.MM.YYYY HH:mm:ss'),
      dayjs(endDate).format('DD.MM.YYYY HH:mm:ss'),
    )
    document.title = `${dates} • ${title}`;
  }

  const onClose = () => {
    windowsStore.close(WINDOW_ID_MYDAY_SCROLL_VIEW);
    document.title = `Chronos Plus — Умный персональный прогноз`;
  }

  return (
    <MyEventsScrollViewContainer className={`may-events-scroll-view${props.className ? ` ${props.className}` : ``}`}>
      <div className="scroll-container" ref={scrollContainerRef}>
        {
          eventsListResult?.map((event: Indi.Events.Event, idx: number, arr: any[]) => {
            let onPrevProps = {};
            let onNextProps = {};
            // наличие этого пропса-метода регулирует видимость кнопки "предыдущее событие" в
            if (idx !== 0) {
              onPrevProps = {
                onPrev: () => scrollToEvent(idx - 1, 'smooth')
              }
            }
            // наличие этого пропса-метода регулирует видимость кнопки "следующее событие" в
            if ((idx + 1) < arr.length) {
              onNextProps = {
                onNext: () => scrollToEvent(idx + 1, 'smooth')
              }
            }
            return <EventScrollWindow id={`event_window_${idx}`} key={`scroll-window-${event.id}`}>
              <StyledMyDayEvent
                key={`myday-detail-${event.id}`}
                event={event}
                current={currentDate}
                opened={true}
                backButton={true}
                onClose={onClose}
                {...onPrevProps}
                {...onNextProps}
              />
            </EventScrollWindow>
          })
        }
      </div>
    </MyEventsScrollViewContainer>
  );
});

const controlPanelHeight = '4rem';

const EventScrollWindow = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* padding: 0.25rem 0; */
`;

const MyEventsScrollViewContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & > .scroll-container {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 2rem); // - ${controlPanelHeight}
    top:0.75rem;
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;

const StyledMyDayEvent = styled(MyDayEvent)`
  /* position: absolute;
  height: 95%;
  top: 0;
  bottom: 0; */
`;
