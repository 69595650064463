import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/elements';
import { stepsCount } from '../Onboarding';
import { ArrowRight } from '../../../assets/icons/system/system-icons';
import { gtmSend } from '../../../helpers/url';
import store from '../../../store/Store';
import { observer } from 'mobx-react-lite';

export const OnboardingPopupNav = observer(({
  step,
  setStep,
  onClose
}: {
  step: number,
  setStep: (step: number) => void,
  onClose: () => void
}) => {
  const userId = store.sessionData?.id;

  const nextButtonText = step === stepsCount ? 'Начать' : 'Дальше';

  const nextStep = () => {
    if(step < stepsCount) {
      setStep(step + 1);
    }
    else {
      gtmSend({'event': `ind_prognosis_onboarding_done`, 'user_id': userId ? userId : ''})
      onClose();
    }
  }

  return <Container>
    <Points>
      {Array(stepsCount).fill(0).map((item, index) => <Point active={(index + 1) === step}></Point>)}
    </Points>
    <Link onClick={nextStep}>{nextButtonText} <ArrowRight /></Link>
  </Container>
})

const Container = styled(FlexContainer)`
  height: 1.5rem;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.675rem;
`

const Points = styled(FlexContainer)`
  gap: 0.5rem;
`

const Point = styled.div<{active?: boolean}>`
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background: ${p => p.active ? 'var(--color-gradus-blue)' : 'var(--popup-line-background)'};
`

const Link = styled(FlexContainer)`
  gap: 0.25rem;
  font-size: 0.875rem;
  color: var(--color-gradus-blue);
  align-items: center;

  > svg {
    margin-top: 0.1rem;
  }
`
