import BlogGroup3Image1 from '../../../assets/images/blog/group_3/3_5329_3.jpg'
import BlogGroup3Image2 from '../../../assets/images/blog/group_3/3_6118_1.jpg'
import BlogGroup4Image1 from '../../../assets/images/blog/group_4/4_6176_1.jpg'
import BlogGroup4Image2 from '../../../assets/images/blog/group_4/4_6176_2.jpg'
import BlogGroup4Image3 from '../../../assets/images/blog/group_4/4_6176_3.jpg'
import BlogGroup4Image4 from '../../../assets/images/blog/group_4/4_6176_4.jpg'
import BlogGroup5Image1 from '../../../assets/images/blog/group_5/4_5542_1.jpg';
import BlogGroup5Image2 from '../../../assets/images/blog/group_5/4_5542_2.jpg';
import BlogGroup5Image3 from '../../../assets/images/blog/group_5/4_5542_3.jpg';
import BlogGroup5Image4 from '../../../assets/images/blog/group_5/4_5542_4.jpg';
import BlogGroup5Image5 from '../../../assets/images/blog/group_5/4_5542_5.jpg';
import BlogGroup5Image6 from '../../../assets/images/blog/group_5/4_5542_6.jpg';
import BlogGroup5Image7 from '../../../assets/images/blog/group_5/4_5542_7.jpg';
import BlogGroup5Image8 from '../../../assets/images/blog/group_5/4_5542_8.jpg';
import BlogGroup5Image9 from '../../../assets/images/blog/group_5/4_5542_9.jpg';
import BlogGroup5Image10 from '../../../assets/images/blog/group_5/4_5542_10.jpg';
import BlogGroup5Image11 from '../../../assets/images/blog/group_5/4_5542_11.jpg';
import BlogGroup5Image12 from '../../../assets/images/blog/group_5/4_5542_12.jpg';
import BlogGroup5Image13 from '../../../assets/images/blog/group_5/4_5542_13.jpg';
import BlogGroup5Image14 from '../../../assets/images/blog/group_5/4_5542_14.jpg';
import AudioFile1 from '../../../assets/audio/blog/1.mp3';
import AudioFile2 from '../../../assets/audio/blog/2.mp3';
import AudioFile3 from '../../../assets/audio/blog/3.mp3';
import AudioFile4 from '../../../assets/audio/blog/4.mp3';
import AudioFile5 from '../../../assets/audio/blog/5.mp3';
import AudioFile6 from '../../../assets/audio/blog/6.mp3';
import AudioFile7 from '../../../assets/audio/blog/7.mp3';

// меняем всегда при обновлении баннеров + меняем id у баннеров
export const updateTime = 1722847178085;

type Theme = 'light' | 'dark';
type Format = 'link' | 'text' | 'audio' | 'video';

export interface Article {
  id: number;
  title: string;
  time?: string;
  link: string;
  background: string;
  description?: string;
  typeFormat: Format;
  audio?: string;
  video?: string;
  author?: string;
  event: string;
  theme: Theme;
  subscription: boolean;
}

export interface Blog {
  title: string;
  articles: Article[];
  rotation: boolean;
}

export const blogGroups: Blog[] = [
  {
		title: 'Новое в блоге',
    rotation: false,
		articles: [
      {
        id: 461761,
        title: 'Кто мой кармический партнер? ',
        link: 'https://teletype.in/@chronos_astrology/h5UxnTiJpR_ ',
        background: BlogGroup4Image1,
        typeFormat: 'link',
        event: 'indi_bunner_1',
        theme: 'light',
        subscription: false
      },
      {
        id: 461762,
        title: '5-й аркан в матрице судьбы: толкование в различных сферах ',
        link: 'https://chronos.mg/blog/5-y-arkan-v-matritse-sudby/',
        background: BlogGroup4Image2,
        typeFormat: 'link',
        event: 'indi_bunner_2',
        theme: 'light',
        subscription: false
      },
      {
        id: 461763,
        title: 'Что можно и чего нельзя делать в коридор затмений-2024 ',
        link: 'https://chronos.mg/blog/koridor-zatmeniy/',
        background: BlogGroup4Image3,
        typeFormat: 'link',
        event: 'indi_bunner_3',
        theme: 'dark',
        subscription: false
      },
      {
        id: 461764,
        title: 'Солнце во Льве: влияние на судьбу и характер',
        link: 'https://chronos.mg/blog/solntse-vo-lve/',
        background: BlogGroup4Image4,
        typeFormat: 'link',
        event: 'indi_bunner_4',
        theme: 'dark',
        subscription: false
      },
    ]
	},
  {
    title: 'Ежедневные практики',
    rotation: true,
    articles: [
      {
        id: 553291,
        title: 'Пустота внутри',
        link: 'https://podprogramma.notion.site/03e584d5dcd6455391a24ab130bb3bd3?pvs=4',
        background: BlogGroup5Image1,
        description: `
        Пустота может быть сигналом истощения: физического или психологического.<br><br>
        Она проявляется в безразличии буквально ко всему: себе, окружению, работе, даже любимому хобби.<br><br>
        Апатия — нормальная реакция организма на обстоятельства; перевозбуждение компенсируется бесстрастием. Но длительное пребывание в подобном состоянии опасно.<br><br>
        Если вы давно не чувствуете воодушевления, попробуйте:<br><br>

        &bull;&nbsp;Выбрать цели<br>
        Начните с небольших и простых, даже если нет желания чего-то достичь. Все, что откладывали и пытались забыть — закройте одну за одной<br><br>

        &bull;&nbsp;Изменить окружение<br>
        Общайтесь с оптимистичными людьми, активными, увлеченными<br><br>

        &bull;&nbsp;Порадовать себя<br>
        Сделайте себе подарок, — любимое блюдо на ужин, интересная книга и тд<br><br>

        &bull;&nbsp;Привести в порядок пространство<br>
        Проведите уборку, избавьтесь от старых вещей, достаньте красивые, которые откладывали на «потом»<br><br>

        &bull;&nbsp;Обратиться за помощью<br>
        Психолог, астролог или хотя бы друг, который может выслушать<br><br>
        И не забывайте: с вами все так. Но вы не должны проходить это в одиночку`,
        typeFormat: 'text',
        event: 'indi_prognosis_practice-emptiness-inside_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553292,
        title: 'Как выплеснуть гнев',
        link: 'https://podprogramma.notion.site/d69f74af91b248e9a53d033b9adea119?pvs=4',
        background: BlogGroup5Image2,
        description: `Гнев — базовая эмоция. Он может быть полезен, если вместе с азартом направлен на достижение цели. Но может и вредить, негативно сказываясь на наших отношениях с людьми. Это не означает, что гнев нужно глубоко прятать и стараться не замечать. Наоборот, его важно прожить — как и любую эмоцию.<br><br>
Назовите то, что чувствуете<br><br>
Раздражение, гнев или злость — признайте, что происходит с вами сейчас. Буквально проговорите «Я испытываю» — это поможет легализовать эмоцию, немного уменьшив напряжение.<br><br>

&bull;&nbsp;Выберите способ прожить<br>
Сфокусируйтесь на чувстве как таковом, а не на причине или оппоненте. И выразите его в здоровом ключе: скомкайте бумагу, покричите в подушку — все, что работает для вас.<br><br>

&bull;&nbsp;Определите эмоцию в теле<br>
Как она выражается? Сужаются глаза, учащается сердцебиение, появляется тяжесть в груди? Попробуйте изменить это на полную противоположность, характерную вам в состоянии покоя.4 Нормализуйте дыханиеСделайте несколько глубоких вдохов и выдохов. Выполните любую дыхательную практику.`,
        typeFormat: 'text',
        event: 'indi_prognosis_practice-anger_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553293,
        title: 'Прогрессивная мышечная релаксация по Джекобсону',
        link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
        background: BlogGroup5Image3,
        description: `Прогрессивная мышечная релаксация по Джекобсону
          На короткий момент создать сильное мышечное напряжение, чтобы мышцы перенапряглись. Вследствие такого интенсивного напряжения мышцы расслабляются. Таким образом мы избавляемся от хронических мышечных блоков, стимулирующих стрессовое состояние.<br><br>
          Необходимо будет напрячь следующие участки тела:<br>
          кисти, предплечья, плечи и надплечья, лопатки, лицо, шею, пресс, ягодицы, промежность, бедра, голени, стопы.<br><br>
          Первый вариант начальный<br>
          Сначала вам нужно будет напрягать все мышечные группы по отдельности.
          Для того, чтобы научиться чувствовать напряжение и расслабление в каждой мышечной группе.<br>
          То есть сначала напрягли кисти, потом предплечья, плечи и т.д.<br><br>
          Второй вариант начальный<br>
          Или же вы можете напрягать мышцы, последовательно подключая другие мышечные группы.<br><br>
          Например:<br>1.&nbsp; Кисти;<br>2. Кисти + предплечья;<br>3. Кисти + предплечья + плечи<br><br>
          И так до полного напряжения всех мышц одновременно.<br><br>
          Третий вариант продвинутый<br><br>
          После того, как вы научитесь хорошо чувствовать напряжение и расслабление своих мышц, вы можете начать практиковать одновременное напряжение всех групп мышц.<br><br>
          В этом случае на выполнение техники вам потребуется около 1-2 минут.<br><br>
          Выглядит это примерно так:<br>1. Сели на стул или легли на кровать;<br>2. Напрягли сразу все мышечные группы;<br>3. Продержали напряжение около 15-ти секунд;<br>4. Сняли напряжение и почувствовали расслабление;<br>5. Обратили внимание на те участки тела, которые не расслабились до конца и если таковые есть, напрягли их отдельно.<br><br>
          Повторить не менее 10 раз.`,
        typeFormat: 'text',
        event: 'indi_prognosis_practice-muscle-relaxation_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553294,
        title: 'Техника «Дыхание по квадрату',
        link: 'https://podprogramma.notion.site/2d61e8df5c8143d89f0a5c4ff6cd2de9?pvs=4',
        background: BlogGroup5Image4,
        description: `Для начала найдите глазами прямоугольный предмет: картину, окно или дверь.<br><br>
         Шаг 1<br>
         Посмотрите в верхний левый угол и вдохните, считая при этом до четырех<br><br>
         Шаг 2<br>
         Переведите взгляд на верхний правый угол и задержите дыхание, считая до четырех<br><br>
         Шаг 3<br>
         Переведите дыхание в нижний правый угол и выдохните, считая до четырех<br><br>
         Шаг 4<br>
         Переведите взгляд в нижний левый угол и сделайте задержку с пустыми легкими, считая до четырех<br><br>
         Повторите этот цикл 10 раз<br><br>
         Постепенно увеличивайте время нахождения в каждом из углов квадрата. Вместо счета можно использовать секундомер.<br><br>
         Попробуйте довести это время до 20 секунд в каждом из 4 углов`,
        typeFormat: 'text',
        event: 'indi_prognosis_practice-breathing-in-a-square_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553295,
        title: 'Диафрагмальное дыхание',
        link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
        background: BlogGroup5Image5,
        description: `Вдох<br><br>
          Начинаем дышать животом. Положите одну руку на живот, чтобы отслеживать, как двигается диафрагма во время дыхания. Вторая рука на груди. Грудь во время дыхания не двигается. Делаем вдох через нос. На вдохе диафрагма уходит вниз, живот надувается, заполняется нижняя часть легких.<br><br>
          Выдох<br><br>
          Выдыхаем через нос. Живот возвращается в исходное положение. На выдохе брюшная стенка идет обратно, воздух выдавливаем через нос и делаем небольшую задержку.<br><br>
          После этого — новый вдох<br><br>
          Если возникают неприятные ощущения в животе, это говорит о том, что диафрагма спазмирована хроническим стрессом.<br><br>
          Продолжайте регулярно практиковать диафрагменное дыхание, и дискомфорт пройдет.<br><br>
          Таким дыханием мы стимулируем блуждающий нерв, который ускоряет процесс включения парасимпатического отдела автономной нервной системы. Наш организм переходит в режим расслабления и восстановления.`,
        typeFormat: 'text',
        event: 'indi_prognosis_practice-diaphragm-beathing_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553296,
        title: 'Источник жизненной силы',
        link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
        background: BlogGroup5Image6,
        typeFormat: 'audio',
        audio: AudioFile1,
        author: 'Ольга Найденова',
        description: `Практика направлена на расслабление физического тела и укрепление биоэнергетической оболочки. Внимание станет сосредоточенным, сознание начнет расширяться, и вместе с этим запустится гармоничная работа всех энергетических центров, чакр.`,
        event: 'indi_prognosis_practice-source-of-power_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553297,
        title: 'Обретение внутреннего центра',
        link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
        background: BlogGroup5Image7,
        typeFormat: 'audio',
        audio: AudioFile2,
        author: 'Дарья Еремина',
        description: `Помогает достичь внутреннего баланса и спокойствия. Она нацелена на укрепление эмоциональной устойчивости и самосознания, создавая состояние внутренней гармонии и ясности мысли.`,
        event: 'indi_prognosis_practice-inner-center_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553298,
        title: 'Волна Изобилия',
        link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
        background: BlogGroup5Image8,
        typeFormat: 'audio',
        audio: AudioFile3,
        author: 'Ольга Найденова',
        description: `Способствует гармонизации отношений с финансами, нацеливая на привлечение изобилия в жизнь. Она помогает укрепить веру в собственные силы и открыться для новых возможностей материального процветания.`,
        event: 'indi_prognosis_practice-abundance_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553299,
        title: 'Прощение себя',
        link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
        background: BlogGroup5Image9,
        typeFormat: 'audio',
        audio: AudioFile4,
        author: 'Ольга Найденова',
        description: `Поможет установить внутренние границы, укрепить силу и энергетику, войти в состояние ресурса. Направлена именно на то, чтобы человек научился прощать себя и брать ответственность за себя и свою жизнь.`,
        event: 'indi_prognosis_practice-forgiveness_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553300,
        title: 'Сила',
        link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
        background: BlogGroup5Image10,
        typeFormat: 'audio',
        audio: AudioFile5,
        author: 'Ольга Найденова',
        description: `Именно эта практика способна привести вас к успеху. Благодаря ей вы всегда будете сосредоточенным и включенным в собственную жизнь. При помощи этой медитации вы обретаете защиту на верхних энергетических центрах, а именно становится сильным ваше ментальное поле.`,
        event: 'indi_prognosis_practice-power_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553301,
        title: 'Наполнение энергией',
        link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
        background: BlogGroup5Image11,
        typeFormat: 'audio',
        audio: AudioFile6,
        author: 'Роман Сафронов',
        description: `Медитативное упражнение, направленное на усиление жизненной энергии и восстановление эмоционального равновесия. Оно помогает в глубоком расслаблении, позволяя телу и разуму зарядиться новой энергией и жизненными силами.`,
        event: 'indi_prognosis_practice-energy_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553302,
        title: 'Притяжение финансов',
        link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
        background: BlogGroup5Image12,
        typeFormat: 'audio',
        audio: AudioFile7,
        author: 'Ольга Найденова',
        description: `Медитация укрепляет уверенность и помогает развить позитивное отношение к деньгам. Она способствует освобождению от финансовых страхов и открытию новых возможностей для улучшения материального положения.`,
        event: 'indi_prognosis_practice-finance_click',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553303,
        title: 'Нейрографика',
        link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
        background: BlogGroup5Image13,
        typeFormat: 'video',
        event: 'indi_prognosis_practice-neurography_click',
        video: 'https://www.youtube.com/watch?v=GDvJxc2DwMw',
        description: `Нейрографика для выхода из стресса, проживания негативных эмоций.`,
        author: 'Юлия Гуляева',
        theme: 'dark',
        subscription: true,
      },
      {
        id: 553304,
        title: 'Нахождение контакта с внутренним ребёнком',
        link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
        background: BlogGroup5Image14,
        typeFormat: 'video',
        event: 'indi_prognosis_practice-connect-w-innerchild_click',
        video: 'https://www.youtube.com/watch?v=qrQLX8J86wo',
        description: `Способствует эмоциональному исцелению, улучшает самооценку и способствует разрешению внутренних конфликтов через связь со своим внутренним ребенком.`,
        author: 'Никита Куклинский',
        theme: 'dark',
        subscription: true,
      },
    ],
  },
  // {
	// 	title: 'Полезные практики',
  //   rotation: false,
	// 	articles: [
	// 		{
	// 		  id: 153291,
	// 			title: 'Будущее без тревоги: как справиться с неизвестностью?',
	// 		  link: 'https://podprogramma.notion.site/03e584d5dcd6455391a24ab130bb3bd3?pvs=4',
	// 		  background: BlogGroup1Image1,
  //       typeFormat: 'link',
	// 		  event: 'ind_prognosis_paid-art-1',
	// 			theme: 'light',
	// 			subscription: true
	// 		},
	// 		{
	// 		  id: 153292,
	// 			title: 'Медитация: трансорфмация жизни через внутреннюю работу',
	// 		  link: 'https://podprogramma.notion.site/d69f74af91b248e9a53d033b9adea119?pvs=4',
	// 		  background: BlogGroup1Image2,
  //       typeFormat: 'link',
	// 		  event: 'ind_prognosis_paid-art-2',
	// 			theme: 'dark',
	// 			subscription: true
	// 		},
	// 		{
	// 		  id: 153293,
	// 			title: 'Что делать, если простые бытовые дела вызывают скуку и тоску?',
	// 		  link: 'https://podprogramma.notion.site/40c249d4026440bdadb1369a4002d1b2?pvs=4',
	// 		  background: BlogGroup1Image3,
  //       typeFormat: 'link',
	// 		  event: 'ind_prognosis_paid-art-3',
	// 			theme: 'light',
	// 			subscription: true
	// 		},
	// 	]
	// },
	// {
	// 	title: 'Про отдых и состояние',
  //   rotation: false,
	// 	articles: [
	// 		{
	// 		  id: 253291,
	// 			title: 'Топ 10 способов выйти из ресурсного состояния',
	// 		  link: 'https://podprogramma.notion.site/10-38bc74aae46346529b1915af6799ce21?pvs=4',
	// 		  background: BlogGroup2Image1,
  //       typeFormat: 'link',
	// 		  event: 'ind_prognosis_paid-art-4',
	// 			theme: 'light',
	// 			subscription: true
	// 		},
	// 		{
	// 		  id: 253292,
	// 			title: 'Почему мы сравниваем себя с другими?',
	// 		  link: 'https://podprogramma.notion.site/2d61e8df5c8143d89f0a5c4ff6cd2de9?pvs=4',
	// 		  background: BlogGroup2Image2,
  //       typeFormat: 'link',
	// 		  event: 'ind_prognosis_paid-art-5',
	// 			theme: 'dark',
	// 			subscription: true
	// 		},
	// 		{
	// 		  id: 253293,
	// 			title: 'Прокрастинация: почему мы откладываем на потом?',
	// 		  link: 'https://podprogramma.notion.site/d3301e56e1bc4cd483f3333857652996?pvs=4',
	// 		  background: BlogGroup2Image3,
  //       typeFormat: 'link',
	// 		  event: 'ind_prognosis_paid-art-6',
	// 			theme: 'light',
	// 			subscription: true
	// 		},
	// 		{
	// 		  id: 253294,
	// 			title: 'Перфекционизм: реально ли отдохнуть с первого раза идеально?',
	// 		  link: 'https://podprogramma.notion.site/da103443dd0c47c583487709ee892cc8?pvs=4',
	// 		  background: BlogGroup2Image4,
  //       typeFormat: 'link',
	// 		  event: 'ind_prognosis_paid-art-7',
	// 			theme: 'dark',
	// 			subscription: true
	// 		},
	// 		{
	// 		  id: 253295,
	// 			title: 'Выспаться не значит отдохнуть',
	// 		  link: 'https://podprogramma.notion.site/7-3d78e58969ba43a78d8218c8441be867?pvs=4',
	// 		  background: BlogGroup2Image5,
  //       typeFormat: 'link',
	// 		  event: 'ind_prognosis_paid-art-8',
	// 			theme: 'dark',
	// 			subscription: true
	// 		},
	// 	]
	// },
	{
		title: 'Блог разработки',
    rotation: false,
    articles: [
      {
        id: 361181,
        title: 'Новая функция в Chronos Plus: Астрособытия ',
        link: 'https://teletype.in/@chronos_astrology/lZLuquEa0TT ',
        background: BlogGroup3Image2,
        typeFormat: 'link',
			  event: 'indi_bunner-how-to-use',
				theme: 'dark',
				subscription: false
			},
      {
        id: 361182,
        title: 'Как пользоваться рекомендациями',
        link: 'https://teletype.in/@chronos_astrology/how-to-apply-recomedations',
        background: BlogGroup3Image1,
        typeFormat: 'link',
        event: 'indi_bunner-how-to-use',
        theme: 'dark',
        subscription: false
      },
		]
	},
];

const BLOG_LS_NAME = 'blog_v1';

type TBlogLS = {
  isRead: Record<number, number>;
  lastOpen: number;
};
const emptyBlogLs = JSON.stringify({
  isRead: {},
  lastOpen: 0,
} as TBlogLS);

export const getBlogLs = (): TBlogLS => {
  const blogLsString = localStorage.getItem(BLOG_LS_NAME) || emptyBlogLs;
  return JSON.parse(blogLsString);
};

export const setBlogLs = (blog: TBlogLS) => {
  localStorage.setItem(BLOG_LS_NAME, JSON.stringify(blog));
};
