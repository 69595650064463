import { isMobile } from "react-device-detect";

const ONBOARDING_LS_NAME = 'onboarding-closed-v1';

export const getOnboardingLS = () => localStorage.getItem(ONBOARDING_LS_NAME);
const setOnboardingLS = () => localStorage.setItem(ONBOARDING_LS_NAME, '1');


export function setOverlayActive(step: number) {
	document.querySelectorAll(`.ob-${step}-b`).forEach(el => {
    el.classList.add('ob-background');
  })

	switch(step) {
		case 0: {
			document.querySelectorAll(`.ob-0`).forEach(el => {
				el.classList.add('ob-overlay');
			})

			document.getElementById('onboarding-my-day')?.classList.add('ob-overlay')

			break
		}
		case 1: {
			document.querySelectorAll(`.ob-1`).forEach(el => {
				el.classList.add('ob-shading-top')
			})

			break
		}
		case 2: {
		  document.querySelectorAll(`.ob-1`).forEach(el => {
				el.classList.add('ob-overlay');
		  })

			break
		}
		case 3: {
			document.querySelectorAll(`.ob-1`).forEach(el => {
				el.classList.add('ob-shading-down')
			})

			document.getElementById('onboarding-my-day')?.classList.remove('ob-overlay')

			break
		}
		case 4: {
			document.querySelectorAll(`.ob-1`).forEach(el => {
				el.classList.add('ob-shading-middle')
			})

			break
		}
	}
}

export function showEvents() {
  const events = document.getElementById('onboarding-my-day')!;
  events?.classList.add('ob-z-index-main')
}

export function hideEvents() {
  const events = document.getElementById('onboarding-my-day')!;
  events?.classList.remove('ob-z-index-main')
}

export function showSphereSwitcher() {
  const events = document.getElementById('ob-sphere-switcher')!;
  events && (events.style.zIndex = '2000');
}

export function hideSphereSwitcher() {
  const events = document.getElementById('ob-sphere-switcher')!;
  events && (events.style.zIndex = 'inherit');
}

export function showCalendar() {
  const events = document.getElementById('my-day-calendar')!;
  events && (events.style.zIndex = '2000');
  events && (events.style.position = 'relative');
  events && (events.style.background = 'white');
}

export function hideCalendar() {
  const events = document.getElementById('my-day-calendar')!;
  events && (events.style.zIndex = 'initial');
  events && (events.style.position = 'initial');
  events && (events.style.background = 'initial');
}

export function setPopupPosition(step: number) {
  const popupEl = document.getElementById('onboarding-popup')!;

	if (!popupEl) return

  if(step === 0) {
    const myEl = document.getElementById('main-page-block-my')!;
    const myElBottom = myEl.getBoundingClientRect().bottom;
    popupEl.style.display = 'block';
    popupEl.style.left = isMobile ? '1rem' : '22rem';
    popupEl.style.top = `calc(${myElBottom}px + 1rem)`;
  } else if(step === 1) {
    const left = (window.innerWidth - popupEl.getBoundingClientRect().width) / 2;
		popupEl.style.left = isMobile ? `${left}px` : '54%';
    popupEl.style.top = `2.25rem`;
  } else if(step === 2) {
    const top = (window.innerHeight - popupEl.getBoundingClientRect().height) / 2;
    popupEl.style.top = `${top}px`;
  } else if(step === 3) {
		popupEl.style.width = '70%';
		const left = (window.innerWidth - popupEl.getBoundingClientRect().width) / 2.2;
    popupEl.style.left = isMobile ? `${left}px`: '51%';
    popupEl.style.top = `1rem`;
    popupEl.style.bottom = `initial`;
  } else if(step === 4) {
		const left = (window.innerWidth - popupEl.getBoundingClientRect().width) / 2.2;
		popupEl.style.left = isMobile ? `${left}px` : '54%';
    popupEl.style.top = `initial`;
    popupEl.style.bottom = `30%`;
  }
}

function onboardingEventsDisable(e: Event) {
  if((e.target as HTMLDivElement).closest('#onboarding-popup')) return;
  e.preventDefault();
  e.stopPropagation();
}

export function disableEvents() {
  document.addEventListener('click', onboardingEventsDisable, true)
  document.addEventListener('touchmove', onboardingEventsDisable, true)
}

export function enableEvents() {
  document.removeEventListener('click', onboardingEventsDisable, true)
  document.removeEventListener('touchmove', onboardingEventsDisable, true)
}

export function clearOnboardings() {
  document.querySelectorAll(`.ob-overlay`).forEach(el => {
    el.classList.remove('ob-overlay');
  })
  document.querySelectorAll(`.ob-background`).forEach(el => {
    el.classList.remove('ob-background');
  })
  document.querySelectorAll(`.ob-shading-top`).forEach(el => {
    el.classList.remove('ob-shading-top');
  })
  document.querySelectorAll(`.ob-shading-middle`).forEach(el => {
    el.classList.remove('ob-shading-middle');
  })
  document.querySelectorAll(`.ob-shading-down`).forEach(el => {
    el.classList.remove('ob-shading-down');
  })

  const popupEl = document.getElementById('onboarding-popup')!;
  popupEl.style.display = 'none';

  hideEvents();
  hideSphereSwitcher();
  hideCalendar();

  setOnboardingLS();
}
