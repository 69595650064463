export const faq = [
	{
		title: 'Каким образом я могу перенести запланированную консультацию?',
		text: 'Для того чтобы перенести время консультации, вам необходимо обратиться к вашему личному менеджеру или написать в службу поддержки по адресу support@chronos.mg не позднее чем за 24 часа до назначенного времени консультации. Перенести консультацию возможно только один раз.'
	},
	{
		title: 'Как происходит процесс отмены консультации?',
		text: 'Если вы желаете отменить консультацию, пожалуйста, уведомите вашего личного менеджера или обратитесь в службу поддержки по адресу support@chronos.mg не менее чем за 24 часа до начала сессии. Возврат средств за отмененную консультацию будет выполнен в течение 5 рабочих дней.'
	},
	{
		title: 'Как можно получить доступ к записи консультации?',
		text: 'После того, как консультация будет завершена, ваш личный менеджер предоставит вам эксклюзивную ссылку для скачивания записи. Вы сможете скачать запись через 24 часа после проведения консультации, а доступ к ней сохранится на протяжении 20 дней.'
	},
	{
		title: 'Что такое анкета ректификации и как ее заполнить?',
		text: 'Анкета ректификации предназначена для уточнения времени вашего рождения и будет отправлена вам менеджером через личный мессенджер. Для максимальной точности астрологического анализа, заполните и отправьте анкету обратно не позднее чем за два дня до назначенной консультации.'
	},
	{
		title: 'Как подключиться к зум-консультации?',
		text: 'На ваш мобильный телефон будет отправлено СМС-уведомление за 30 минут до начала консультации, содержащее ссылку для подключения. В личном кабинете кнопка для подключения к консультации станет активной за 5 минут до ее начала. Убедитесь, что приложение Zoom установлено на вашем устройстве заранее. Если вы не знакомы с Zoom, после подключения к консультации нажмите на иконку микрофона в нижнем левом углу экрана, затем в появившемся окне выберите опцию подключения через «Wi-Fi или сотовые данные».'
	},
	{
		title: 'Возможно ли проведение консультации через Skype или другие приложения?',
		text: 'Предпочтительным каналом для проведения консультаций является Zoom. Однако в исключительных случаях мы можем организовать консультацию через Skype.'
	}
]
