import React, { useState } from 'react'
import styled from 'styled-components'
import { MainPadding, WindowContainer } from '../../components/layout/elements'
import { Button } from '../../components/ui/Button';
import { TextInput } from '../../components/ui/TextInput'
import { windowsStore } from '../../store/Windows';
import { WindowHeader } from '../windows/components/WindowHeader';
import api from '../../helpers/api';
import { observer } from 'mobx-react-lite';
import store from '../../store/Store';
import { gtmSend } from '../../helpers/url';
import { useBackWindow } from '../../hooks/router-hooks';
import { ROUTES } from '../../helpers/routes';

export const WINDOW_ID_UNSUBSCRIBE: string = 'WINDOW_ID_UNSUBSCRIBE';

export default observer(function Unsubscribe() {
  useBackWindow(ROUTES.UNSUBSCRIBE, WINDOW_ID_UNSUBSCRIBE);

  const [message, setMessage] = useState('');
  const userId = store.sessionData?.id;

  const onClose = () => windowsStore.close(WINDOW_ID_UNSUBSCRIBE);

  const unsubscribe = async () => {
    //@ts-ignore
    await api.indiUnsubscribe({ auto_payment: false, reason: message});
    await store.refresh();
    onClose();

    gtmSend({'event': 'ind_prognosis_settings_sub-canceled', 'user_id': userId ? userId : ''})
  }

  return (
    <WindowContainer className='WindowContainer'>
      <WindowHeader title='Отмена подписки' onBack={onClose} />
      <Content>
        <Description>Помогите нам стать лучше. Расскажите, почему вы решили отменить подписку?</Description>

        <TextInput
          value={message}
          onChange={setMessage}
          title={'Свой вариант'}
        />
      </Content>

      <ButtonContainer>
        <Button onClick={unsubscribe}>
          Отменить подписку
        </Button>
      </ButtonContainer>
    </WindowContainer>
  )
})

const Content = styled(MainPadding)`
  flex: 1;

  .text-input-title {
    color: var(--text-primary);
    font-weight: 500;
  }
`

const Description = styled.div`
  font-size: 0.875rem;
  color: var(--text-secondary);
  line-height: 135%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`

const ButtonContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 1rem;
  padding: 0 0.5rem;
`
