import React from 'react';
import styled from 'styled-components';
import { GridContainer } from '../../../components/layout/elements';
import { mainPageBlocks } from '../helpers/blocks';
import { TUserStateKeys } from '../helpers/states';

export const MainPageBlocks = ({
  stateName
}: {
  stateName: TUserStateKeys
}) => {
  const Block1 = mainPageBlocks[stateName].block1;
  const Block2 = mainPageBlocks[stateName].block2;
  const Block3 = mainPageBlocks[stateName].block3;

  return <Container>
    <Block1 />
    <Block2 />
    <Block3Container><Block3 /></Block3Container>
  </Container>
}

const Container = styled(GridContainer)`
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

	padding: 4rem 1rem 1rem 1rem;

  > div:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media (max-height: 700px) {
    padding-bottom: 6rem;
  }
`

const Block3Container = styled.div`
  background: rgba(248, 248, 248, 1);
`
