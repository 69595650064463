import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import bookmarksPath from '../../../assets/images/menu/bookmarks.png';
import consultationsPath from '../../../assets/images/menu/consultations.png';
import mainPath from '../../../assets/images/menu/main.png';
import monthlyReviewPath from '../../../assets/images/menu/monthly_review.png';
import myPath from '../../../assets/images/menu/my.png';
import productsPath from '../../../assets/images/menu/products.png';
import profilePath from '../../../assets/images/menu/profile.png';
import { FlexContainer, GridContainer } from '../../../components/layout/elements';
import { Paywall } from '../../../components/Paywall';
import { gtmSend, searchParams } from '../../../helpers/url';
import { Sections } from '../../../store/Shutter';
import store from '../../../store/Store';
import { windowsStore } from '../../../store/Windows';
import { openConsultations } from '../../consultations/Consultations';
import { openMyDay } from '../../my-day/my-day';
import { Notes, WINDOW_ID_NOTES } from '../../notes/Notes';
import { openSmsRegistrationWindow } from '../../paywall/Paywall';
import { openProducts } from '../../products/Products';
import { openProfile } from '../../profile/Profile';
import { HoroscopeTypes, openPrognoses } from '../../prognoses/prognoses';
import { history } from '../../../MainRouter';


interface MainPageMenuProps {
  rollDown: () => void
}

export const MainPageMenu = observer((props: MainPageMenuProps) => {
  const [showPaywall, setShowPaywall] = useState(false)

  const userId = store.sessionData?.id;
  const activeSection = store.shutter.activeSection
  const info = store.sessionData?.indi;
  const status = store.events.status;

  const sp = searchParams()

  const offerSubscription = Boolean(
    status !== 'active' &&
    info?.trialExpiryDate &&
    dayjs().isAfter(info?.trialExpiryDate)
  );

  const openMain = () => {
    windowsStore.closeAll()
    history.push({pathname: '/', search: sp.toString()})
  }

  const openMyDayHandler = () => {
    store.events.setShowEvents(true)
    openMyDay()
  }

  const openNotes = () => windowsStore.open(
    WINDOW_ID_NOTES,
    <Notes />,
    undefined,
    false,
    true,
  )

  const openMonthlyReview = () => {
    openPrognoses(HoroscopeTypes.MONTH)
  }

  const openProfileHandler = () => {
    if (store.isAuth) {
      openProfile()
    } else {
      openSmsRegistrationWindow()
    }
  }

  const onClickHandler = (section: Sections) => {
    switch(section) {
      case Sections.MAIN:
        openMain()
        break
      case Sections.MY_EVENTS:
        openMyDayHandler()
        break
      case Sections.MY_NOTES:
        openNotes()
        break
      case Sections.PRODUCTS:
        openProducts()
        break
      case Sections.CONSULTATIONS:
        openConsultations()
        break
      case Sections.MONTH_REVIEW:
        openMonthlyReview()
        break
      case Sections.PROFILE:
        openProfileHandler()
        break
      default:
        break
    }

    props.rollDown()
  }

  const sections = [
    {
      key: Sections.MAIN,
      title: 'Главная',
      path: mainPath,
      onClick: () => onClickHandler(Sections.MAIN),
    },
    {
      key: Sections.MY_EVENTS,
      title: 'Мои события',
      path: myPath,
      onClick: () => onClickHandler(Sections.MY_EVENTS),
    },
    {
      key: Sections.MY_NOTES,
      title: 'Заметки',
      path: bookmarksPath,
      onClick: () => onClickHandler(Sections.MY_NOTES),
    },
    {
      key: Sections.PRODUCTS,
      title: 'Продукты',
      path: productsPath,
      onClick: () => onClickHandler(Sections.PRODUCTS),
    },
    {
      key: Sections.CONSULTATIONS,
      title: 'Консультации',
      path: consultationsPath,
      onClick: () => onClickHandler(Sections.CONSULTATIONS),
    },
    {
      key: Sections.MONTH_REVIEW,
      title: 'Обзор месяца',
      path: monthlyReviewPath,
      onClick: () => onClickHandler(Sections.MONTH_REVIEW),
    },
    {
      key: Sections.PROFILE,
      title: 'Профиль',
      path: profilePath,
      onClick: () => onClickHandler(Sections.PROFILE),
    },
  ]

  return <Container>
    {
      sections.map(section => {
        const onClickHandler = (sectionKey: Sections) => {
          if ([Sections.MY_EVENTS, Sections.MY_NOTES].includes(sectionKey) && status !== 'active') {
            setShowPaywall(true)
            return
          }

          section.onClick()
          store.shutter.setActiveSection(sectionKey)
          gtmSend({'event': `indi_hub_${sectionKey}`, 'user_id': userId ? userId : ''})
        }

        return (
          <Item
            key={`menu_section_${section.key}`}
            onClick={() => onClickHandler(section.key)}
          >
            <img src={section.path} alt={section.title} />
            {section.title}
            <Indicator active={activeSection === section.key}><span></span></Indicator>
          </Item>
        )
      })
    }

    {
      showPaywall && (
        createPortal(
          <Paywall
            offerSubscription={offerSubscription}
            onClose={() => setShowPaywall(false)}
          />,
          document.body
        )
      )
    }
  </Container>
})

const Container = styled(GridContainer)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 2.25rem;
  padding: 0 2rem 0.5rem 2rem;
`

const Item = styled(FlexContainer)<{disabled?: boolean}>`
  align-items: center;
  flex-direction: column;

  font-size: 0.625rem;
  font-weight: 500;
  cursor: pointer;

  > img {
    display: block;
    margin-bottom: 0.5rem;
    width: 3.5rem;
  }

  ${p => p.disabled && css`
    pointer-events: none;
    opacity: 0.3;
  `}
`

const Indicator = styled(FlexContainer)<{active: boolean}>`
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 3px;
  padding-top: 0.5rem;

  span {
    display: ${p => p.active ? 'block' : 'none'};
    width: 8px;
    height: 3px;
    background: #1A83FF;
    border-radius: 9px;
  }
`
