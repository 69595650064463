import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {ChevronLeft2 as ChevronLeft} from '../../../assets/icons/system/system-icons';
import store from '../../../store/Store';
import {observer} from 'mobx-react-lite';
import isToday from 'dayjs/plugin/isToday';
import { secondsToHms } from '../../../helpers/dates';
import { getDateText, getFormatText } from '../helpers/services';
import { IServiceDataEx, SessionFormat } from '../../../libs';

dayjs.extend(isToday);

dayjs().isToday();

export default observer(function Title(props: {
  service?: IServiceDataEx | null;
  text?: string;
  dateTime?: string;
  phone?: string;
  disabled?: boolean;
  sessionFormat?: SessionFormat;
}): JSX.Element {
  return <>
    <PageTitle>
      {
				!!props.phone
					? <>
		          Мы отправили код <br/> подтверждения на <br/>
		          <span style={{color: '#4760E7'}}>{props.phone}</span>
        		</>
        	: <>
			        {
								props.service &&
									<Span
					          disabled={props.disabled || false}
					          style={!props.disabled ? {color: '#4760E7'} : {}}
					          onClick={() => { if (!props.disabled) store.showHandler.servicesModal = true }}
					        >
										{props.sessionFormat ? `${getFormatText(props.sessionFormat)} формат.` : 'Устный'} {props.service.name}&nbsp;{!props.disabled ? <ChevronLeft /> : ''}
									</Span>
							}
			        {
								props.text && <p>{props.text}</p>
							}
			        {
								props.dateTime &&
									<>
					          <p>
					            <Span
					              disabled={props.disabled || false}
					              style={!props.disabled ? {color: '#4760E7'} : {}}
					              onClick={() => { if (!props.disabled) store.showHandler.scheduleModal = true }}
					            >
					              {getDateText(props.dateTime)}&nbsp;{!props.disabled ? <ChevronLeft /> : ''}&nbsp;
					            </Span> {props.sessionFormat && props.sessionFormat === SessionFormat.Offline ? '' : secondsToHms(props.service?.duration || 60)}
					          </p>
				        	</>
							}
      			</>
			}
    </PageTitle>
  </>;
});

const PageTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.25rem;
  color: var(--text-primary);
  line-height: 2rem;
  margin-bottom: 16px;

  p {
    display: flex;
  }
`;

const Span = styled.span<{disabled: boolean}>`
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  align-items: center;
  display: flex;
  > svg {
    transform: rotate(-90deg);
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: #4760E7;
      fill-opacity: 1;
    }
  }
`;
