export const texts = [
	{
    title: 'Обновленный «Мой день»',
    text: 'Мы переработали раздел «Мой день», чтобы вам было удобнее изучать прогнозы и планировать. Узнайте больше о новых изменениях',
		titleTrialActive: 'Сделайте первый шаг',
    textTrialActive: 'Откройте "Мой день", чтобы посмотреть, какие небесные события сегодня влияют на вас'
  },
  {
    title: 'Мой день',
    text: 'Здесь находится Обзор дня и перечислены все события, влияющие на вас.'
  },
  {
    title: 'События бывают напряженными - обращайте на них особое внимание'
  },
  {
    title: 'Календарь событий',
    text: 'Планируйте дела с помощью календаря — смотрите события на определенный день'
  },
  {
    title: 'Переключайте дни',
    text: 'Свайпайте влево или вправо по центральной части экрана, чтобы переключаться между днями и планировать свою неделю'
  }
];

export const events = [
  {
    title: 'Время обновлений в работе и применения новых методов',
    period: '12 ноя — 14 ноя • ещё 12 дней',
    type: 'tense'
  },
]
