import styled from "styled-components"
import { MainPadding, WindowContainer } from "../../components/layout/elements"
import { WindowHeader } from "../windows/components/WindowHeader"
import { ContentsList, Item } from "../../components/ContentsList"
import InfoBlock, { BlockStatus } from "../../components/InfoBlock"
import { windowsStore } from "../../store/Windows"
import { useBackWindow } from "../../hooks/router-hooks"
import { ROUTES } from "../../helpers/routes"
import { Warning3 as WarningIcon } from "../../assets/icons/system/system-icons"
import { Backgrounds } from "../../components/banners/Banner/bg"
import { Banner } from "../../components/banners/Banner/Banner"
import { ChevronRightIcon } from '../../assets/icons/system/system-icons'
import store from "../../store/Store"
import { Button } from "../../components/ui/Button"
import { observer } from "mobx-react-lite"
import dayjs from "dayjs"
import { openPaymentPaywallWindow } from "../payment/components/payment-paywall"
import { TariffPeriod } from "../payment/helpers/tariffs"
import { gtmSend } from "../../helpers/url"
import Unsubscribe, { WINDOW_ID_UNSUBSCRIBE } from "../unsubscribe/Unsubscribe"
import { SubscriptionStatus } from "../../store/Subscribtion"
import { useEffect, useMemo, useState } from "react"


export const WINDOW_ID_SUBSCRIPTION_MANAGEMENT: string = 'WINDOW_ID_SUBSCRIPTION_MANAGEMENT'

export const openSubscriptionManagement = () => windowsStore.open(
  WINDOW_ID_SUBSCRIPTION_MANAGEMENT,
  <SubscriptionManagement/>,
)

export const SubscriptionManagement = observer(() => {
  useBackWindow(ROUTES.SUBSCRIPTION_MANAGEMENT, WINDOW_ID_SUBSCRIPTION_MANAGEMENT)

  const [subscription, setSubscription] = useState<Item[]>([])

  const subscriptionInfo = store.sessionData?.indi
  const subscriptionStatus = store.subscription.subscriptionStatus
  const tariffs = store.events.tariffs
  const status = store.events.status
  const isActive = status === 'active'
  const showYearBanner = !isActive || tariffs.find(({id}) => id === subscriptionInfo?.tariffId)?.period !== 12
  const userId = store.sessionData?.id;
  const autoPayment = subscriptionInfo?.autoPayment

  const stage = useMemo(() => {
    switch (subscriptionStatus) {
      case SubscriptionStatus.TRIAL:
        return {
          text: SubscriptionStatus.TRIAL,
          color: 'var(--color-blue-magenta)',
          type: 'trial',
        }
      case SubscriptionStatus.ACTIVE:
        return {
          text: SubscriptionStatus.ACTIVE,
          color: 'var(--color-blue-magenta)',
          type: 'trial',
        }
      case SubscriptionStatus.NOT_PASSED:
        return {
          text: SubscriptionStatus.NOT_PASSED,
          color: 'var(--color-secondary-red)',
          type: 'not_passed',
        }
      default:
        return {
          text: SubscriptionStatus.INACTIVE,
          color: 'var(--color-secondary-red)',
          type: 'inactive',
        }
    }
  }, [subscriptionStatus])

  const cancelledSubscription = stage.type === 'inactive' && subscriptionInfo?.expiryDate

  const interval = useMemo(() => {
    const period = tariffs.find(({id}) => id === subscriptionInfo?.subscriptionId)?.period

    switch (period) {
      case 1:
        return 'Ежемесячная'
      case 6:
        return 'Полугодовая'
      case 12:
        return 'Годовая'
      default:
        return ''
    }
  }, [tariffs, subscriptionInfo])

  const paymentDay = useMemo(() => {
    return isActive ? dayjs(subscriptionInfo?.expiryDate).format('DD MMM YYYY') : ''
  }, [isActive, subscriptionInfo?.expiryDate])

  const amount = useMemo(() => {
    return isActive ? `${subscriptionInfo?.amount} ₽` : ''
  }, [isActive, subscriptionInfo?.amount])

  const paymentMethod = useMemo(() => {
    return isActive ? `${subscriptionInfo?.cardType}, **** ${subscriptionInfo?.cardLastFour}` : ''
  }, [isActive, subscriptionInfo?.cardType, subscriptionInfo?.cardLastFour])

  useEffect(() => {
    const list: Item[] = [
      {
        title: 'Chronos Plus',
        text: stage.text,
        colorText: stage.color,
      },
      {
        title: 'Периодичность',
        text: interval,
      },
      {
        title: 'Сумма списания',
        text: amount,
      },
      {
        title: 'Метод оплаты',
        text: paymentMethod,
      },
    ]

    if (autoPayment) {
      list.splice(2, 0, {
        title: 'Следующее списание',
        text: paymentDay,
      })
    }

    setSubscription(list)
  }, [autoPayment, amount, stage, interval, paymentDay, paymentMethod])

  const openUnsubscribeWindow = () => {
    windowsStore.open(
      WINDOW_ID_UNSUBSCRIBE,
      <Unsubscribe />
    )

    gtmSend({'event': 'ind_prognosis_settings_cancel-sub-click-button', 'user_id': userId ? userId : ''})
  }

  const unsubscribe: Item[]  = [
    {
      title: 'Отменить подписку',
      goTo: openUnsubscribeWindow,
    },
  ]

  const onClose = () => windowsStore.close(WINDOW_ID_SUBSCRIPTION_MANAGEMENT)

  const subscribe = async () => {
    await store.subscription.updateSubscribe(true)
    gtmSend({'event': 'indi_profile_restore_subs', 'user_id': userId})
  }

  const buyYearSubscription = () => {
    const onClose = async () => {
      await store.refresh()
      windowsStore.closeAll()
    }

    openPaymentPaywallWindow(TariffPeriod.YEAR, onClose)
  }

  return (
    <WindowContainer>
      <WindowHeader title={'Управление подпиской'} onBack={onClose}/>
      <Container>
        <Content>
          {
            stage.type === 'not_passed' && (
              <InfoBlock
                icon={<WarningIcon />}
                status={BlockStatus.ALERT}
              >
                Попробуем списать деньги еще несколько раз, затем подписка будет отменена
              </InfoBlock>
            )
          }

          <ContentsList
            items={subscription}
          />

          {
            subscriptionInfo?.autoPayment && !cancelledSubscription && (
              <ContentsList
                items={unsubscribe}
              />
            )
          }

          {
            cancelledSubscription && (
              <UnsubscribeInfo>Возобновите подписку, чтобы продолжить пользоваться преимуществами Chronos Plus</UnsubscribeInfo>
            )
          }

          {
            showYearBanner && (
              <StyledBanner
                title='Получите 2 месяца в подарок'
                text='При оплате годовой подписки Chronos Plus'
                buttonContent={<ButtonContent>Узнать больше <ChevronRightIcon/></ButtonContent>}
                onClick={buyYearSubscription}
                background={Backgrounds.OFFER}
              />
            )
          }
        </Content>

        <Footer>
          {
            (!subscriptionInfo?.autoPayment || cancelledSubscription) && (
              <StyledButton onClick={subscribe}>Возобновить подписку</StyledButton>
            )
          }
        </Footer>
      </Container>


    </WindowContainer>
  )
})

const Container = styled(MainPadding)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`

const StyledBanner = styled(Banner)`
  margin-top: 0.5rem;

  .title {
    font-size: 1.125rem;
    color: var(--text-primary);
    width: 100%;
  }

  .text {
    color: var(--text-primary);
  }

  .banner_button {
    margin-top: 1.25rem;
    width: fit-content;
  }
`

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.875rem;
  padding: 0 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-white);
  }
`

const UnsubscribeInfo = styled.div`
  font-size: 0.875rem;
  color: var(--text-third);
  margin-top: 1rem;
`

const StyledButton = styled(Button)`
  margin-top: 1rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Footer = styled.div``
