import styled from 'styled-components';
import { MyDayBadIcon, MyDayGoodIcon } from '../../../assets/icons/myday';
import { FlexContainer } from '../../../components/layout/elements';
import { List } from '../../../components/ui/List';
import { IDayDescription } from '../../../store/Events';


export const MyDayEventDescription = ({
  data
}: {
  data: IDayDescription | null;
}) => {
  return data ? <Container onClick={() => {}} opened={true} className="ob-z-index">

    <Description>
      {data.atmosphere && <div>{'Атмосфера сегодняшнего дня наполнена ' + data.atmosphere}.</div>}
      {data.attention.length > 0 && <div>{'Также вам следует обратить особое внимание на ' + data.attention.join(', ')}.</div>}
      {data.importance && <div>{'Особое влияние этого дня отразится на ' + data.importance.description1 + ', при этом не забывайте ' + data.importance.description2}.</div>}
    </Description>

    <Blocks>
      {
				data.toDo.length > 0 && <List
																	icon={<MyDayGoodIcon/>}
																	title={'Дела на сегодня'}
																	items={data.toDo}/>
			}

      {
				data.notToDo.length > 0 && <List
																			icon={<MyDayBadIcon/>}
																			title={'Избегайте'}
																			items={data.notToDo}/>
			}
    </Blocks>

  </Container> : null
}

const Container = styled(FlexContainer)<{importantBg?: string, opened: boolean}>`
  flex-direction: column;
  gap: 10px;
  line-height: 1.45;
  margin: 0.5rem 0 2rem 0;

  &:last-child {
    margin-bottom: 6px;
  }
`

const Description = styled(FlexContainer)`
  flex-direction: column;
  margin-bottom: 1.25rem;
  gap: 1rem;
`

const Blocks = styled(FlexContainer)`
  flex-direction: column;
  gap: 1.5rem;
`
