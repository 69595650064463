import { observer } from 'mobx-react-lite';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { LogoBlackIcon } from '../../../assets/icons/system/system-icons';
import prognosisForYouPath from '../../../assets/images/main-banner/prognosis-for-you.png';
import resubscribePath from '../../../assets/images/main-banner/re-subscribe.png';
import backgroundBluePath from '../../../assets/images/main-banner/bg-blue.jpg';
import backgroundRedPath from '../../../assets/images/main-banner/bg-red.jpg';
import { FlexContainer } from '../../../components/layout/elements';
import { Button } from '../../../components/ui/Button';
import { gtmSend } from '../../../helpers/url';
import store from '../../../store/Store';
import { windowsStore } from '../../../store/Windows';
import { Paywall, WINDOW_ID_PAYWALL } from '../../paywall/Paywall';
import { Indi, searchParams } from 'src/libs';
import { PayLanding, WINDOW_ID_PAYLANDING } from '../../paywall/PayLanding';
import { isMobile } from 'react-device-detect';
import { numberWord } from '../../../helpers/strings';


import { SubscribeModes, FreePeriod } from '../../payment/helpers/tariffs';

interface IMainPageBlocksSubscriptionBannerProps {
  page?: string;
}

export const openPaywallWindow = (freePeriod?: FreePeriod) => {
  windowsStore.open(
    WINDOW_ID_PAYWALL,
    <Paywall freePeriod={freePeriod} />,
    'fade'
  )
}

export const openPayLandingWindow = (freePeriod?: FreePeriod) => {
  windowsStore.open(
    WINDOW_ID_PAYLANDING,
    <PayLanding freePeriod={freePeriod} />,
    'fade'
  )
}



const blueGradient = 'linear-gradient(264.22deg, rgba(118, 184, 255, 0.8) 5.77%, rgba(152, 202, 255, 0) 99.67%), linear-gradient(0deg, #844BFD, #844BFD)'
const redGradient = 'linear-gradient(265.95deg, rgba(231, 182, 110, 0.8) 14.59%, rgba(231, 182, 110, 0) 78.75%), linear-gradient(0deg, #EC91E8, #EC91E8)'


const sp = searchParams();
const period = Number(sp.get('d'));
const trialPeriod = [3,14].includes(period) ? period : 7;


const titles = {
	trial: {
		title: 'Персональные ежедневные прогнозы',
		subtitle: 'Рекомендации по самым важным сферам жизни на месяцы вперед',
		buttonTitle: `Попробовать ${trialPeriod} ${numberWord(trialPeriod!, ['день', 'дня', 'дней'])}`,
	},
	subscribe: {
		title: 'Персональные ежедневные прогнозы',
		subtitle: 'Рекомендации по самым важным сферам жизни на месяцы вперед',
		buttonTitle: 'Оформить подписку',
	},
	resubscribe: {
		title: 'Возобновите подписку',
		subtitle: 'Чтобы сохранить уверенность и контроль над будущим в семи ключевых сферах',
		buttonTitle: 'Возобновить подписку',
	},
	trialFourteen: {
		title: '14 дней Chronos Plus в подарок',
		subtitle: 'Рекомендации по самым важным сферам жизни на месяцы вперед',
		buttonTitle: 'Активировать 14 дней',
	}
}

export const MainPageBlocksSubscriptionBanner = observer((props: IMainPageBlocksSubscriptionBannerProps) => {
  const isAuthFinished = store.isAuthFinished
  const status = store.events.status
  const userId = store.sessionData?.id || ''
  const info = store.sessionData?.indi

  const getMode = () => {
    const hasFourteenTrialOffer = info?.offers
      .find(item => item.type === Indi.IndiUseOffer.Offers.FOURTEEN_FREE_DAYS)

    if (hasFourteenTrialOffer?.available) {
      return SubscribeModes.TRIAL_FOURTEEN
    }

		if (!info?.trialExpiryDate) {
			return SubscribeModes.TRIAL
		}

		if (info?.trialExpiryDate !== info?.expiryDate) {
			return SubscribeModes.RESUBSCRIBE
		}

		return SubscribeModes.SUBSCRIBE
	}

  const getFreePeriod = () => {
    if (getMode() === SubscribeModes.TRIAL_FOURTEEN) {
      return FreePeriod.FOURTEEN_FREE_DAYS
    }

    if (getMode() === SubscribeModes.TRIAL) {
      return FreePeriod.TRIAL
    }
  }

  const onClickHandler = () => {
	openPaywallWindow(getFreePeriod());
    // openPayLandingWindow(getFreePeriod());
    gtmSendHandler()
  }

	const getImage = () => {
		if ([SubscribeModes.RESUBSCRIBE, SubscribeModes.TRIAL_FOURTEEN].includes(getMode())) {
			return resubscribePath
		} else {
			return prognosisForYouPath
		}
	}

	const getBackground = () => {
		if (getMode() === SubscribeModes.RESUBSCRIBE) {
			return backgroundRedPath
		} else {
			return backgroundBluePath
		}
	}

  const gtmSendHandler = () => {
    if (getMode() === SubscribeModes.TRIAL_FOURTEEN) {
      gtmSend({'event': 'indi_main-sub-bunner-14days_click', 'user_id': userId})
    } else {
      gtmSend({'event': `ind_prognosis_${props.page || 'general'}_banner_click`, 'user_id': userId})
    }
  }

  if (!isAuthFinished || !status) return <div><Skeleton style={{'height': '374px', 'width': '100%', 'borderRadius': '20px'}} /></div>

  return (
		<Container
			onClick={onClickHandler}
			className="ob-0"
			background={getBackground()}
		>
			<LogoBlackIcon/>
	    <Image src={getImage()}/>
	    <Title>{titles[getMode()].title}</Title>
	    <SubTitle>{titles[getMode()].subtitle}</SubTitle>

	    <StyledButton
				onClick={() => {}}
				background={getMode() === SubscribeModes.RESUBSCRIBE ? redGradient : blueGradient}
      >
				{titles[getMode()].buttonTitle}
			</StyledButton>
  	</Container>
	)
})

const Container = styled(FlexContainer)<{background: string}>`
	flex-direction: column;
	align-items: center;

	text-align: center;
	background: ${p => `url(${p.background}) -10px 0/cover no-repeat`};
	box-shadow: 0px 0.75px 2px 0px rgba(137, 146, 172, 0.12);
	box-shadow: 0px 4px 14px 0px rgba(129, 136, 157, 0.08);
  border-radius: 20px;
  padding: 1rem;
`

const Image = styled.img`
  width: ${isMobile ? '5.75rem' : '7.75rem'};
	margin-top: 2rem;
`

const Title = styled.div`
  margin-bottom: 0.625rem;
  font-weight: 500;
	margin-top: 2rem;
`

const SubTitle = styled.div`
  margin-bottom: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0 1rem;
`

const StyledButton = styled(Button)<{background: string}>`
	background: ${p => p.background};
`
