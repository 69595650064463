import styled, { css } from 'styled-components';
import { eventsOptions } from '../helpers/events';
import { FlexContainer } from '../../../components/layout/elements';
import { MyDayEventNew } from './my-day-event-new';
import dayjs from 'dayjs';
import { Pin } from '../../../components/ui/Pin';
import { memo, useLayoutEffect, useRef, useState } from 'react';
import { Button } from '../../../components/ui/Button';
import { MyDayNoEvents } from './my-day-no-events';
import { Indi } from '../../../libs';


export const MyDayEventsGroup = memo(({
  group,
  events,
  currentDate,
  openEvent,
	pinEventsGroup,
	pinned
}: {
  group: Indi.Events.Sphere
  events: Indi.Events.Event[],
  currentDate: dayjs.Dayjs,
  openEvent: (event: Indi.Events.Event) => void
	pinEventsGroup?: (pinned: boolean) => void
	pinned?: boolean
}) => {
	const [collapsed, setCollapsed] = useState(true)
	const [heightEventContainer, setHeightEventContainer] = useState(0)

	const refEvent = useRef<HTMLDivElement | null>(null)

	useLayoutEffect(() => {
		refEvent?.current && setHeightEventContainer(refEvent?.current?.offsetHeight)
	}, [refEvent, currentDate])

  if(events.length <= 0) return null;

  const eventOptions = eventsOptions[group];
  const SmallIcon = eventOptions.iconSmall;

	const showAllEvents = () => {
		if (collapsed && events.length > 1) setCollapsed(false)
	}
	const collapseEvents = () => setCollapsed(true)

	const pin = () => {
		pinEventsGroup && pinEventsGroup(Boolean(pinned))
	}

	const openEventHandler = (event: Indi.Events.Event) => {
		if (!collapsed || events.length === 1) openEvent(event)
	}

  const noEventsType =  group === 'important' ? group : 'group';

	const getGap = () => {
		const stacks = events.length > 3 ? 3 : events.length

		switch (stacks) {
			case 3:
				return 75
			case 2:
				return 65
			default:
				return 55
		}
	}

	return <Container collapsed={collapsed}
										height={heightEventContainer}
										gap={getGap()}>
    <Header>
      <HeaderLeft>
        <SmallIcon />
        <span style={{color: group === 'important' ? 'var(--color-gray)' : eventOptions.textColor}}>{eventOptions.title}</span>
      </HeaderLeft>
      <HeaderRight>
				{!collapsed && <ButtonShowLess onClick={collapseEvents}>Показать меньше</ButtonShowLess>}
        {group !== 'important' && <Pin pinned={Boolean(pinned)} setPinned={pin}/>}
      </HeaderRight>
    </Header>

    {events.length === 0 && <MyDayNoEvents type={noEventsType} currentDate={currentDate} />}

    {events.length > 0 && <Events onClick={showAllEvents}>
      {events.map((event, index) => <MyDayEventNew
				key={`${group}_my-day-event-new_${index}`}
        event={event}
        openEvent={openEventHandler}
        current={currentDate}
        isImportant={Boolean(event.isImportant)}
				collapsed={collapsed}
				index={index}
				refEvent={refEvent}
				height={heightEventContainer}
      />)}
    </Events>}


    <Footer>
      {!collapsed && <ButtonShowLess onClick={collapseEvents}>Показать меньше</ButtonShowLess>}
    </Footer>
  </Container>
})

const Container = styled(FlexContainer)<{collapsed: boolean, gap: number, height: number}>`
  flex: 0.75rem;
  flex-direction: column;
  height: 100%;

	${p => (p.collapsed && p.height > 0) && css`
		min-height: ${`${p.height + p.gap}px`};
    overflow: hidden;
	`}
`

const Header = styled(FlexContainer)`
  align-items: center;
  margin-bottom: 0.75rem;

  > svg {
    &:first-child {
      margin-right: 0.375rem;
    }
  }
`

const HeaderLeft = styled(FlexContainer)`
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }

  span {
    white-space: nowrap;
  }
`

const HeaderRight = styled(FlexContainer)`
  justify-content: end;
  align-items: center;

  margin-left: auto;
`

const Events = styled(FlexContainer)`
  flex-direction: column;
  gap: 0.75rem;
	position: relative;
`

const Footer = styled(FlexContainer)`
  justify-content: end;
  align-items: center;

  width: 100%;
	padding: 0.5rem 0;
`

const ButtonShowLess = styled(Button)`
  display: block;
  width: fit-content;
  padding: 0.375rem 0.5rem;
  color: var(--text-primary);
  font-size: 0.75rem;
  font-weight: 500;
  background: var(--color-white);
  filter: var(--shadow);
`
