import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import ym from 'react-yandex-metrika';

import store from '../../../store/Store';
import Input from '../../../components/ui/Input';
import ButtonSwitcher, { IOption } from '../../../components/ui/ButtonSwitcher';
import StarIcon from '../../../components/ui/StarIcon';
import { InfoGray as InfoIcon, MinusStatusIcon } from '../../../assets/icons/system/system-icons';
import { searchParams } from '../../../helpers/url';
import { history } from '../../../MainRouter';
import { Button, ISession, SessionStatus } from '../../../libs';


const STARS = [1, 2, 3, 4, 5];
const RATE_OPTIONS: IOption[] = [
  {
    label: 'Оставить отзыв',
    value: 0
  },
  {
    label: 'Пожаловаться',
    value: 1
  }
];

const Rate = ({
  session,
}: {
  session: ISession;
}) => {
  const [canRate, setCanRate] = useState(false);
  const [rate, setRate] = useState(0);
  const [rateType, setRateType] = useState(0);
  const [comment, setComment] = useState('');

  const isCancelled = session?.data?.status === SessionStatus.Canceled;
  const isDisabled = !!(session?.data?.comment || session.data.rate !== 0);

  const sp = searchParams()

  useEffect(() => {
    onSessionChange();
  }, [session]);

  const onSessionChange = async () => {
    if (session) {
      const start = dayjs(session.start);
      const end = start.add(session.data.duration, 'minutes');
      const ratingPossible = end.add(1, 'day').isAfter(dayjs());

      setComment(session.data.comment);
      setRate(session.data.rate);
      setRateType(session.data.rate === -1 ? 1 : 0);
      setCanRate(ratingPossible);
    }
  }

  const sendRate = async () => {
    if (session) {
      ym('reachGoal', 'Consult_button-click_send-feedback');

      await store.updateSession(session.id, {
        ...session,
        data: {
          ...session.data,
          comment,
          rate: rateType === 0 ? rate : -1,
        }
      });
      history.push({pathname: '/services', search: sp.toString()});
    }
  };

  return <>
      {isCancelled &&
        <>
          <CancelledText>Сеанс отменен<MinusStatusIcon /></CancelledText>
          <CancelledDescription>
            <InfoIcon />
            <p>Деньги вернутся на ваш счет в течение 3 дней.</p>
          </CancelledDescription>
        </>
      }
      {(rateType === 0 && ((session?.data?.status === SessionStatus.Paid && canRate))) && <>
        {!isCancelled &&
          <ButtonSwitcher
            options={RATE_OPTIONS}
            value={rateType}
            onChange={setRateType}
            disabled={isDisabled}
          />
        }
        {!isDisabled &&
          <Text>
            {isCancelled ? 'Нам важно знать ваше мнение о сервисе ' : 'Как прошла консультация?'}
          </Text>
        }
        {!isCancelled &&
          <StarsRow>
            {STARS.map((_, i) => {
              return <StarIcon
                key={`star_${i}`}
                active={rate > i}
                onClick={() => setRate(i+1)}
                disabled={isDisabled}
              />
            })}
          </StarsRow>
        }

        <CustomInput
          disabled={isDisabled}
          bordered
          textarea
          value={comment}
          onChange={setComment}
          label="Ваш комментарий"
          placeholder="Ваш комментарий"
          rows={1}
        />
        {!isDisabled &&
          <CustomButton
            onClick={() => sendRate()}
            disabled={rateType === 0 ? !rate : !comment}
            style={{ marginBottom: '16px', opacity: 1, borderRadius: '0.4rem', height: '3rem', fontSize: '1rem' }}
            size="big"
          >Отправить</CustomButton>
        }
      </>
    }
  </>
};

export default Rate;

const CustomButton = styled(Button)`
  margin-bottom: 24px;
`;

const CustomInput = styled(Input)`
  margin-bottom: 16px;
`;

const StarsRow = styled.div`
  display: flex;
  margin-bottom: 32px;

  > svg {
    margin-right: 16px;
    cursor: pointer;

    > path {
      transition: all 0.2s ease;
    }
  }
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 165%;
`;

const CancelledText = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 24px;
  color: #EF5350;
  line-height: 31px;
  position: relative;
  top: -16px;
`;

const CancelledDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;

  background: var(--switcher-background);
  border-radius: 8px;
  margin-bottom: 24px;

  > svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }

  > p {
    font-size: 14px;
    line-height: 16px;
    min-height: 20px;
    display: flex;
    align-items: center;
  }
`;
