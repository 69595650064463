import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { BookmarkSmallWithBg } from "../../../assets/icons/notes/icons"
import notesStub from '../../../assets/icons/notes/img/empty_notes.png'
import { Button } from "../../../components/ui/Button"

interface EmptyNotesProps {
  goToEvent(): void
}

export const EmptyNotes = observer((props: EmptyNotesProps) => {
  const onClickHandler = () => {
    props.goToEvent()
  }

  return (
    <Container>
      <Description>
        <Img src={notesStub} alt={'notes'}/>
        <div>
          Некоторые события содержат
          <Phrase><span>рекомендации. Нажимайте на</span> {<BookmarkSmallWithBg/>} рядом</Phrase> с теми рекомендациями, которые хотите выполнить. Они появятся здесь, и вы не забудете, что хотели сделать!
        </div>
      </Description>
      <Button onClick={onClickHandler}>Давайте попробуем</Button>
    </Container>
  )
})

const Container = styled.div`
  padding: 0 1rem;
`

const Img = styled.img`
  display: inline;
  width: 100%;
  margin-bottom: 2rem;
`

const Description = styled.div`
  margin-bottom: 1.5rem;

  span {
    white-space: nowrap;
  }
`

const Phrase = styled.p`
  display: flex;
  gap: 0.25rem;
  flex-wrap: nowrap;
`
