import { show } from '@chronos/ui';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Overlay from '../../components/Overlay';
import { MainPadding } from '../../components/layout/elements';
import { Button } from '../../components/ui/Button';
import { TextInput } from '../../components/ui/TextInput';
import store from '../../store/Store';
import { TrialClose } from '../paywall/components/CloseIcon';
import { observer } from 'mobx-react-lite';


interface PromocodePopupProps {
	onClose(): void
	subscription?: boolean
}

export const PromocodePopup = observer((props: PromocodePopupProps) => {
  const [ promo, setPromo ] = useState('');
  const [ promoError, setPromoError ] = useState('')

	const containerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		setTimeout(() => {
			containerRef.current?.classList.add('show_modal')
		}, 100)
	}, [])

	useEffect(() => {
		setPromoError(store.promocode.promoError)
	}, [store.promocode.promoError])

  const checkPromocode = async () => {
    setPromoError('');

    const result = await store.promocode.checkPromocode(promo, undefined, props.subscription);

    show(result);

    if (result.type === 'success') {
			props.onClose()
    }
  }

	const onChangeHandler = (value: string) => {
		setPromo(value)
		promoError && store.promocode.setPromoError('')
	}

	const onCloseHandler = () => {
		props.onClose()
		promoError && store.promocode.setPromoError('')
	}

  return (
		<Overlay onClick={onCloseHandler}>
			<Container ref={containerRef}>
		    <StyledTrialClose onClick={onCloseHandler}/>

		    <Content>
		      <Title>Промокод</Title>
		      <StyledTextInput
		        value={promo}
		        onChange={onChangeHandler}
		        title={'Введите промокод'}
		        error={promoError}
		      />
		    </Content>

		    <StyledButton onClick={checkPromocode} color="green" disabled={!promo}>Применить</StyledButton>
		  </Container>
		</Overlay>
	)
})

const Container = styled.div`
	position: relative;
	border-radius: 32px 32px 0px 0px;
	background: var(--color-white);
	width: 100%;
	position: fixed;
	bottom: -100%;
	padding: 1rem;

	&.show_modal {
	  transition: .3s;
		bottom: 0;
	}
`

const Content = styled(MainPadding)`
	text-align: center;
`

const Title = styled.div`
  font-size: 1.5rem;
	font-weight: 500;
  margin-bottom: 1rem;
`

const StyledTrialClose = styled(TrialClose)`
	top: 1.5rem;
`

const StyledTextInput = styled(TextInput)`
	font-size: 1.125rem;
	margin-top: 2rem;
	text-align: left;

	.text-input-title {
		font-weight: 500;
	}
`

const StyledButton = styled(Button)`
  color: var(--text-primary);
	font-weight: 500;
`
