import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

const PWA_INSTALLED_LS  = 'pwa-istalled-03'

export const useAppInstall = () => {
  const deferredPrompt = useRef<any>();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {

    const beforeInstall = (e?: Event) => {
      e && e.preventDefault();
      if(window.matchMedia('(display-mode: standalone)').matches) return; // 1 проверка на браузер
      if((navigator as any).standalone) return; // 1 проверка на браузер
      if(document.referrer.includes('android-app://')) return; // 1 проверка на браузер
      if(!isMobile) return; // 2 проверка на мобилку
      if(localStorage.getItem(PWA_INSTALLED_LS)) return // 3 проверка на наличие iOS() && 
      deferredPrompt.current = e;
      setIsVisible(true);
    }

    // const afterInstall = () => {
    //   if(iOS()) localStorage.setItem(PWA_INSTALLED_LS, '1');
    //   setIsVisible(false);
    // }

    //if(iOS()){
    beforeInstall();
    // } else {
    //   window.addEventListener('beforeinstallprompt', beforeInstall);
    //   window.addEventListener('appinstalled', afterInstall);
    //   return () => {
    //     window.removeEventListener('beforeinstallprompt', beforeInstall);
    //     window.removeEventListener('appinstalled', afterInstall);
    //   }
    // }

    
  }, [])

  const installApp = async () => {
    //if(iOS()) {
      localStorage.setItem(PWA_INSTALLED_LS, '1');
      setIsVisible(false);
      return;
    //}
    
  //   if (deferredPrompt.current) {
  //     deferredPrompt.current.prompt();
  //     const { outcome } = await deferredPrompt.current.userChoice;
  //     if (outcome === 'accepted') {
  //         deferredPrompt.current = null;
  //     }
  // }
  }

  return {
    isVisible,
    installApp
  }
}

// export function iOS() {
//   return [
//     'iPad Simulator',
//     'iPhone Simulator',
//     'iPod Simulator',
//     'iPad',
//     'iPhone',
//     'iPod'
//   ].includes(navigator.platform)
//   // iPad on iOS 13 detection
//   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
// }