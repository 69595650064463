import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/layout/elements';
import { Harmony, Tension } from '../../../assets/icons/system/system-icons'
import { events } from '../helpers/texts';

export const OnboardingPopupEvents = () => {
  return <Container>
    {events.map(event => {
      const TypeIcon = event.type === 'harmonic' ? Harmony : Tension;

      return <Event key={event.title}>
        <div>
        	<Header>
	          <Title>{event.title}</Title>
	        </Header>
	        <Period>
						{event.period}
	        </Period>
        </div>
				<IconContainer><TypeIcon /></IconContainer>
      </Event>
    })}

		<Info>Они содержат рекомендации и риски. Следование рекомендациям поможет улучшить сферу и избежать негативного эффекта</Info>
  </Container>
}

const Container = styled(FlexContainer)`
  gap: 0.5rem;
  flex-direction: column;
  margin-top: 0.75rem;
`

const Event = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

  background: #F6F6F6;
  border-radius: 16px;
  padding: 0.625rem 1rem;
`

const Header = styled(FlexContainer)`
  justify-content: space-between;
  margin-bottom: 0.3rem;
  color: var(--text-secondary);
  padding: 0.5rem 0;
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-primary);
`

const Period = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.5rem;
	color: var(--text-third);
`

const IconContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: end;
`

const Info = styled.div`
	font-size: 0.75rem;
	color: var(--text-secondary);
	margin: 0.5rem 0;
`
