import { isMobile } from "react-device-detect";
import { PopupContainer } from "../../components/ui/Popup/Popup";
import { MainPageBlog } from "../main-page/components/Blog/main-page-blog";
import { MainPageBlogNew } from "../main-page/components/Blog/main-page-blog-new";
import { MainPageDiagnosticBanner } from "../main-page/components/main-page-diagnostic-banner";
import { MainPageMenu } from "../main-page/components/main-page-menu";
import { useEffect, useLayoutEffect, useRef } from "react";
import store from "../../store/Store";
import { shutterBottom } from "../../store/Shutter";
import { observer } from "mobx-react-lite";
import { windowsStore } from "../../store/Windows";
import { popupsStore } from "../../store/Popups";
import { gtmSend } from "../../helpers/url";
import styled from "styled-components";


interface ShutterProps {
  newCounterRef: React.RefObject<HTMLDivElement>
  rollDown: () => void
}

export const Shutter = observer((props: ShutterProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const scrolledRef = useRef(false)

  const userId = store.sessionData?.id;

  const hideShutter = windowsStore.getWindows().find(window => window.isModal) || popupsStore.getPopups().length > 0

  useLayoutEffect(() => {
    store.shutter.setShutterRef(containerRef)
  }, [])

  useEffect(() => {
    const scrollHandler = () => {
      const scrollTop = containerRef.current?.scrollTop ?? 0

      if (scrollTop > 300 && !scrolledRef.current) {
        gtmSend({ 'event': `indi_hub_scroll`, 'user_id': userId ? userId : '' })
        scrolledRef.current = true
      }
    }

    containerRef.current?.addEventListener('scroll', scrollHandler)

    return () => {
      containerRef.current?.removeEventListener('scroll', scrollHandler)
    }
  }, [userId])

  const containerStyles: React.CSSProperties = isMobile
  ? {
      'zIndex': 'var(--z-index-shutter)',
      'overflow': 'hidden',
      'width': '100%',
      'transform': `translateY(-${shutterBottom}px)`,
      'height': '100dvh',
      'position': 'fixed',
      'bottom': '-100%',
    }
  : {
      'overflow': 'initial',
    };

  return (
    <StyledPopupContainer
      style={containerStyles}
      popupRef={containerRef}
      className={'ob-0'}
      background={isMobile ? 'var(--color-white)' : ''}
      showNotification
      hideShutter={!!hideShutter}
    >
      <MainPageBlogNew newCounterRef={props.newCounterRef}/>
      <MainPageMenu rollDown={props.rollDown}/>
      <MainPageDiagnosticBanner />
      <MainPageBlog rollDown={props.rollDown}/>
    </StyledPopupContainer>
  )
})

const StyledPopupContainer = styled(PopupContainer)<{hideShutter: boolean}>`
  display: ${p => p.hideShutter ? 'none' : 'block'};
`
