import { makeAutoObservable } from "mobx";
import api from "../helpers/api";

export class Rectification {
  constructor() {
    makeAutoObservable(this)
  }

  async orderRectification() {
    try {
      await api.orderRectification({ isDelayed: true })
    } catch(e) {
      console.error(`Error in ordering rectification: ${e}`)
    }
  }
}
