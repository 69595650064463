import { Indi } from "../../../libs"
import styled from "styled-components"
import { INote } from "../../../store/Notes"
import { eventsOptions } from "../../my-day/helpers/events"
import { Note } from "./Note"


export const NotesGroup = ({
  sphere,
  notes,
  goToEvent,
  setShowToaster,
}: {
  sphere: string
  notes: INote[]
  goToEvent(note: INote): void
  setShowToaster(value: boolean): void
}) => {

  const eventOptions = eventsOptions[sphere as Indi.Events.Sphere]
  const Icon = eventOptions.icon

  return (
    <NotesGroupContainer>
      <Header color={eventOptions.textColor}>
        <Icon/>
        <div>{eventOptions.title}</div>
      </Header>

      {
        notes.map((note, index) => (
          <Note
            key={`${sphere}_note_${index}`}
            note={note}
            background={eventOptions.color}
            color={eventOptions.textColor}
            goToEvent={goToEvent}
          />
        ))
      }
    </NotesGroupContainer>
  )
}

const NotesGroupContainer = styled.div`
  margin-bottom: 1.5rem;
`

const Header = styled.div<{color: string}>`
  display: flex;
  align-items: center;
  gap: 0.35rem;

  color: ${p => p.color};
  margin-bottom: 1rem;
`
