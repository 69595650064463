import BlackFridayBg from './black-Friday.jpg'
import Offer from '../../../../assets/images/offer_purpure_bg.jpg'

export enum Backgrounds {
	BLACK_FRIDAY = 'blackFriday',
  OFFER = 'offer'
}

export const backgrounds = {
	[Backgrounds.BLACK_FRIDAY]: BlackFridayBg,
  [Backgrounds.OFFER]: Offer,
}
