import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ROUTES } from '../../helpers/routes';
import { useBackWindow } from '../../hooks/router-hooks';
import { MyDayItem } from './my-day-item';

import { GridContainer, WindowContainer } from '../../components/layout/elements';
import { getStartDateFromUrl } from '../../helpers/dates';

import { useHorizontalSwipes } from '../../hooks/swipe-hooks';
import store from '../../store/Store';
import dayjs from 'dayjs';
import { history } from '../../MainRouter';
import { gtmSend, searchParams } from '../../helpers/url';
import { CALENDAR_IS_OPENED_CLASS } from './components/my-day-calendar';
import { MyDayHeader } from './components/my-day-header';
import { getMyDayLS } from '../../store/localStorageHelpers';
import { Logging } from '../../libs';
import { windowsStore } from '../../store/Windows';
import { useMoveShutter } from '../../hooks/popup-hooks';
import { WidgetNotes } from '../notes/WidgetNotes';

export const WINDOW_ID_MYDAY: string = 'WINDOW_ID_MYDAY';

interface MyDayProps {
  outOfZone?: boolean;
	day?: dayjs.Dayjs
}

export const openMyDay = (day?: dayjs.Dayjs) => {
  windowsStore.open(
    WINDOW_ID_MYDAY,
    <MyDay day={day}/>,
    undefined,
    false,
    true
  )
}

export const MyDay = observer((props: MyDayProps) => {
  useBackWindow(ROUTES.MY_DAY, WINDOW_ID_MYDAY);

  const status = store.events.status;
	const userId = store.sessionData?.id
  const shutterRef = store.shutter.shutterRef
  const sp = searchParams();

  const innerContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [ selectedDay, setSelectedDay ] = useState(props.day ?? getStartDateFromUrl());
  const [ firstRender, setFirstRender ] = useState(true);
  const [ x, setX ] = useState(-100);

  useMoveShutter(containerRef, shutterRef)

  const onSwipe = (step: number) => {
    if (document.querySelector(`.${CALENDAR_IS_OPENED_CLASS}`)) return;
    setX((x) => Math.min(Math.max(x + step, -200), 0));
    setTimeout(() => {
      const dayDiff = step > 0 ? -1 : 1;
      setSelectedDay(selectedDay.add(dayDiff, 'day'));

      innerContainerRef.current && innerContainerRef.current.classList.remove('smooth');
      setX(-100);
      setTimeout(() => innerContainerRef.current && innerContainerRef.current.classList.add('smooth'), 100);
    }, 300);
  };

  const onSwipedLeft = () => onSwipe(-100);
  const onSwipedRight = () => onSwipe(+100);

  const { swipeHandlers } = useHorizontalSwipes<HTMLDivElement >(onSwipedLeft, onSwipedRight)

	useEffect(() => {
		const isTriggeredEmail = sp.get('utm_campaign')

		if (isTriggeredEmail === 'triggered-email-back') {
			gtmSend({'event': `triggered-email-opened`, 'user_id': userId ? userId : ''})
			history.push(window.location.pathname)
		}

    store.logger.startLoggingSession(Logging.Screens.MY_DAY)

    return () => {
      history.push({pathname: '/', search: sp.toString()})
      store.logger.createLoggingSession(Logging.Screens.MY_DAY)
    }
	}, [])

  useEffect(() => {
    if (status === 'active') {
      const date = selectedDay.isValid() ? selectedDay : dayjs();
      store.events.loadEventsByDate(date);

      const formattedDate = dayjs(date).format('DD-MM-YYYY');
      history.push({
        pathname: `/${ROUTES.MY_DAY}/${formattedDate}`,
        search: sp.toString(),
      });

      document.title = `Мой день • ${dayjs(date).format('D MMMM')}`;
    }
  }, [sp, selectedDay, status]);

  useEffect(() => {
    if (firstRender) {
      innerContainerRef.current && innerContainerRef.current.classList.remove('smooth');
      setFirstRender(false);
      setTimeout(() => innerContainerRef.current && innerContainerRef.current.classList.add('smooth'), 100);
    }
    innerContainerRef.current && (innerContainerRef.current.style.transform = `translateX(${x}%)`);
  }, [x, innerContainerRef, firstRender])

  const [ hideOffer ] = useState(getMyDayLS().hideOffer)

	useEffect(() => {
		!hideOffer && gtmSend({ 'event': `ind_prognosis_my-day${props.outOfZone ? '-out-of-zone' : ''}_1year-bunner_shown`, 'user_id': userId})
	}, [selectedDay])

  return (
    <WindowContainer>
      <Container {...swipeHandlers} ref={containerRef}>
         <MyDayHeader
            selected={selectedDay}
            setSelected={setSelectedDay}
          />

        {status === 'active' && <WidgetNotes/>}

        <InnerContainer ref={innerContainerRef} className='smooth'>
          <MyDayItem selectedDay={selectedDay.add(-1, 'day')} setSelectedDay={setSelectedDay} containerRef={containerRef}/>
          <MyDayItem selectedDay={selectedDay} setSelectedDay={setSelectedDay} containerRef={containerRef}/>
          <MyDayItem selectedDay={selectedDay.add(1, 'day')} setSelectedDay={setSelectedDay}  containerRef={containerRef}/>
        </InnerContainer>
      </Container>
    </WindowContainer>
  )
});

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding-top: 5rem;
`;

const InnerContainer = styled(GridContainer)`
  width: 100%;
  grid-template-columns: 100% 100% 100%;

  &.smooth {
    transition: transform 0.3s;
  }
`;
