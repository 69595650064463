import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';

import { capitalize } from '../../helpers/strings';
import store from '../../store/Store';

import { TrendingIcon, ThumbsUpIcon, CutIcon } from '../../assets/icons/system/system-icons';
import Month from './components/Month';
import OtherTrends from './components/OtherTrends';
import OtherItems from './components/OtherItems';
import { popupsStore } from '../../store/Popups';
import PrognosesMonth, { WINDOW_ID_PROGNOSES_MONTH } from './prognoses-month';
import PrognosesSpecialDay, { WINDOW_ID_PROGNOSES_SPECIAL_DAY } from './prognoses-special-day';
import { gmtLocal } from './prognoses-daily';


interface IPinnedComponents {
  component: React.JSXElementConstructor<any>,
  name: string;
  props: any;
}

export default observer(function PrognosesMonthly() {
  const today = dayjs();
  const nextMonth = today.add(1, 'month');
  const [pinned, setPinned] = useState<any>([]);

  useEffect(() => {
    store.prognoses.getPrognosesFeed(dayjs().toISOString(), gmtLocal);
    document.title = `Общий прогноз • ${dayjs().locale('ru').format('MMMM')}`;
  }, []);

  const [specialDaysLucky, specialDaysBad, haircutIntervals] = useMemo(() => {
    return [
      store.prognoses.getActualSpecialDays('LUCKY'),
      store.prognoses.getActualSpecialDays('BAD'),
      store.prognoses.getHaircutIntervals(),
    ];
  }, [store.prognoses.prognoses]);

  const sortedComponents = useMemo(() => {
    const components: IPinnedComponents[] = [{
      component: Month,
      name: 'month',
      props: {
        formattedMonth: today.format('MM.YYYY'),
      },
    }, {
      component: OtherItems,
      name: 'next-month',
      props: {
        items: [{
          title: capitalize(nextMonth.format('MMMM')),
          onClick: () => {
            popupsStore.open(
              WINDOW_ID_PROGNOSES_MONTH,
              <PrognosesMonth formattedMonth={nextMonth.format('MM.YYYY')} />,
              'Назад'
            )
          },
          period: {
            start: nextMonth.startOf('M').format('DD.MM.YYYY HH:mm:ss'),
          },
        }],
      },
    }, {
      component: OtherTrends,
      name: 'trends',
      props: {
        title: 'Тренды',
        icon: <TrendingIconStyled />,
      },
    }];

    if(specialDaysLucky?.length) {
      components.push({
        component: OtherItems,
        name: 'lucky-days',
        props: {
          title: 'Благоприятные дни',
          icon: <ThumbsUpIconStyled />,
          items: specialDaysLucky.map(day => ({
            title: day.title,
            period: day.period,
            //link: `/prognoses/special-day/${day.id}`,
            onClick: () => {
              popupsStore.open(
                WINDOW_ID_PROGNOSES_SPECIAL_DAY,
                <PrognosesSpecialDay dayId={String(day.id)} />,
                'Назад'
              )
            },
          })),
        },
      });
    }

    if(specialDaysBad?.length) {
      components.push({
        component: OtherItems,
        name: 'bad-days',
        props: {
          title: 'Неблагоприятные дни',
          icon: <ThumbsDownIconStyled />,
          items: specialDaysBad.map(day => ({
            title: day.title,
            period: day.period,
            //link: `/prognoses/special-day/${day.id}`,
            onClick: () => {
              popupsStore.open(
                WINDOW_ID_PROGNOSES_SPECIAL_DAY,
                <PrognosesSpecialDay dayId={String(day.id)} />,
                'Назад'
              )
            },
          })),
        },
      });
    }

    if(haircutIntervals?.length) {
      components.push({
        component: OtherItems,
        name: 'haircut-days',
        props: {
          title: 'Дни для стрижки',
          icon: <CutIconStyled />,
          items: haircutIntervals.map(day => ({
            time: day.period.start,
          })),
        },
      });
    }
    
    return components.sort((c1, c2) => {
      const i1 = pinned.indexOf(c1.name);
      const i2 = pinned.indexOf(c2.name);
      return i2 - i1;
    })
  }, [store.prognoses.prognoses, pinned]);

  const togglePin = (name: string) => {
    if(pinned.indexOf(name) >= 0) {
      setPinned(pinned.filter((p: string) => p !== name));
    } else {
      setPinned(pinned.concat(name).slice(-2));
    }
  }

  if(!store.prognoses.prognoses) {
    return null;
  }

  return (
    <Container>
      { sortedComponents.map((c, i: number) => (
          <c.component
            key={`prognoses_${i}`}
            {...c.props}
            togglePin={() => togglePin(c.name)}
            isPinned={pinned.indexOf(c.name) >= 0}
          />
      ))}
    </Container>
  );
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 3.5rem;
`;

const TrendingIconStyled = styled(TrendingIcon)`
  width: 1.5rem;
  height: 1.5rem;
`;

const ThumbsUpIconStyled = styled(ThumbsUpIcon)`
  width: 1.5rem;
  height: 1.5rem;
  fill: rgba(81, 135, 25, 0.6);
`;

const ThumbsDownIconStyled = styled(ThumbsUpIcon)`
  width: 1.5rem;
  height: 1.5rem;
  fill: rgba(197, 50, 47, 0.6);
  transform: rotate(180deg);
`;

const CutIconStyled = styled(CutIcon)`
  width: 1.5rem;
  height: 1.5rem;
`;
