import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { FlexContainer, MainPadding } from '../../../components/layout/elements';
import { Button } from '../../../components/ui/Button';
import { gtmSend } from '../../../helpers/url';
import store from '../../../store/Store';
import { texts } from '../helpers/texts';
import { OnboardingPopupEvents } from './onboarding-popup-events';
import { OnboardingPopupNav } from './onboarding-popup-nav';
import { SwipeIcon } from '../../../assets/icons/system/system-icons';
import { isMobile } from 'react-device-detect';

export const OnboardingPopup = observer(({
  step,
  onClose,
  setStep
}: {
  step: number,
  onClose: () => void,
  setStep: (step: number) => void;
}) => {
  const userId = store.sessionData?.id;

	const active = store.events.status === 'active';
	const trialExpiryDate = store.sessionData?.indi?.trialExpiryDate;
  const trialActive = active && trialExpiryDate && dayjs().isBefore(trialExpiryDate);

	const title = step === 0 && trialActive ? texts[step].titleTrialActive : texts[step].title;
  const text = step === 0 && trialActive ? texts[step].textTrialActive : texts[step].text;

  const cancelOnboarding = () => {
    gtmSend({'event': `ind_prognosis_onboarding_skip`, 'user_id': userId ? userId : ''})
    onClose();
  }

  const startOnboarding = () => {
    gtmSend({'event': `ind_prognosis_onboarding_start`, 'user_id': userId ? userId : ''})
    setStep(step + 1)
  }

  return <Container id="onboarding-popup">
    <Triangle step={step} isMobile={isMobile}/>

    {title && <Title>{title}</Title>}
    {text && <Text>{text}</Text>}

    {
			step === 0 &&
				<Buttons>
		      <Button onClick={startOnboarding} color="blue" small>Открыть ленту событий</Button>
		      <Button onClick={cancelOnboarding} color="gray" small>Пропустить</Button>
	    	</Buttons>
		}

    {step === 2 && <OnboardingPopupEvents />}

    {step > 0 && <OnboardingPopupNav step={step} setStep={setStep} onClose={onClose} />}

		{step === 4 && <IconContainer isMobile={isMobile}><SwipeIcon/></IconContainer>}
  </Container>
})

const Container = styled(MainPadding)`
  position: fixed;
  width: 85%;
  max-width: 22rem;
  background: var(--color-white);
  z-index: 100;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
	display: none;
`

const IconContainer = styled.div<{isMobile: boolean}>`
	position: absolute;
	top: -6rem;
  left: ${p => p.isMobile ? '6rem' : '8rem'};
`

const Triangle = styled.div<{step: number, isMobile: boolean}>`
  border: 0.6rem solid transparent; border-bottom: 0.6rem solid var(--color-white);
  position: absolute;
	z-index: 1000;

  ${p => p.step === 0 && css`
    top: -1rem;
    left: 3rem;
  `}

  ${p => (p.step === 1 || p.step === 3 ) && css`
    bottom: -1.1rem;
    transform: rotate(180deg);
    left: 50%;
    margin-left: -0.3rem;
  `}

  ${p => p.step === 2 && css`
    display: none;
  `}

  ${p => p.step === 3 && css`
		bottom: ${p.isMobile ? '6.5rem' : '5.4rem'};
    left: 102%;
		transform: rotate(90deg);
    margin-left: -0.4rem;
  `}

  ${p => p.step === 4 && css`
    top: -1rem;
    left: 50%;
    margin-left: -0.3rem;
  `}
`

const Title = styled.div`
  font-size: 1.125rem;
	font-weight: 500;
  line-height: 110%;
  margin-bottom: 0.25rem;
  color: var(--text-primary);
`

const Text = styled.div`
  font-size: 0.75rem;
  line-height: 135%;
  margin: 0.5rem 0;
`

const Buttons = styled(FlexContainer)`
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.75rem;
`
