import styled, { css } from 'styled-components'
import { Button } from './ui/Button'
import Clock from '../assets/images/clock.png'
import api from '../helpers/api'
import { useState } from 'react'
import { getProfileLS, setProfileLS } from '../store/localStorageHelpers'
import dayjs from 'dayjs'
import { gtmSend } from '../helpers/url'
import store from '../store/Store'
import { Checkbox } from '../libs'

interface RectificationRequestProps {
  rectification?: boolean
  setRectification?(value: boolean): void
}

export const RectificationRequest = (props: RectificationRequestProps) => {
	const getDisabled = () => {
		const delay = getProfileLS().rectificationDelay
		return delay ? dayjs(delay).diff(dayjs(), 'day') < 1 : false
	}

	const [disabled, setDisabled] = useState(getDisabled())

	const userId = store.sessionData?.id;

	const onClickHandler = () => {
		api.orderRectification({isDelayed: true})

		const dataProfileLS = {
			rectificationDelay: dayjs().toISOString()
		}
		setProfileLS(dataProfileLS)

		setDisabled(true)

		gtmSend({'event': 'ind_prognosis_settings_rectification', 'user_id': userId ? userId : ''})
	}

	if (props.setRectification) {
		return (
			<CheckboxContainer>
				<StyledCheckbox
					color={'var(--color-green)'}
					checked={props.rectification}
					onChange={(value: boolean) => props.setRectification!(value)}
				/>
				<div>
					<div className='title'>Узнать точное время рождения</div>
					<div className='subtitle'>
						Свяжемся с вами, чтобы рассказать об услуге Ректификации (уточнение времени рождения)
					</div>
				</div>
			</CheckboxContainer>
		)
	}

	return (
		<Container>
			<Content>
				<Img src={Clock} alt="clock" />
				<div>
					<div className='title'>Узнать точное время рождения</div>
					<div className='subtitle'>
						Свяжемся с вами, чтобы рассказать об услуге Ректификации (уточнение времени рождения)
					</div>
				</div>
			</Content>

			<StyledButton onClick={onClickHandler}
										color={'gray'}
										disabled={disabled}
										styleDisabled={disabled}>
				{
					disabled
						? 'Заявка отправлена'
						: 'Отправить заявку'
				}
			</StyledButton>
		</Container>
	)
}

const CheckboxContainer = styled.div`
	display: flex;
	align-items: start;

	margin-top: 1rem;

	.title {
		font-size: 1rem;
		font-weight: 500;
	}

	.subtitle {
		font-size: 0.75rem;
	}
`

const StyledCheckbox = styled(Checkbox)`
	color: white;
	margin-right: 0.75rem;

	.box {
		border: 1px solid var(--border);
		width: 1.75rem;
		height: 1.75rem;
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;

	padding: 0.875rem 0.75rem 0.75rem;
	margin-bottom: 1rem;
	background: var(--input-background);
	border-radius: 16px;

	.title {
		font-size: 1rem;
		font-weight: 500;
	}

	.subtitle {
		margin-top: 0.25rem;
		font-size: 0.75rem;
		width: 80%;
	}
`

const Content = styled.div`
	display: flex;
	justify-content: space-between;

	margin-bottom: 1rem;
`

const Img = styled.img`
	width: 2rem;
	height: 2rem;
	margin-right: 1rem;
`

const StyledButton = styled(Button)<{styleDisabled: boolean}>`
	color: var(--text-primary);
	font-weight: 500;

	${p => p.styleDisabled && css`
		background: none;
		color: var(--text-third);
	`}
`
