import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { FormEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PrognosesForm } from '../../components/forms/PrognosesForm';
import { MainPadding, WindowFormContainer } from '../../components/layout/elements';
import { Button } from '../../components/ui/Button';
import { TextInput } from '../../components/ui/TextInput';
import api from '../../helpers/api';
import { isDateInvalid, isEmailInvalid, isNameInvalid, isPlaceInvalid, isTimeInvalid } from '../../helpers/fields';
import { gtmSend } from '../../helpers/url';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';
import { openPaymentResultWindow } from '../payment/PaymentResult';
import { WindowHeader } from '../windows/components/WindowHeader';
// import { goToAuth } from '../main-page/components/main-page-header-login-button';
import Policy from '../../components/Policy';
import { RectificationRequest } from '../../components/RectificationRequest';
import { ROUTES } from '../../helpers/routes';
import { useBackWindow } from '../../hooks/router-hooks';
import { IPlace, Indi } from '../../libs';
import { openPaymentWindow } from '../paywall/Paywall';
import { FreePeriod } from '../payment/helpers/tariffs';


export const WINDOW_ID_REGISTRATION: string = 'WINDOW_ID_REGISTRATION';

export const openRegistrationWindow = (tariff: Indi.indiGetTariffs.Tariff, isAuth: boolean) => {
  windowsStore.open(
    WINDOW_ID_REGISTRATION,
    <Registration selectedTariff={tariff} isAuth={isAuth} />
  )
}

export const defaultPlace: IPlace = {
  name: '',
  lat: 0,
  lon: 0
}

export const Registration = observer(({
  selectedTariff,
  isAuth
}: {
  selectedTariff: Indi.indiGetTariffs.Tariff,
  isAuth: boolean
}) => {
  useBackWindow(ROUTES.REGISTRATION, WINDOW_ID_REGISTRATION);

  const profile = store.profile;

  const ref = useRef<HTMLFormElement>(null);

  const [ step, setStep ] = useState(isAuth ? 2 : 1);

  const [ email, setEmail ] = useState(profile.email || '');
  const [ emailError, setEmailError ] = useState('');

  const [ name, setName ] = useState(profile.firstName || '');
  const [ nameError, setNameError ] = useState('');

  const [ date, setDate ] = useState(
    profile.birth.dateTime
      ? dayjs(profile.birth.dateTime).toISOString()
      : dayjs('2000-01-01').toISOString()
  );
  const [ dateError, setDateError ] = useState('');

  const [ time, setTime ] = useState(
    profile.birth.dateTime
      ? dayjs(profile.birth.dateTime).toISOString()
      : dayjs('12:00', 'HH:mm').toISOString()
  )
  const [ timeError, setTimeError ] = useState('');

  const [ place, setPlace ] = useState<IPlace>(profile.birth.place || defaultPlace);
  const [ placeError, setPlaceError ] = useState('');

  const [ disableButton, setDisableButton ] = useState(false);

  const [ ymIsSended, setYmIsSended ] = useState(false);

  const info = store.sessionData?.indi;
  const userId = store.sessionData?.id || '';

	const [ rectification, setRectification ] = useState(false);


  const onClose = () => {
    if(step === 1 || isAuth) {
      windowsStore.close(WINDOW_ID_REGISTRATION);
    } else {
      setStep(1);
    }
  }

  useEffect(() => {
    if(!ymIsSended) {
      switch (step) {
        case 1:
          gtmSend({'event': 'ind_prognosis_trial_e-mail', 'user_id': userId});
          break
        case 2:
          gtmSend({'event': 'ind_prognosis_trial_birth_data', 'user_id': userId});
          setYmIsSended(true);
          break
      }
    }
  }, [step, userId, ymIsSended])

  useEffect(() => {
    if(!isAuth && info) {
      openPaymentWindow(selectedTariff, {freePeriod: FreePeriod.TRIAL});
    }
  }, [isAuth, info, selectedTariff])

  const registration = async () => {
    await store.events.registration(email, 'ru', name, {
      dateTime: {
        date: dayjs.utc(date).format('DD.MM.YYYY'),
        time: dayjs.utc(time).format('HH:mm:ss')
      },
      place
    });
  }

  const changeStep = async (e?: FormEvent) => {
    e && e.preventDefault();

    if(step === 1) {
      if(isEmailInvalid(email)) {
        setEmailError(isEmailInvalid(email));
        return;
      }

      const result = await api.indiTrialGetAction(email);

      switch(result.nextAction) {
        case 'registration':
          setStep(2);
          break;
        case 'authorization':
          // goToAuth();
          break;
        case 'email':
          openPaymentResultWindow(email, { exist: true })
          break;
        case 'error':
          setEmailError(result.error || '');
          break;
      }

    } else if (step === 2) {
      setNameError('');
      setDateError('');
      setTimeError('');
      if(isNameInvalid(name)) {
        setNameError(isNameInvalid(name));
        return;
      }
      if(isDateInvalid(date)) {
        setDateError(isDateInvalid(date));
        return;
      }

      if (isTimeInvalid(time)) {
        setTimeError(isTimeInvalid(time));
        return;
      }

      if(isPlaceInvalid(place)){
        setPlaceError(isPlaceInvalid(place))
        return;
      }

      setDisableButton(true);
      if(!isAuth) {
        await registration();
      } else {
        await api.updateProfile({
          firstName: name,
          birth: {
            //@ts-ignore
            dateTime: {
              date: dayjs.utc(date).format('DD.MM.YYYY'),
              time: dayjs.utc(time).format('HH:mm:ss')
            },
            place
          }
        } as any); // FIXME: any

        openPaymentWindow(selectedTariff, { freePeriod: FreePeriod.TRIAL });

        rectification && await api.orderRectification({ isDelayed: true })
      }

			rectification && await api.orderRectification({isDelayed: true})

      setDisableButton(false);
    }
  }

  const onChangeEmailHandler = (value: string) => {
    setEmail(value);
    setEmailError(isEmailInvalid(value));
  }

  const onChangeNameHandler = (value: string) => {
    setName(value);
    setNameError(isNameInvalid(value));
  }

  const onChangeDateHandler = (value: string) => {
    setDate(value);
    setDateError('');
  }

  const onChangeTimeHandler = (time: string) => {
    setTime(time)
    setTimeError('')
  }

  const onChangePalaceHandler = (value: any) => {
    if (typeof value === 'object') {
      setPlace({ name: value.name, lat: value.lat, lon: value.lon })
      setPlaceError('')
    } else {
      setPlace({ name: value, lat: 0, lon: 0 })
    }
  }

  return <WindowFormContainer onSubmit={changeStep} ref={ref}>
    <WindowHeader title='Регистрация' onBack={onClose} />
    <RegistrationContent>
      {!isAuth && <MiniTitle>шаг {step} из 2</MiniTitle>}

      {step === 1 && <>
        <Title>Введите свою почту. Она потребуется для создания аккаунта или авторизации</Title>
        <TextInput
          value={email}
          onChange={onChangeEmailHandler}
          title={'Почта'}
          type={'email'}
          error={emailError}
        />
      </>}

      {step === 2 && <>
        <Title>Заполните данные рождения, чтобы Chronos мог строить прогнозы</Title>
        <PrognosesForm
          name={name}
          nameOnChange={onChangeNameHandler}
          nameError={nameError}
          date={date}
          dateOnChange={onChangeDateHandler}
          dateOnInvalid={() => setDateError('Введите корректную дату')}
          dateError={dateError}
          time={time}
          timeOnChange={onChangeTimeHandler}
          timeOnInvalid={() => setTimeError('Введите корректное время')}
          timeError={timeError}
          place={place}
          placeOnChange={onChangePalaceHandler}
          placeError={placeError}
        />

				<RectificationRequest rectification={rectification}
															setRectification={setRectification}/>
      </>}

    </RegistrationContent>

    <Footer>
      <Button onClick={changeStep} disabled={disableButton}>Продолжить</Button>
      {step === 1 && <Policy fixedPosition={true} bottom={25}/>}
    </Footer>
  </WindowFormContainer>
})


export let RegistrationContent = styled.div`
  display: block;
  position: relative;
  padding: 1rem;
  flex: 1;
  font-weight: 500;
`

export const Footer = styled(MainPadding)`
  position: relative;
  flex: 0.25;
  height: min-content;
  font-weight: 500;

  @media (max-height: 680px) {
    flex: 0.3;
  }

  @media (max-width: 360px) {
    flex: 0.35;
  }
`

export const MiniTitle = styled.div`
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: var(--text-secondary);

  & > h1, h2, h3 {
    text-align: center;
  }
`

export const Title = styled.div`
  line-height: 135%;
  margin-bottom: 1rem;
`

export const CopyRight = styled.div`
  font-size: 0.7rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
  line-height: 140%;
  padding: 0 1rem;
  text-align: center;

  > a {
    text-decoration: none;
    color: var(--color-gradus-blue);
  }
`
