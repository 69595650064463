import styled, { css } from "styled-components"
import { Button } from "./ui/Button"
import { DataUpdateTariffModal } from "../store/Tariffs"

interface UpdateTariffModalProps {
  data: DataUpdateTariffModal
  onClick(): void
}

export default function UpdateTariffModal(props: UpdateTariffModalProps) {
  const { title, text, subtitle } = props.data.description
  const { oldPrice, finalPrice, discount } = props.data.amounts

  return (
    <UpdateTariffModalContainer>
      <Badge>-{discount}%</Badge>
      <Description>
        <div className={'title'}>{title}</div>
        <div>{text}</div>
      </Description>
      <StyledButton color={'gradient'} onClick={props.onClick}>
        Обновить за <OldPrice discount={!!discount}>{oldPrice}₽</OldPrice> {finalPrice && <span>{finalPrice}₽</span>}
      </StyledButton>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </UpdateTariffModalContainer>
  )
}

const UpdateTariffModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--color-black);
	border-radius: 1rem;
	padding: 1rem;
	color: var(--text-primary-white);
	font-size: 0.875rem;
  margin-bottom: 1.5rem;
  text-align: center;
`

const Badge = styled.div`
	color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: 500;
  background: var(--color-white);
  padding: 0.25rem 0.75rem;
  border-radius: 1.5rem;
  margin-bottom: 1rem;
`

const Description = styled.div`
  font-size: 0.75rem;
  margin-bottom: 1rem;

  .title {
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-primary-white);
    margin-bottom: 0.5rem;
  }
`

const StyledButton = styled(Button)`
  font-size: 1rem;
  padding: 0.75rem 1rem;
`

export const Subtitle = styled.div`
  font-size: 0.75rem;
  color: var(--text-secondary-white);
  margin-top: 0.5rem;
`

export const OldPrice = styled.span<{discount: boolean}>`
  ${p => p.discount && css`
    text-decoration: line-through;
  `}
`
