import { Button, Indi } from "src/libs"
import dayjs from "dayjs"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { HalfMoonIcon } from "../../../../assets/icons/astrology"
import { MyDayBadIcon, MyDayGoodIcon } from "../../../../assets/icons/myday"
import { ChevronRightRoundBold } from "../../../../assets/icons/system/system-icons"
import Overlay from "../../../../components/Overlay"
import { FlexContainer } from "../../../../components/layout/elements"
import { TButtonColor } from "../../../../components/ui/Button"
import { List } from "../../../../components/ui/List"
import { WidgetsAccordion } from "../../../../components/ui/WidgetsAccordion"
import { gtmSend } from "../../../../helpers/url"
import { IEvents } from "../../../../store/Events"
import store from "../../../../store/Store"
import { MyDayFeedback } from "../../../my-day/components/my-day-feedback"
import MissingDateOrPayment from "../../../subscription/MissingDateOrPayment"
import { MainPageWidgetMoon } from "../main-page-widget-moon"
import { WidgetAstroEvents } from "../../../astro-events/WidgetAstroEvents"
import { openMyDay } from "../../../my-day/my-day"
import { Sections } from "../../../../store/Shutter"


interface ItemProps {
  className?: string
  events: IEvents
  day: string
  todoRef: React.RefObject<HTMLDivElement>
}

export const ActiveSubscriptionScreenItem = observer((props: ItemProps) => {
  const {
    className,
    events,
    day,
    todoRef,
  } = props

  const [moonDataError, setMoonDataError] = useState(false)

  const percent = store.events.percents[dayjs(day).format('DD.MM.YYYY')]
  const userId = store.sessionData?.id
  const stub = store.events.events[Object.keys(store.events.events).reverse()[0]]
  const _events = events ?? stub
  const expiryDate = store.sessionData?.indi?.expiryDate
  const isExpiryDay = dayjs(expiryDate).startOf('day') < dayjs(day).startOf('day')
  const astroEventsStore = store.astroEvents.astroEvents

  useEffect(() => {
    if (!percent) {
      store.events.loadMonth(day)
    }

    store.astroEvents.getAstroEvents(day)
  }, [percent, day])

  useEffect(() => {
    if (isExpiryDay) {
      gtmSend({ 'event': 'indi_main_inactive-period-shown', 'user_id': userId ? userId : '' })
    }
  }, [isExpiryDay, userId])

  const goToEvents = (showEvents?: boolean) => {
    showEvents && store.events.setShowEvents(true)
    openMyDay(dayjs(day))
    store.shutter.setActiveSection(Sections.MY_EVENTS)
  }

  const onClickPercent = () => {
    gtmSend({ 'event': 'ind_prognosis_main-page_persent-click', 'user_id': userId ? userId : '' })
    goToEvents()
  }

  const onClickLearnMore = () => {
    gtmSend({ 'event': 'ind_prognosis_main-page_learn-more-click', 'user_id': userId ? userId : '' })
  }

  const onClickGoToEvents = () => {
    gtmSend({ 'event': 'ind_prognosis_main-page_go-to-events-click', 'user_id': userId ? userId : '' })
    goToEvents(true)
  }

  const Icon = store.events.getIcon(dayjs(day))

  const getErrorContent = () => {
    if (moonDataError) {
      return {
        title: 'Данные по лунному календарю на выбранную дату пока недоступны',
        buttonTitle: 'Вернуться на главную',
        buttonColor: 'black',
        onClick: () => setMoonDataError(false),
      }
    }

    if (isExpiryDay) {
      return {
        title: 'События на оплаченный период закончились',
        buttonTitle: 'Продлить подписку',
        buttonColor: 'gradient',
        onClick: undefined,
      }
    }

    return {
      title: 'Загружаем прогноз',
      buttonTitle: undefined,
      buttonColor: undefined,
      onClick: undefined,
    }
  }

  const widgets = () => {
    const astroEvents = []

    let key: keyof Indi.GetAstroEvents.AstroEvents
    for (key in astroEventsStore[day]) {
      const astroEvent = astroEventsStore[day][key] as Indi.GetAstroEvents.PlanetEvents & Indi.GetAstroEvents.Interval

      const pushWidget = (key: keyof Indi.GetAstroEvents.AstroEvents) => {
        if (key === Indi.GetAstroEvents.AstroEventsKey.ECLIPSE_CORRIDOR) {
          if (
            astroEvent?.from &&
            astroEvent?.to &&
            dayjs(day).isAfter(dayjs(astroEvent?.from).startOf('day')) &&
            dayjs(day).isBefore(dayjs(astroEvent?.to))
          ) {
            return true
          } else {
            return false
          }
        }

        if (
          dayjs(astroEvent?.retro?.from || astroEvent?.from).diff(dayjs(day), 'day') < 15 &&
          dayjs(day).isBefore(dayjs(astroEvent?.staticAfter?.to || astroEvent?.to).endOf('day'))
        ) {
          return true
        }

        return false
      }

      if (pushWidget(key)) {
        astroEvents.push({...astroEvent, key})
      }
    }

    astroEvents.sort((w1, w2) => {
      const from1 = dayjs(w1?.retro?.from || w1?.from).millisecond()
      const from2 = dayjs(w2?.retro?.from || w2?.from).millisecond()

      return from1 - from2
    })

    return [
      <MainPageWidgetMoon
        day={day}
        onClick={() => setMoonDataError(true)}
      />,
      ...astroEvents.map(astroEvent =>  (
        <WidgetAstroEvents
          day={day}
          type={astroEvent.key}
          retro={astroEvent?.retro}
          staticBefore={astroEvent?.staticBefore}
          staticAfter={astroEvent?.staticAfter}
          eclipse={astroEvent?.from && astroEvent?.to ? {from: astroEvent?.from, to: astroEvent?.to} : undefined}
        />
      ))
    ]
  }

  return (
    <Container
      className={className ?? ''}
      background={store.events.getColor(dayjs(day)).background}>

      {
        (!events || moonDataError) && (
          <StyledOverlay>
            <MissingDateOrPayment
              title={getErrorContent().title}
              buttonTitle={getErrorContent().buttonTitle}
              buttonColor={getErrorContent().buttonColor as TButtonColor}
              onClick={getErrorContent().onClick} />
          </StyledOverlay>
        )
      }

      <Description>
        {Icon && <Icon />}
        <Percent onClick={onClickPercent} color={store.events.getColor(dayjs(day)).color}>Потенциал дня {percent}%</Percent>

        <ClickArea onClick={() => goToEvents()}>
          <Text>Атмосфера сегодняшнего дня наполнена {_events?.day?.atmosphere}</Text>
          <StyledButton
            onClick={() => { }}
            color='transparentWithBorder'>
            <ButtonContent onClick={onClickLearnMore}>Узнать больше <ChevronRightRoundBold /></ButtonContent>
          </StyledButton>
        </ClickArea>
      </Description>

      <WidgetsAccordion
        widgets={widgets()}
        title='Астрособытия'
        icon={<HalfMoonIcon/>}
      />

      <Todo ref={todoRef}>
        {
          _events?.day.toDo.length > 0 && <List
            icon={<MyDayGoodIcon />}
            title={'Делайте сегодня'}
            items={_events.day.toDo} />
        }

        {
          _events?.day.notToDo.length > 0 && <List
            icon={<MyDayBadIcon />}
            title={'Избегайте'}
            items={_events.day.notToDo} />
        }
      </Todo>

      <StyledButton
        onClick={onClickGoToEvents}
        color='transparentWithBorder'>
        <ButtonContent>Перейти к событиям <ChevronRightRoundBold /></ButtonContent>
      </StyledButton>

      <StyledMyDayFeedback
        date={day}
        feedback={_events?.feedback}
        hasFeedback={!!_events?.feedback} />
    </Container>
  )
})

const Container = styled(FlexContainer) <{ background: string }>`
  flex-direction: column;
  align-items: center;

  padding: 4rem 1rem 4rem 1rem;
  background: ${p => `url(${p.background}) 0 0/cover no-repeat`};
`

const Description = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
`

const ClickArea = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
`

const Percent = styled.div<{ color: string }>`
  background-color: var(--color-white);
  border-radius: 50px;
  padding: 0.5rem 0.75rem;
  margin-top: 1.25rem;
  color: ${p => p.color};
`

const Text = styled.div`
  color: var(--text-secondary);
  text-align: center;
  margin-top: 1rem;
  padding: 0 1rem;
  line-height: 24.3px;
  font-size: 1rem;
  font-weight: 400;
`

const StyledButton = styled(Button)`
  border: 1px solid var(--text-third);
  border-radius: 50px;
  margin: 1.5rem 0;
  padding: 0.25rem 0.15rem 0.25rem 1rem;
`

const ButtonContent = styled(FlexContainer)`
  align-items: center;

  color: var(--text-primary);
  font-size: 0.875rem;
  font-weight: 500;
`

const Todo = styled.div`
  margin-top: 1.5rem;

  & > :first-child {
    margin-bottom: 1.5rem;
  }
`

const StyledMyDayFeedback = styled(MyDayFeedback) <{ hasFeedback: boolean }>`
  width: 100%;
  margin-bottom: 0;
  text-align: center;

  ${p => p.hasFeedback && css`
    text-align: center;
  `}
`

const StyledOverlay = styled(Overlay)`
  left: auto;
  top: 4rem;
  backdrop-filter: brightness(0.9) blur(3px);
  align-items: start;

  .overlay-sibling {
    background: linear-gradient(180deg, #F3F7EF 0%, #FFFFFF 100%);
  }
;
`
