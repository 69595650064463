import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Button, Input, InputContainer } from "@chronos/ui";
import store from "../../store/Store";
import { getPromocodeStateLS, setPromocodeLS, setPromocodeStateLS } from "../../store/localStorageHelpers";
import Overlay from "../../components/Overlay";
import { isMobile } from "react-device-detect";
import { observer } from 'mobx-react-lite';
import { ArrowRight, Tag, TickInCircleIcon } from '../../assets/icons/system/system-icons';

type PromocodePropsType = {
  promo: string;
  headers?: {tentative: string}
  disabled?: boolean;
}

type CheckPromocodeResType = {
  type: string
  text?: string
}

export default observer(function Promocode(props: PromocodePropsType) {
  const [promocode, setPromoCode] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [success, setSuccess] = useState<CheckPromocodeResType | null>(null)
  const [error, setError] = useState<CheckPromocodeResType | null>(null)
  const promoError = store.promocode.promoError

  useEffect(() => {
    (async function () {
      if (getPromocodeStateLS() === 'update') {
        await checkPromocode(props.promo, props.headers)
      }
    })()
  }, [])

  useEffect(() => {
    setPromoCode(props.promo)
    props.promo && checkPromocode(props.promo, props.headers)
  }, [props.promo])

  useEffect(() => {
    if (promoError) {
      store.promocode.resetPromocode()
      setSuccess(null)
    }
  }, [promoError])

  const onChangeHandler = (value: string) => {
    setPromoCode(value)
    error && setError(null)
  }

  const onClickHandler = () => {
    if (success) {
      store.promocode.resetPromocode()
      setSuccess(null)
      setPromoCode('')
    } else {
      setShowModal(true)
    }
  }

  const checkPromocode = async (promocode: string, headers?: {tentative: string}) => {
    const res = await store.promocode.checkPromocode(promocode.trim(), headers)

    switch (res.type) {
      case 'success':
        setPromocodeLS(promocode)
        setSuccess(res)
        setTimeout(() => {
          setShowModal(false)
        }, 600)
        break
      case 'error':
        setPromocodeStateLS('update')
        setError(res)
        break
    }
  }

  return (
    <>
      <Container onClick={onClickHandler} disabled={props.disabled}>
        <div className={'left'}>
          <Tag/>
          <div>{success ? 'Промокод применен' : 'Применить промокод'}</div>
        </div>
        <div>{success ? <span>Сбросить</span> : <ArrowRight/>}</div>
      </Container>

      {showModal && <Overlay onClick={() => setShowModal(false)}>
          <Modal isMobile={isMobile}>
              <CustomInputContainer label='Ввод промокода' message={error ? error.text : undefined}>
                  <Input value={promocode} onChange={onChangeHandler}/>
              </CustomInputContainer>
              <CustomButton onClick={() => checkPromocode(promocode, props.headers)} disabled={!!success}>{success ? <TickInCircleIcon/> : 'Применить'}</CustomButton>
          </Modal>
      </Overlay>}
    </>)
})

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;

  color: var(--text-primary);
  margin-bottom: 1.5rem;
  padding: 0.8rem 1rem;
  //background-color: var(--switcher-background);
  border-radius: 0.5rem;
  cursor: pointer;
  align-items: center;

  ${p => p.disabled && css`
    opacity: 0.2;
    pointer-events: none;
  `}

  span {
    color: var(--color-gradus-blue-light);
  }

  .left {
    display: flex;

    svg {
      margin-right: 1rem;
    }
  }
`

const Modal = styled.div<{isMobile: boolean}>`
  width: 80%;
  background-color: white;
  padding: 1.5rem 1rem;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
`

const CustomInputContainer = styled(InputContainer)`
  label {
    padding-left: 0.1rem;
    margin-bottom: 0.5rem;
  }

  input {
    top: -5px;
    font-family: 'Apercu Pro', sans-serif;
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
    padding-bottom: 0.75rem;
    margin-bottom: 0.5rem;
    border-bottom: 0.125rem solid rgba(0, 0, 0, 0.1);
  }

  .ch-hint-message {
    color: var(--color-red);
    margin-bottom: 1.125rem;
  }
`;

const CustomButton = styled(Button)`
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 4px;
  color: white;
  background-color: var(--color-green);

  svg {
    color: var(--color-green);
  }
`
