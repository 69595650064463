import React from 'react';
import styled from 'styled-components';
import { Cross } from '../../../assets/icons/system/system-icons';
import { FlexContainer } from '../../../components/layout/elements';
import { windowsStore } from '../../../store/Windows';
import { WINDOW_ID_PAYWALL } from '../Paywall';

export const closePaywall = () => {
  windowsStore.close(WINDOW_ID_PAYWALL);
}

export const CloseIcon = ({
  className = '',
  onClick
}: {
  className?: string
  onClick: () => void;
}) => {
  return <Container className={className} onClick={onClick}>
    <Cross />
  </Container>
}

export const PaywallClose = ({
  className = ''
}: {
  className?: string; 
}) => {
  return <PaywallCloseContainer className={className}>
    <CloseIcon onClick={closePaywall} />
  </PaywallCloseContainer>
}

export const TrialClose = ({
  onClick,
	className,
}: {
  onClick: () => void;
	className?: string
}) => {
  return <TrialCloseContainer className={className ?? ''}>
    <CloseIcon onClick={onClick} />
  </TrialCloseContainer>
}

const Container = styled(FlexContainer)`
  width: 1.875rem;
  height: 1.875rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border-radius: 50%;
  background: var(--element-background-white-10);

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const PaywallCloseContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 1.675rem;
	z-index: var(--z-index-button);
`

const TrialCloseContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: var(--button-z-index);

  > div {
    background: var(--input-background);
    color: var(--input-border);
  }
`
