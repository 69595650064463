import styled from "styled-components"
import { Backgrounds, backgrounds } from "./bg"
import { Button, TButtonColor } from "../../ui/Button"
import { ReactNode } from "react"

interface BannerProps {
	className?: string
	background?: Backgrounds
	title: string
	text?: string
	date?: string
	buttonContent?: ReactNode
	buttonColor?: TButtonColor
	onClick?(): void
}

export const Banner = (props: BannerProps) => {
  const background = props.background && backgrounds[props.background]

	return (
		<Container className={props.className} background={background}>
			<div className='title'>{props.title}</div>
			{props.text && <div className='text'>{props.text}</div>}
			{props.date && <div className='date'>{props.date}</div>}
			{
				props.buttonContent && props.onClick &&
					<StyledButton className={'banner_button'}
											  onClick={props.onClick}
											  color={props.buttonColor}>
					  <div className='btn_content'>{props.buttonContent}</div>
					</StyledButton>
		  }
		</Container>
	)
}

const Container = styled.div<{background?: string}>`
	background: ${p => p.background ? `url(${p.background}) 0 0/cover no-repeat` : '#0000000A'};
	border-radius: 1rem;
	padding: 1rem;
	color: var(--text-primary-white);

	.title {
		font-size: 1.35rem;
		font-weight: 500;
		width: 70%;
	}

	.text {
		margin-top: 0.5rem;
		font-size: 0.875rem;
	}

	.date {
		margin-top: 0.5rem;
		font-size: 0.875rem;
	}

	@media (max-width: 800px) and (min-width: 400px)  {
		background: ${p => p.background ? `url(${p.background}) 0 0/cover no-repeat` : '#0000000A'};
		max-width: 430px;
	}

	@media (max-width: 390px) {
		.title {
			font-size: 1.125rem;
		}

		.text.date {
			margin-top: 0.35rem;
			font-size: 0.75rem;
		}
	}
`

const StyledButton = styled(Button)`
	padding: 0.5rem 0.75rem;
	margin-top: 0.75rem;
	width: 45%;

	.btn_content {
		white-space: nowrap;
		font-weight: 500;
		font-size: 0.75rem;
	}

	svg {
		width: 0.75rem;
		height: 0.75rem;
	}

	@media (max-width: 390px) {
		width: 50%;

		.btn_content {
			font-size: 0.7rem;
		}
	}
`
