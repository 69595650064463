import React, { useEffect } from 'react';
import { MainPage } from './modules/main-page/main-page';
import { Popups } from './modules/popups/Popups';
import { Windows } from './modules/windows/Windows';
import Store from './store/Store';
import ScheduleModal from './modules/consultations/components/ScheduleModal';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import ServicesModal from './modules/consultations/components/ServicesModal';
import store from './store/Store'
import { Onboarding } from './modules/onboarding/Onboarding';
import { checkProduct } from './modules/products/helpers/check-product';
import { isMobile } from "react-device-detect";
import { OpenMobileModal } from './components/OpenMobileModal';
import { Notifications } from './libs';
import { getAppLS, setAppLS } from './store/localStorageHelpers';
import dayjs from 'dayjs';

export default observer(function App() {

  const status = store.events.status;

  useEffect(() => {
    Store.init()
    .then(checkProduct)
  }, []);

  useEffect(() => {
    if(status) document.body.style.pointerEvents = 'initial';

    if (status === 'active') setAppSession()
  }, [status])

  const setAppSession = () => {
    const appLS = getAppLS()
    const newAppLS = {
      ...appLS,
      sessionCount: (appLS?.sessionCount ?? 0) + 1,
      lastSession: dayjs().toISOString(),
    }
    setAppLS(newAppLS)
  }

  return <>
    <MainPage />

    <Windows />
    <Popups />

    <Onboarding />

    <StyledNotifications/>

    {!isMobile && <OpenMobileModal />}

    {Store.showHandler.servicesModal && <ServicesModal />}
    {Store.showHandler.scheduleModal && <ScheduleModal />}
  </>
})

const StyledNotifications = styled(Notifications)`
  font-size: 0.8rem;
`
