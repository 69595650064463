import styled from "styled-components"
import { popupsStore } from "../../store/Popups"
import { Button } from "../../components/ui/Button"
import { HarmonicIcon, MediumIcon, TenseIcon } from "../../assets/icons/favorability"
import { CloseIcon } from "../paywall/components/CloseIcon"
import { content } from "./helpers/content"
import { FlexContainer } from "../../components/layout/elements"
import dayjs from "dayjs"
import { gtmSend } from "../../helpers/url"
import store from "../../store/Store"
import { openMyDay } from "../my-day/my-day"


const WINDOW_ID_POTENTIAL = 'WINDOW_ID_POTENTIAL'

export const openPotential = (fromMainPage?: boolean, day?: dayjs.Dayjs) => popupsStore.open(
  WINDOW_ID_POTENTIAL,
  <Potential fromMainPage={fromMainPage}/>
)

export const closePotential = () => popupsStore.close(
  WINDOW_ID_POTENTIAL
)

interface PotentialProps {
  fromMainPage?: boolean
  day?: dayjs.Dayjs
}

export const Potential = (props: PotentialProps) => {
  const {
    fromMainPage = false,
    day,
  } = props

  const userId = store.sessionData?.id;

  const onClickHandler = () => {
    if (fromMainPage) {
      openMyDay(day)
      gtmSend({ 'event': 'indi_potential_back_to_feed_info', 'user_id': userId ? userId : '' })
    } else {
      closePotential()
      gtmSend({ 'event': 'indi_potential_back_to_feed', 'user_id': userId ? userId : '' })
    }
  }

  const backToMainPage = () => {
    closePotential()
    gtmSend({ 'event': 'indi_potential_back_to_main', 'user_id': userId ? userId : '' })
  }

  return (
    <Container>
      <div>
        <StyledCloseIcon  onClick={closePotential}/>
        <Icons>
          <TenseIcon/>
          <HarmonicIcon/>
          <MediumIcon/>
        </Icons>

        <Title>
          Как устроен<br/>
          потенциал дня
        </Title>

        <Text>
          {
            content.map((item, index) => (
              <div key={`potential_text-item_${index}`} className="text-item">
                <div className="title">{item.title}</div>
                <div className="text">{item.text}</div>
              </div>
            ))
          }
        </Text>
      </div>

      <Buttons>
        <Button onClick={onClickHandler}>
          {
            fromMainPage
              ? 'Перейти к ленте событий'
              : 'Вернуться к ленте событий'
          }
        </Button>

        {
          fromMainPage && <Button
                            onClick={backToMainPage}
                            color='gray'
                          >
                            Вернуться на главную
                          </Button>
        }
      </Buttons>
    </Container>
  )
}

const Container = styled(FlexContainer)`
  flex-direction: column;
  justify-content: space-between;

  min-height: 95vh;
  padding: 1rem;
  margin-bottom: 2rem;
`
const Icons = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  margin-top: 3rem;

  svg {
    width: 2rem;
    height: 2rem;
  }

  svg:nth-child(2) {
    width: 3rem;
    height: 3rem;
  }
`
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  width: 100%;
  margin-top: 1.5rem;
`
const Text = styled.div`
  margin-top: 0.5rem;

  .text-item {
    margin-top: 1rem;
    line-height: 23.2px;
  }

  .title {
    font-weight: 500;
  }

  .text {
    color: var(--text-secondary);
  }

`

const Buttons = styled.div`
  margin-top: 2rem;

  div:nth-child(2) {
    margin-top: 1rem;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 1rem;
  top: 2rem;

  color: #939393;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 50%;
`
