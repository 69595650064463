import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import store from '../../store/Store';

import {
	clearOnboardings,
	disableEvents,
	enableEvents,
	getOnboardingLS,
	hideEvents,
	setOverlayActive,
	setPopupPosition,
	showEvents
} from './helpers/dom';

import { popupsStore } from '../../store/Popups';
import { windowsStore } from '../../store/Windows';
import { OnboardingBlock } from './components/onboarding-block';
import { OnboardingPopup } from './components/onboarding-popup';
import './helpers/styles.css';
import { openMyDay } from '../my-day/my-day';

export const stepsCount = 4;

export const Onboarding = observer(() => {
  const [step, setStep] = useState(-1);
  const myBlockLoaded = store.events.myBlockLoaded;
  const monthBlockLoaded = store.events.monthBlockLoaded;
  const windows = windowsStore.getWindows();
  const popups = popupsStore.getPopups();

  const profile = store.profile;
  const isHasPersonalData = profile.firstName && profile.birth.dateTime && profile.birth.place?.name

  const onClose = () => {
    enableEvents();
    clearOnboardings();
    const popUp = document.querySelectorAll('.popup-scroll')
    popUp.forEach(item => item.classList.remove('block-scroll'))
  }

  useEffect(() => {
		if (step === 2) {
			const popUp = document.querySelectorAll('.popup-scroll')
   		popUp.forEach(item => item.classList.add('block-scroll'))
		}
  }, [windows, popups, step])

  useEffect(() => {
    const loaded = myBlockLoaded && monthBlockLoaded;

    if(loaded) {
      if(step === 0) {
        setTimeout(() => {
          setOverlayActive(step);
          setPopupPosition(step);
          disableEvents();
        }, 500)
      } else if(step === 1) {
        openMyDay();

        setTimeout(() => {
          setOverlayActive(step);
          setPopupPosition(step);
          showEvents();
        }, 50);
      } else if(step === 2 ) {
				setOverlayActive(step);
        setTimeout(() => {
					setOverlayActive(step);
          setPopupPosition(step);
          hideEvents();
        }, 50);
      } else if(step === 3) {
        setTimeout(() => {
					setOverlayActive(step);
          setPopupPosition(step);
        }, 50);
      } else if(step === 4) {
        setTimeout(() => {
					setOverlayActive(step);
          setPopupPosition(step);
        }, 50);
      }
    }
  }, [step, myBlockLoaded, monthBlockLoaded]);

  if (profile.id === -1 || isHasPersonalData || getOnboardingLS() || store.userNotUseIndi) return null

  if (step === -1) return <OnboardingBlock setStep={setStep}/>;

  return <OnboardingPopup step={step} onClose={onClose} setStep={setStep} />;
})
