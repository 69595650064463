import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { AppInstallPopup } from '../../components/AppInstallPopup';
import { DESKTOP_GAP, DESKTOP_WIDTH } from '../../helpers/ui';
import { gtmSend } from '../../helpers/url';
import { useAppInstall } from '../../hooks/app-install-hooks';
import { useMainPopupScroll } from '../../hooks/popup-hooks';
import store from '../../store/Store';
import { getAppLS, getMainPageLS, setMainPageLS } from '../../store/localStorageHelpers';
import { SubscribeModes } from '../payment/helpers/tariffs';
import { openPotential } from '../potential/Potential';
import { Shutter } from '../shutter/Shutter';
import { MainPageActiveSubscriptionScreen } from './components/active-subscription/main-page-active-subscription-screen';
import { MainPageBlocks } from './components/main-page-blocks';
import { MainPageHeader } from './components/main-page-header';
import { MainPageInfoBanner } from './components/main-page-info-banner';
import { TUserStateKeys, states } from './helpers/states';
import Overlay from '../../components/Overlay';

import { Indi, Logging } from 'src/libs';


export const MainPage = observer(() => {
  const [selectedDay, setSelectedDay] = useState(dayjs().startOf('day'))
  const [scrolled, setScrolled] = useState(false)
  const [showInfoBanner, setShowInfoBanner] = useState(false)

  const overlayRef = useRef<HTMLDivElement>(null);
  const mainPageRef = useRef<HTMLDivElement>(null);
  const newCounterRef = useRef<HTMLDivElement>(null);

  const mainPageLS = getMainPageLS()
  const appLS = getAppLS()

  const isAuthFinished = store.isAuthFinished
  const status = store.events.status;
  const userId = store.sessionData?.id || '';
  const info = store.sessionData?.indi;
  const shutterRef = store.shutter.shutterRef

  useEffect(() => {
    store.logger.startLoggingSession(Logging.Screens.MAIN)

    return () => {
      store.logger.createLoggingSession(Logging.Screens.MAIN)
    }
  }, [])

  const subscribeMode = (() => {
    const hasFourteenTrialOffer = info?.offers.find(item => item.type === Indi.IndiUseOffer.Offers.FOURTEEN_FREE_DAYS);

    if (hasFourteenTrialOffer?.available) {
      return SubscribeModes.TRIAL_FOURTEEN;
    }

		if (!info?.trialExpiryDate) {
			return SubscribeModes.TRIAL;
		}

		if (info?.trialExpiryDate !== info?.expiryDate) {
			return SubscribeModes.RESUBSCRIBE;
		}

		return SubscribeModes.SUBSCRIBE;
  })();

  // пока фризим по 6003
  // const freePeriod = (() => {
  //   if (subscribeMode === SubscribeModes.TRIAL_FOURTEEN) {
  //     return FreePeriod.FOURTEEN_FREE_DAYS
  //   }

  //   if (subscribeMode === SubscribeModes.TRIAL) {
  //     return FreePeriod.TRIAL
  //   }
  // })();

  const { rollDown } = useMainPopupScroll(
    shutterRef!,
    overlayRef,
    mainPageRef,
    newCounterRef,
    userId.toString()
  );

  // пока фризим по 6003

  // useEffect(() => {
  //   if(!isAuthFinished) return;

  //   // неавторизованный пользователь и лендинг еще не показывали
  //   if (!userId) {
	//     if (localStorage.getItem('landing_shown')) return;
  //     window.localStorage.setItem('landing_shown', 'true');
  //   }

  //   // у пользователя уже был триал
  //   if (!freePeriod) return;

  //   setTimeout(() => {
  //     openPayLandingWindow(freePeriod);
  //     history.push('/paywall?utm_source=indiapp&utm_medium=landing');
  //   }, 30000);

  //   gtmSend({'event': 'indi_chronos-plus-landing-pop-up_visit_all', 'user_id': userId || 'unauthorized'})

  //   // console.log(`isAuthFinished - ${isAuthFinished} status - ${status} userId - ${userId} subscribeMode - ${subscribeMode} freePeriod - ${freePeriod}`)

  // }, [isAuthFinished, userId])

  useEffect(() => {
    const showInfoBanner = () => {
      if (newCounterRef.current?.style.display === 'none' && !mainPageLS.infoBanner?.closed) {
        setShowInfoBanner(true)
      }
    }

    showInfoBanner()

    shutterRef && shutterRef.current?.addEventListener('touchend', showInfoBanner)

    return () => {
      shutterRef && shutterRef.current?.removeEventListener('touchend', showInfoBanner)
    }
  }, [mainPageLS])

  const { isVisible, installApp } = useAppInstall();

  let stateName: TUserStateKeys | null = store.events.status || 'unlogin';

  if (!stateName) return null;

  const state = states[stateName];
  const Container = isMobile ? Mobile : Desktop;

  const Icon = store.events.getIcon(dayjs(selectedDay))

  const onCloseInfoBanner = () => {
    const newMainPageLS = {
      ...mainPageLS,
      infoBanner: {
        ...mainPageLS.infoBanner,
        closed: true
      }
    }

    setMainPageLS(newMainPageLS)
    setShowInfoBanner(false)
  }

  const onClickInfoBanner = () => {
    openPotential(true, selectedDay)
    gtmSend({ 'event': 'indi_potential_info_banner', 'user_id': userId ? userId : '' })
    onCloseInfoBanner()
  }

  const getShowInfoBanner = useCallback(() => {
    const isOldUser = dayjs().diff(dayjs(store.sessionData?.indi?.startDate), 'day') > 1

    if (
      showInfoBanner &&
      Icon &&
      store.events.getPercent(selectedDay) <= 50
    ) {
      if (isOldUser) {
        return true
      } else {
        return appLS?.sessionCount > 1 || dayjs().diff(dayjs(appLS.lastSession), 'day') > 1
      }
    } else {
      return false
    }
  }, [Icon, appLS.lastSession, appLS?.sessionCount, selectedDay, showInfoBanner])

  return (
    <>
      <Container ref={mainPageRef}>
        <MainPageHeader
          state={state}
          selected={selectedDay}
          scrolled={scrolled}
          setSelected={setSelectedDay}
        />

        <Content
          isMobile={isMobile}
          isActive={status === 'active'}
        >
          {
            status === 'active'
              ? <MainPageActiveSubscriptionScreen
                  selectedDay={selectedDay}
                  setSelectedDay={setSelectedDay}
                  scrolled={scrolled}
                  setScrolled={setScrolled}
                  newCounterRef={newCounterRef}
                />
              : <MainPageBlocks stateName={stateName} />
          }
        </Content>

        {
          getShowInfoBanner() && (
            <StyledMainPageInfoBanner
              icon={Icon && <Icon/>}
              text='Узнайте как формируется ваш потенциал дня'
              onClick={onClickInfoBanner}
              onClose={onCloseInfoBanner}
            />
          )
        }

        <Shutter newCounterRef={newCounterRef} rollDown={rollDown}/>
        <Overlay
          className={'main-page_overlay'}
          overlayRef={overlayRef}
        />

      </Container>
      <div className='no-print'>
      {isVisible && <AppInstallPopup onClick={installApp} />}
      </div>
    </>
  )
});

const Mobile = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  height: 100%
`
const Desktop = styled.div`
  width: ${DESKTOP_WIDTH}px;
  height: 100vh;
  overflow: auto;
  position: absolute;
  left: 50%;
  transform: translateX(calc(-100% - ${DESKTOP_GAP / 2}px));
`

const Content = styled.div<{isMobile: boolean, isActive?: boolean}>`
  ${p => p.isMobile && css`
    height: 100%;
    overflow-y: scroll;
  `};

  @media (max-height: 700px) {
    ${p => !p.isActive && css`
      padding-bottom: 8rem;
    `}
  }
`

const StyledMainPageInfoBanner = styled(MainPageInfoBanner)`
  top: 70vh;

  @media (max-height: 680px) {
    top: 65vh;
  }
`
