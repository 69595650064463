import React from 'react';
import styled from 'styled-components';
import { FlexContainer, WindowContainer } from '../../../../components/layout/elements';
import { windowsStore } from '../../../../store/Windows';
import { WindowHeader } from '../../../windows/components/WindowHeader';
import { Article } from '../../helpers/blog';

export const WINDOW_ID_ARTICLE_VIDEO: string = 'WINDOW_ID_ARTICLE_VIDEO';

interface MainPageBlogArticleVideoProps {
  article: Article;
  title: string;
}

const getEmbeddedVideoUrl = (url: string): string => {
  const videoId = url.split('v=')[1];
  return `https://www.youtube.com/embed/${videoId}`;
};

export const MainPageBlogArticleVideo: React.FC<
  MainPageBlogArticleVideoProps
> = ({ article, title }) => {

  const [isPlaying, setIsPlaying] = React.useState(false);

  const descriptionNotNull = article.description || '';

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const embeddedVideoUrl = article.video
    ? getEmbeddedVideoUrl(article.video)
    : '';

  const onClose = () => windowsStore.close(WINDOW_ID_ARTICLE_VIDEO)

  return (
    <WindowContainer>
      <WindowHeader title={title} onBack={onClose}/>
      <Container>
        <VideoContainer onClick={handlePlayPause}>
          <iframe
            src={embeddedVideoUrl}
            title={article.title}
            allowFullScreen
          ></iframe>
        </VideoContainer>
        <ContentContainer>
          <Title>{article.title}</Title>
          <AuthorName>{article.author}</AuthorName>
          <Description dangerouslySetInnerHTML={{ __html: descriptionNotNull }} />
          {/* <BlogArticlesFeedBack date={''} /> */}
        </ContentContainer>
      </Container>
    </WindowContainer>
  );
};

const Container = styled.div`
  margin: 0;
  padding: 0;
`;
const ContentContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  text-align: center;
`;
const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const Title = styled.div`
  color: #000000b2;
  font-size: 24px;
  font-weight: 500;
  margin-top: 32px;
`;
const AuthorName = styled.div`
  color: #00000066;
  margin-top: 1rem;
  font-size: 16px;
  margin-bottom: 20px;
`;
const Description = styled(FlexContainer)`
  font-size: 18px;
  font-weight: 400;
  color: #000000e5;
  margin-bottom: 24px;
  text-align: left;
  line-height: 1.4;
`;
