import { useEffect } from 'react'
import styled from 'styled-components'
import { OldPrice, Subtitle } from '../../components/UpdateTariffModal'
import { MainPadding } from '../../components/layout/elements'
import { Button, TButtonColor } from '../../components/ui/Button'
import { gtmSend } from '../../helpers/url'
import store from '../../store/Store'
import { openPaywallWindow } from '../main-page/components/main-page-blocks-subscription-banner'
import { MyDayOffer } from '../my-day/components/my-day-offer'

type TSubscriptionIsNotPaidType = 'all' | 'day' | 'updateTariff' | 'year';

const options = {
  all: {
    title: 'Подписка не оплачена',
    text: 'Получите персональный расширенный прогноз на месяцы вперёд',
    subtitle: '',
    buttonTitle: 'Узнать больше',
  },
  day: {
    title: 'События на оплаченный период закончились',
    text: 'Следующие события будут доступны после списания средств за соответсвующий период',
    subtitle: '',
    buttonTitle: '',
  },
  updateTariff: {
    title: 'Посмотрите прогноз на полгода вперед и получите месяц в подарок',
    text: 'Оформите подписку на 6 месяцев — так дешевле',
    subtitle: 'Списание раз в 6 месяцев',
    buttonTitle: <p>Обновить за <OldPrice discount={true}>3000₽</OldPrice> 2499₽</p>,
  },
  year: {
    title: 'События на оплаченный период закончились',
    text: '',
    subtitle: '',
    buttonTitle: 'Продлить подписку',
  }
}

interface SubscriptionIsNotPaidProps {
  type?: TSubscriptionIsNotPaidType
  onClick?(): void
  title?: string
  buttonTitle?: string
  buttonColor?: TButtonColor
  className?: string
}

export default function MissingDateOrPayment(props: SubscriptionIsNotPaidProps) {
  const {
    type = 'all',
    onClick,
    title,
    buttonTitle,
    buttonColor,
    className = '',
  } = props

  const option = options[type];
  const userId = store.sessionData?.id;

  useEffect(() => {
    if (type === 'year') {
      gtmSend({ 'event': 'ind_prognosis_my-day-out-of-zone_1year-bunner_shown', 'user_id': userId})
    }
  }, [])

  const onClickHandler = () => {
    if (onClick) {
      onClick()
    } else {
        openPaywallWindow()
        gtmSend({ 'event': 'indi_main_inactive-period_extend_click', 'user_id': userId ? userId : '' })
    }
  }

  if (title) {
    return (
      <Content className={className}>
        <Circle/>
        <Title>{title}</Title>
        {buttonTitle && <StyledButton color={buttonColor} onClick={onClickHandler}>{buttonTitle}</StyledButton>}
      </Content>
    )
  }

  return (
    <Content className={className} type={type}>
      {
        type === 'year'
          ? <MyDayOffer outOfZone/>
          : <>
              <Circle/>
              <Title>{option.title}</Title>
              <Description>{option.text}</Description>
              {option.buttonTitle && <Button color={type === 'updateTariff' ? 'gradient' : undefined} onClick={onClickHandler}>{option.buttonTitle}</Button>}
              {option.subtitle && <StyledSubtitle>{option.subtitle}</StyledSubtitle>}
            </>
      }
    </Content>
  )
}

const Content = styled(MainPadding)<{type?: TSubscriptionIsNotPaidType}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: ${p => p.type === 'all' ? '80vh' : '40vh'};
  width: 80%;
  height: 70vh;
  text-align: center;
  margin: 0 auto;
`

const Circle = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border: 3px solid #C2C2C2;
  border-radius: 50%;
  margin-bottom: 1rem;
`

const Title = styled.div`
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 0.5rem;
`

const Description = styled.div`
  font-size: 0.75rem;
  margin-bottom: 1rem;
`

const StyledSubtitle = styled(Subtitle)`
  color: var(--text-secondary)
`

const StyledButton = styled(Button)`
  margin-top: 1.5rem;
`
