import { Indi } from "src/libs"
import styled, { css } from "styled-components"

import { WindowContainer } from "../../components/layout/elements"
import { Button } from "../../components/ui/Button"
import { ROUTES } from "../../helpers/routes"
import { useBackWindow } from "../../hooks/router-hooks"
import { windowsStore } from "../../store/Windows"
import { WindowHeader } from "../windows/components/WindowHeader"
import { astroEventsContent } from "./helpers/content"
import store from "../../store/Store"
import { observer } from "mobx-react-lite"
import { useLayoutEffect, useRef, useState } from "react"
import { useMoveShutter } from "../../hooks/popup-hooks"


export const WINDOW_ID_ASTRO_EVENT = 'WINDOW_ID_ASTRO_EVENT'

export const openAstroEventWindow = (
  type: keyof Indi.GetAstroEvents.AstroEvents,
  interval: string,
  title: string,
  showStatic: boolean
) => {
  windowsStore.open(
    WINDOW_ID_ASTRO_EVENT,
    <AstroEvent
      type={type}
      interval={interval}
      showStatic={showStatic}
      title={title}
    />,
    undefined,
    false,
    true,
  )
}

interface AstroEventProps {
  type: keyof Indi.GetAstroEvents.AstroEvents
  interval: string
  showStatic: boolean
  title: string
}

export const AstroEvent = observer((props: AstroEventProps) => {
  const {
    type,
    interval,
    showStatic,
    title,
  } = props

  const [addGap, setAddGap] = useState(false)

  const windowRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const shutterRef = store.shutter.shutterRef

  useMoveShutter(windowRef, shutterRef)

  useBackWindow(ROUTES.ASTRO_EVENT, WINDOW_ID_ASTRO_EVENT);

  useLayoutEffect(() => {
    const windowRefHeight = windowRef?.current?.getBoundingClientRect().height ?? 0
    const containerHeight = containerRef?.current?.getBoundingClientRect().height ?? 0

    if (
      windowRefHeight - containerHeight < 200 &&
      windowRefHeight - containerHeight > 0
    ) {
      setAddGap(true)
    }
  }, [])

  const content = astroEventsContent[type]

  const onClose = () => {
    windowsStore.close(WINDOW_ID_ASTRO_EVENT)
  }

  return (
    <WindowContainer ref={windowRef}>
      <WindowHeader title={title} onBack={onClose}/>
      <Container ref={containerRef} addGap={addGap}>
        <Content>
          <Interval>{interval}</Interval>
          {
            showStatic && content?.static && <Static>
              {content?.static.map((item, index) => <div key={`astro-event-static-${index}`} className='static_item'>{item}</div>)}
            </Static>
          }
          <Description>
            {content.description.map((item, index) => <div key={`astro-event-description-${index}`}>{item}</div>)}
          </Description>
        </Content>

        <Button onClick={onClose}>Закрыть</Button>
      </Container>
    </WindowContainer>
  )
})

const Container = styled.div<{addGap: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1rem;

  ${p => p.addGap && css`
    padding-bottom: 11rem;
  `}
`

const Content = styled.div`
  margin-bottom: 1.5rem;
`

const Interval = styled.div`
  color: var(--text-third);
  margin-bottom: 1rem;
`

const Static = styled.div`
  padding: 1rem;
  background: #F495501A;
  border-radius: 24px;
  margin-bottom: 1rem;

  .static_item {
    margin-bottom: 0.5rem;
  }
`

const Description = styled.div`
  margin-bottom: 1.5rem;

  div {
    margin-bottom: 1rem;
  }
`
