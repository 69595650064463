import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { WINDOW_ID_EDIT, Edit } from '../modules/edit/Edit';
import { windowsStore } from '../store/Windows';
import { Button } from './ui/Button';
import store from '../store/Store';
import dayjs from 'dayjs';
import { HeaderAvatar } from '../modules/main-page/components/main-page-header-avatar';
import { FlexContainer } from './layout/elements';
import { defaultPlace } from '../modules/registration/Registration';

export const defaultDateTime = dayjs();

export const PersonInfo = observer(() => {
  const { firstName, birth: { dateTime = defaultDateTime, place = defaultPlace } } = store.profile;
  const userId = store.sessionData?.id;
  const avatarUrl = '';
  const userName = firstName || 'Профиль';

  const date = dayjs(dateTime || defaultDateTime).utc().format('DD MMM YYYY, HH:mm');

  const openEditWindow = () => {
    windowsStore.open(WINDOW_ID_EDIT,
      <Edit />
    )
  }

  return <Container>
    <Name>
      <HeaderAvatar avatarUrl={avatarUrl} userId={userId} name={userName} />
      {firstName}
    </Name>
    <Date>{date}</Date>
    <Place>{place?.name}</Place>
    <Button onClick={openEditWindow} color="white" small>Редактировать</Button>
  </Container>
})

const Container = styled.div`
  background: var(--input-background);
  border-radius: 16px;
  padding: 0.875rem 0.75rem 0.75rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
`

const Name = styled(FlexContainer)`
  align-items: center;
  gap: 0.675rem;
  margin-bottom: 0.6rem;
  color: var(--text-primary);
  font-size: 1.25rem;
  line-height: 110%;
`

const Date = styled.div`
  margin-bottom: 0.125rem;
  line-height: 135%;
`

const Place = styled.div`
  margin-bottom: 0.875rem;
  line-height: 135%;
`
