import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PersonalForm, { IPersonalForm } from '../../../../components/forms/PersonalForm';
import { toUTCDateTimeFormat } from '../../../../helpers/dates';
import { CloseIcon } from '../../../paywall/components/CloseIcon';
import { SOLAR } from '../../helpers/constants';
import { IClientOpts } from "./types";
import { toDateTime } from '../../../../libs';


interface IPersonalData {
  data: IClientOpts
  closeEditForm(): void
  editSolar(date: string, time: string, place: string, lon: number, lat: number): void
}

export default observer(function EditForm(props: IPersonalData): JSX.Element {
  const [formData, setFormData] = useState<IPersonalForm>({name: '', dateTime: '', place: null, location: null});
  const currentDateTime = toUTCDateTimeFormat(props.data.date, props.data.time)
  const originDateTime = toUTCDateTimeFormat(props.data.originDate, props.data.originTime)

  useEffect(() => {
    setFormData({
      name: props.data.name,
      dateTime: currentDateTime,
      place: {
        name: props.data.place1,
        lon: props.data.lon1,
        lat: props.data.lat1,
      },
      location: {
        name: props.data.place2,
        lon: props.data.lon2,
        lat: props.data.lat2,
      }
    })

  }, []);

  const confirmHandler = async (formData: IPersonalForm) => {
    const {date, time} = toDateTime(dayjs(formData.dateTime).toISOString())
    props.editSolar(date, time, formData.location?.name!, formData.location?.lon!, formData.location?.lat!)
    props.closeEditForm()
  }

  const changeDateFormat = (value: string) => {
    return dayjs(value).format('DD.MM.YYYY')
  }

  const getDateOptions = () => {
    const birthDay = changeDateFormat(originDateTime)
    const dayBefore = changeDateFormat(dayjs(originDateTime).subtract(1, 'day').toString())
    const dayAfter = changeDateFormat(dayjs(originDateTime).add(1, 'day').toString())

    const options: { value: string, label: string }[] = [{value: birthDay, label: birthDay}]
    options.unshift({value: dayBefore, label: dayBefore})
    options.push({value: dayAfter, label: dayAfter})

    return options
  }

  return <Container>
    <Header>
      <Title>Редактирование данных</Title><CloseIcon onClick={props.closeEditForm}/>
    </Header>

    <PersonalForm data={formData} isEdit={true} submitTitle={'Продолжить'} onSubmit={confirmHandler} product={SOLAR}
                  blockedFields={['name', 'place']} dateOptions={getDateOptions()} originDateTime={originDateTime}/>
  </Container>
});

const Container = styled.main`
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  margin-bottom: 1rem;

  svg {
    width: 1.5rem;
    position: absolute;
    top: 20%;
    right: 0;
    cursor: pointer;
  }
`

const Title = styled.div`
  font-size: 1rem;
  color: var(--text-primary);
  line-height: 2rem;
  margin-bottom: 1rem;
  text-align: center;
`
