import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { ChevronRightIcon } from '../../assets/icons/system/system-icons';
import { defaultDateTime } from '../../components/PersonInfo';
import { Banner } from '../../components/banners/Banner/Banner';
import { PrognosesForm } from '../../components/forms/PrognosesForm';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import { Button } from '../../components/ui/Button';
import api from '../../helpers/api';
import { isDateInvalid, isNameInvalid, isPlaceInvalid, isTimeInvalid } from '../../helpers/fields';
import { ROUTES } from '../../helpers/routes';
import { RectificationRequest } from '../../components/RectificationRequest';
import { openPaymentWindow } from '../paywall/Paywall';
import { IPlace } from '../../libs';

import { MainPageHeaderLoginButton } from '../main-page/components/main-page-header-login-button';
import { states } from '../main-page/helpers/states';
import { CloseIcon } from '../paywall/components/CloseIcon';
import { defaultPlace } from '../registration/Registration';
import { WindowHeader } from '../windows/components/WindowHeader';

import { gtmSend } from '../../helpers/url';
import { useBackWindow } from '../../hooks/router-hooks';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';


export const WINDOW_ID_EDIT: string = 'WINDOW_ID_EDIT';

interface EditProps {
  beforeAuth?: boolean;
  onProfileUpdate?: () => void;
  beforePayment?: boolean;
  showToaster?: () => void;
}

export const openEditWindow = (props?: EditProps) => {
  windowsStore.open(
    WINDOW_ID_EDIT,
    <Edit
      beforeAuth={props?.beforeAuth}
      beforePayment={props?.beforePayment}
      onProfileUpdate={props?.onProfileUpdate}
      showToaster={props?.showToaster}
    />,
    'fade'
  )
}

export const Edit = observer((props: EditProps) => {
  useBackWindow(ROUTES.EDIT, WINDOW_ID_EDIT);

  const { firstName, birth: { dateTime, place: initialPlace }, originalDate } = store.profile;
  //@ts-ignore
  const initialDate = dayjs.utc(dateTime || defaultDateTime)?.toISOString();
  const initialTime = dayjs.utc(dateTime || defaultDateTime)?.toISOString();

  const [name, setName] = useState(firstName ?? '');
  const [nameError, setNameError] = useState('');

  const [date, setDate] = useState(initialDate ?? '');
  const [dateError, setDateError] = useState('');

  const [time, setTime] = useState(initialTime ?? '');
  const [timeError, setTimeError] = useState('');

  const [place, setPlace] = useState<IPlace>(initialPlace || defaultPlace);
  const [placeError, setPlaceError] = useState('');

  const [disableButton, setDisableButton] = useState(false);

  const userId = store.sessionData?.id || '';

  useEffect(() => {
    gtmSend({'event': 'indi_prognosis_personal-info_visit', 'userId': userId || 'unauthorized'})
  }, [])

  useEffect(() => {
    // if (props.beforeAuth) return;
    checkDate(date, originalDate);
  }, [date, originalDate])

  const checkDate = (date: string, originalDate?: string) => {
    const dateError: string = isDateInvalid(date, originalDate);
    if (dateError) {
      setDateError(dateError);
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }

  const changeDateFormat = (value: string) => {
    return dayjs(value).format('YYYY-MM-DD')
  }

  const minDate = originalDate && changeDateFormat(dayjs(originalDate, 'DD.MM.YYYY').subtract(1, 'day').toString())
  const maxDate = originalDate && changeDateFormat(dayjs(originalDate, 'DD.MM.YYYY').add(1, 'day').toString())

  const onClose = () => windowsStore.close(WINDOW_ID_EDIT);

  const submit = async () => {
    try {
      gtmSend({'event': 'indi_profile_my_recti_data_done', 'user_id': userId})

      if (checkInvalidData()) return

      const _profile = {
        ...store.profile,
        firstName: name,
        birth: {
          //FIXME: fix types
          dateTime: {
            date: dayjs.utc(date).format('DD.MM.YYYY'),
            time: dayjs.utc(time).format('HH:mm:ss')
          } as any,
          place
        }
      }

      // if beforeAuth
      if (props.beforeAuth === true) {
        await store.updateProfile(store.convertProfile(_profile));
        props.onProfileUpdate?.();
        onClose();
        return ;
      }
      //

      setDisableButton(true);

      await api.updateProfile({
        firstName: name,
        birth: {
          //FIXME: fix types
          //@ts-ignore
          dateTime: {
            date: dayjs.utc(date).format('DD.MM.YYYY'),
            time: dayjs.utc(time).format('HH:mm:ss')
          },
          place
        }
      } as any) // FIXME: any

      await store.getProfile();
      onClose();
    } catch (e) {
      console.error(`Submit error: ${e}`)
    }
  }

  const onChangeNameHandler = (value: string) => {
    setName(value);
    setNameError('');
  }

  const onChangeDateHandler = (value: string) => {
    setDate(value);
    setDateError('');
  }

  const onChangeTimeHandler = (time: string) => {
    setTime(time)
    setTimeError('')
  }

  const onChangePalaceHandler = (value: any) => {
    if (typeof value === 'object') {
      setPlace({ name: value.name, lat: value.lat, lon: value.lon })
      setPlaceError('')
    } else {
      setPlace({ name: value, lat: 0, lon: 0 });
    }
  }

  const checkInvalidData = () => {
    if (dateError || timeError) return true;

    const { nameErr, dateErr, placeErr, timeErr } = {
      nameErr: isNameInvalid(name),
      dateErr: isDateInvalid(date!),
      timeErr: isTimeInvalid(time!),
      placeErr: isPlaceInvalid(place)}

    if (nameErr) setNameError(nameErr);
    if (dateErr) setDateError(dateErr);
    if (timeErr) setTimeError(timeErr);
    if (placeErr) setPlaceError(placeErr);

    return nameErr || dateErr || timeErr || placeErr
  }

  const orderRectification = () => {
    store.rectification.orderRectification()
    gtmSend({'event': 'indi_profile_rectification_my_recti_data', 'user_id': userId})
    props.showToaster?.()
  }

  return <WindowContainer >
    {
      props.beforeAuth && (
        <>
          <Header>
            <MainPageHeaderLoginButton state={states[store.events.status || 'unlogin']} />
            <CloseIcon onClick={onClose}/>
          </Header>
          <Title>
            <div>Укажите данные рождения</div>
            <div className='subtitle'>Chronos использует их, чтобы построить ваш прогноз</div>
          </Title>
        </>
      )
    }

    <WindowHeader title={'Мои данные'} onBack={onClose} />
    <Content>
      <PrognosesForm
        name={name}
        nameOnChange={onChangeNameHandler}
        nameError={nameError}
        date={date!}
        dateOnChange={onChangeDateHandler}
        dateOnInvalid={() => setDateError('Введите корректную дату')}
        dateError={dateError}
        time={time!}
        timeOnChange={onChangeTimeHandler}
        timeOnInvalid={() => setTimeError('Введите корректное время')}
        timeError={timeError}
        place={place}
        placeOnChange={onChangePalaceHandler}
        placeError={placeError}
        minDate={minDate}
        maxDate={maxDate}
        originalDate={originalDate}
        sendAnalitics={props.beforeAuth}
      />

      <StyledBanner
        title='Узнайте точное время рождения'
        text='Свяжемся с вами, чтобы рассказать об услуге ректификации (уточнение времени рождения)'
        buttonContent={<ButtonContent>Отправить заявку <ChevronRightIcon /></ButtonContent>}
        onClick={orderRectification}
      />

    </Content>
    <Footer>
      <Button onClick={submit} disabled={disableButton}>Сохранить</Button>
    </Footer>
  </WindowContainer>
})

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`

const Title = styled.div`
  position: relative;
  padding: 1rem;

  font-size: 1.5rem;
  font-weight: 500;

  text-align: center;

  & .subtitle {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`;

const Content = styled(MainPadding)`
  flex: 1;
`

const Footer = styled(MainPadding)`
  height: min-content;
`

const StyledBanner = styled(Banner)`
  margin-top: 1.5rem;

  .title {
    font-size: 1.125rem;
    color: var(--text-primary);
    width: 100%;
  }

  .text {
    color: var(--text-primary);
  }

  .banner_button {
    margin-top: 1.25rem;
    width: fit-content;
  }
`

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.875rem;
  padding: 0 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-white);
  }
`
