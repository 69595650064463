import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import UpdateTariffModal from '../../components/UpdateTariffModal';
import UsersPullBanner from '../../components/UsersPullBanner';
import { toISOString } from '../../helpers/dates';
import { gtmSend } from '../../helpers/url';
import { useCalendarVisible } from '../../hooks/calendar-hooks';
import { useElementOnScreen } from '../../hooks/dom-hooks';
import { usePopupScrollToTop } from '../../hooks/popup-hooks';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';
import { TariffPeriod } from '../payment/helpers/tariffs';
import { WINDOW_PAYMENT_PAYWALL, openPaymentPaywallWindow } from '../payment/components/payment-paywall';
import SubscriptionIsNotPaid from '../subscription/MissingDateOrPayment';
import { MyDayDay, MyDayDayLoading } from './components/my-day-day';


export const MyDayItem = observer(({
  selectedDay,
  setSelectedDay,
  containerRef,
}: {
  selectedDay: Dayjs,
  setSelectedDay: React.Dispatch<React.SetStateAction<Dayjs>>
  containerRef: React.RefObject<HTMLDivElement>
}) => {

  const userId = store.sessionData?.id;

  const ref = useRef<HTMLDivElement>(null);

  const [bannerRef, isBannerVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.8
  });

  useEffect(() => {
    if (isBannerVisible) {
      const event = { 'event': 'ind_fb-bunner_feed_shown', 'user_id': userId ? userId : '' }
      gtmSend(event);
    }
  }, [isBannerVisible, userId]);

  const events = store.events.events[toISOString(selectedDay.startOf('day'))];
  const expiryDate = store.sessionData?.indi?.expiryDate;
  const status = store.events.status;
  const isAuthFinished = store.isAuthFinished;

  useCalendarVisible(ref);
  usePopupScrollToTop(selectedDay);

  const onBannerClick = () => {

    const event = { 'event': 'ind_fb-bunner_feed_click', 'user_id': userId ? userId : '' };
    gtmSend(event);

    setTimeout(() => {
      window.location.reload();
    }, 16);
  }

  if(!isAuthFinished) return null;

  if (status !== 'active') {
    return <SubscriptionIsNotPaid/>
  }

  const overPeriodDay = selectedDay.isAfter(dayjs.tz(expiryDate));
  const overSixMonths = selectedDay.isAfter(dayjs.tz(expiryDate).add(6, 'month'));
  const activeTrial = store.trial.activeTrial;
  const showUpdateTariffModal = store.sessionData?.indi?.tariffId && !activeTrial && !overPeriodDay;
  const bannerCustomization = store.sessionData?.indi?.customization!;
	const subscriptionIsNotPaidType = overSixMonths || !store.sessionData?.indi || store.sessionData?.indi?.tariffId === 3 ? 'day' : 'year'

	const onClickHandler = () => {
		gtmSend({ 'event': 'ind_prognosis_check-our-bunner_click', 'user_id': userId})
		openPaymentPaywallWindow(TariffPeriod.YEAR, () => windowsStore.close(WINDOW_PAYMENT_PAYWALL))
	}

  return <Container className="ob-1" id="onboarding-my-day" ref={ref}>
    <Days>
      {!overPeriodDay && <>
        {events && <MyDayDay
											date={selectedDay}
											events={events.events}
											day={events.day}
											feedback={events?.feedback}
                      containerRef={containerRef}
                    />}
        {!events && <MyDayDayLoading date={selectedDay}  />}
      </>}

      {overPeriodDay && <SubscriptionIsNotPaid type={subscriptionIsNotPaidType} onClick={onClickHandler} />}
    </Days>

    {/* @ts-ignore */}
    {store.isShowUsersPullBanner && <StyledUserPullBanner data={bannerCustomization} onClick={onBannerClick} ref={bannerRef}/> }

    {(showUpdateTariffModal && (!store.isShowUsersPullBanner)) && <UpdateTariffModal data={store.tariffs.dataUpdateToSixMonthTariff} onClick={store.payments.paymentIndiProlongation} /> }
  </Container>

})

const Container = styled.div`
  padding-bottom: 4.5rem;
`

const Days = styled.div`
`
const StyledUserPullBanner = styled(UsersPullBanner)`
  margin: 2.5rem 0;
  padding-bottom: 3rem;
`
