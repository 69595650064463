import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { createRef, ReactElement, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css } from "styled-components";
import { history } from '../../../../MainRouter';
import IndiPrognosisBanner from '../../../../components/banners/IndiPrognosisBanner';
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import { IChartData } from '../../../../components/ui/Chart';
import ContextMenu from '../../../../components/ui/ContextMenu';
import DropDown from '../../../../components/ui/ContextMenu/DropDown';
import { contextButtonPositionType } from '../../../../components/ui/ContextMenu/types';
import Swiper, { ISwipeTab } from '../../../../components/ui/Swiper/Swiper';
import { TSignColor, getSignColor } from '../../../../helpers/colors';
import { ROUTES } from '../../../../helpers/routes';
import { copyToClipboard } from '../../../../helpers/strings';
import { gtmSend, searchParams } from '../../../../helpers/url';
import { useBackWindow } from '../../../../hooks/router-hooks';
import { IGetSign, signsIcons } from '../../../../store/Astrology';
import store from "../../../../store/Store";
import { windowsStore } from '../../../../store/Windows';
import { getPaymentIdLS, getPromocodeDataLS, getPromocodeLS, setConfirmationStepLS, setProductDataLS, setProductTokenLS, setPromocodeLS, setPromocodeStateLS } from '../../../../store/localStorageHelpers';
import { WindowHeader } from '../../../windows/components/WindowHeader';
import ProductConfirmation from '../../components/Confirmation';
import Footer from '../../components/Footer';
import { SYNASTRY, WINDOW_ID_CONFIRMATION, WINDOW_ID_SYNASTRY } from '../../helpers/constants';
import Category from "./Category";
import Summary from "./Summary";
import SynastryFinishing from './SynastryFinishing';
import { ChaptersDataType, IData, SignResponseType, SynastryDataType, SynastryPropsType } from "./types";
import { s3urls } from '../../../../helpers/api';
import { cards } from '../../../payment/helpers/tariffs';
import ReRecaptcha, { TReReCaptchaApi } from '../../../../components/ReRecaptcha';
import { Button, getGMT, show } from '../../../../libs';


export default observer(function Synastry(props: SynastryPropsType) {
	const pathname = `${ROUTES.PRODUCTS_SYNASTRY}/${props.token}`
  useBackWindow(pathname, WINDOW_ID_SYNASTRY);

	document.title = `Продукты • Совместимость в любви`;

  const [data, setData] = useState<IData | null>(null)
  const [signs, setSigns] = useState<number[] | null>(null)
  const [updatedPrice, setUpdatedPrice] = useState(0);
  const [contextMenuPosition, setContextMenuPosition] = useState<contextButtonPositionType>(null)
	const [ card, setCard ] = useState(cards[0]);

  const captchaRef = createRef<TReReCaptchaApi>();

  const discount = () => {
    const storePromoDiscount = store.promocode.promoData?.actions?.discount!;
    const lsPromoDiscount = getPromocodeDataLS()?.actions?.discount;
    return (storePromoDiscount > 0 && storePromoDiscount < 1)
      ? storePromoDiscount
      : (lsPromoDiscount > 0 && lsPromoDiscount < 1)
        ? lsPromoDiscount
        : 0;
  }
  const promocode = () => getPromocodeLS() ?? store.promocode.promoCode;
  const promoId = () => store.promocode.promoData?.id ?? getPromocodeDataLS()?.id;
  const isFreeCharge = () => {
    const storePromoDiscount = store.promocode.promoData?.actions?.discount!;
    const lsPromoDiscount = getPromocodeDataLS()?.actions?.discount;
    return storePromoDiscount === 1 || lsPromoDiscount === 1;
  }

  const [isWaiting, setIsWaiting] = useState(false);
  const freePayTimer = useRef<any>(null);

  const sp = searchParams()

  const synastryProduct = useMemo(() => {
    return store.products?.products?.find(p => p.code === 'SYNASTRY');
  }, [store.products.products]);

  const currentProductData = useMemo(() => {
    return store.products?.purchasedProducts?.find(p => p.id === props.token);
  }, [store.products.purchasedProducts]);

  let newMajorVersion = false;
  if(synastryProduct?.version && currentProductData?.version) {
    const [MV] = synastryProduct?.version.split('.');
    const [curMV] = currentProductData?.version.split('.');
    newMajorVersion = +MV > +curMV;
  }

  const getTabs = (): ISwipeTab[] => {
    const keys = data?.synastry && Object.keys(data.synastry)

    const tabs = keys?.map(item => {
      return {
        key: item,
        title: item === 'diagramData' ? 'Сводка' : data?.synastry[item as keyof ChaptersDataType].title,

        influence: data && data.synastry.diagramData.chaptersData[item as keyof ChaptersDataType]?.percent
      }
    })

    tabs && tabs.forEach((item, index) => {
      if (item.key === 'diagramData') {
        tabs.unshift(...tabs.splice(index, 1))
      }
    })

    const sortTabs = tabs && tabs.sort((firstTab, secondTab) => secondTab.influence! - firstTab.influence!)

    return sortTabs as ISwipeTab[]
  }

  const saveLink = async () => {
    try {
      const token = store.synastry.synastryId && await store.synastry.getShareToken(store.synastry.synastryId.toString())
      const link = `${window.location.host}/synastry/${token}/shared`

      copyToClipboard(link)

      if (isMobile) {
        window.navigator.share({
          title: 'Хронос',
          text: 'Синастрия',
          url: link
        })
      } else {
          copyToClipboard(link);

          show({
            text: 'Ссылка скопирована',
            type: 'success'
          })
      }
    } catch {
      show({
        text: 'Не удалось скопировать ссылку',
        type: 'error'
      })
    }
  }

  const buySynastry = () => {
    const synastryProductToken = 1

    windowsStore.open(WINDOW_ID_CONFIRMATION, <ProductConfirmation product={SYNASTRY}/>)
    store.payments.setToken(synastryProductToken)
  }

  useEffect(() => {
    if (props.sharedToken) {
      store.synastry.getSharedSynastry(props.sharedToken)
        //@ts-ignore
        .then(setData)

      store.products.setIsSharedProduct('synastry')
    } else {
      props.token && store.synastry.getSynastry(props.token)
        //@ts-ignore
        .then(setData)
    }

    store.products.getProducts();
    store.products.getPurchasedProducts();
    windowsStore.setContextMenu(synastryContextMenu)

    return () => {
      windowsStore.setContextMenu(null)
      props.sharedToken && store.products.setIsSharedProduct(null)
      store.synastry.trialSynastry && store.synastry.setShowedTrialSynastry(false)
    }
  }, [])

  useEffect(() => {
		getPrice()
  }, [newMajorVersion, card, captchaRef]);

  useEffect(() => {
    (async function () {
      if (data) {
        const getSignsData: IGetSign[] = []

        for (let i = 1; i < 3; i++) {
          //@ts-ignore
          getSignsData.push({date: data.input[`date${i}`], time: data.input[`time${i}`], lon: +(data.input[`lon${i}`]), lat: +(data.input[`lat${i}`])})
        }

        const result = await store.astrology.getSigns(getSignsData)
        const arrSigns = result?.map((item: SignResponseType) => ({
          key: item.birthSign,
          icon: signsIcons[item.birthSign]
        }))

        setSigns(arrSigns)
      }
    })()

    if (data?.isTrial) {
      store.synastry.setShowedTrialSynastry(true)
    }
  }, [data])

  const getPrice = async () => {
    if (!captchaRef.current) return

    const productCode = 'SYNASTRY'
    const productToken = 1
    const promo = promocode()

    store.payments.setToken(productToken)

    captchaRef.current?.reset()
    const captchaToken = await captchaRef.current?.executeAsync()

    const result = await store.payments.getPaymentConfig(productCode, promo, true, card.country, data?.id, undefined, data?.input, captchaToken);

    if(result?.options.amount) {
      setUpdatedPrice(result?.options.amount);
    }
  }

  const print = () => {
    setTimeout(() => {
      window.print()
      store.solar.setIsPrinting(false)
    }, 100)
  }

  const contextMenuState = [
    [
      {
        icon: null,
        title: 'Скопировать ссылку',
        action: saveLink,
      },
    ],
    [
      {
        icon: null,
        title: 'Скачать в PDF',
        action: print,
      },
    ],
    [
      {
        icon: null,
        title: 'Вернуться к покупкам',
        action: buySynastry,
      },
    ], [
      {
        icon: null,
        title: 'Сообщить о проблеме',
        className: 'drop',
        action: () => {
          const otherWindow = window.open();
          otherWindow!.opener = null;
          // @ts-ignore
          otherWindow!.location = s3urls.support;
        },
      },
    ],
  ];

  const contextMenuStateButton = [
    [
      {
        icon: null,
        title: 'Скопировать ссылку',
        action: saveLink,
      },
    ],
    [
      {
        icon: null,
        title: 'Скачать в PDF',
        action: print
      },
    ],
  ];

  const synastryContextMenu = <ContextMenu groupsItems={contextMenuState}/>

  if (!data) return <></>

  const place1 = data?.input.place1;
  const place2 = data?.input.place2;
  const time1 = data?.input.time1.slice(0, 5);
  const time2 = data?.input.time2.slice(0, 5);

  const getAspectTitle = (key: string) => {
    switch (key) {
      case 'sexuality':
        return 'Секс'
      case 'support':
        return 'Поддержка'
      case 'emotions':
        return 'Эмоции'
      case  'mentality':
        return 'Общение'
      case 'worth':
        return 'Уважение'
      default:
        return ''
    }
  }

  const getChartData = () => {
    const cartData: IChartData[] = []

    let key: keyof ChaptersDataType
    for (key in data.synastry.diagramData.chaptersData) {
      const item = {percent: data.synastry.diagramData.chaptersData[key].percent, color: `var(--color-${key})`, key: key as string, title: getAspectTitle(key)}
      cartData.push(item)
    }

    return cartData
  }

  const getCategory = () => {
    const categories: { component: ReactElement, influence: number }[] = []

    let key: keyof SynastryDataType
    for (key in data.synastry) {
      if (key !== 'diagramData') {
        const component = <Category key={key}
                                    categoryKey={key}
                                    signs={signs!}
                                    partners={data.input}
                                    data={data.synastry[key]}
                                    chartData={getChartData()}
                                    influenceData={data.synastry.diagramData.chaptersData[key]}/>

        categories.push({component, influence: data.synastry.diagramData.chaptersData[key].percent})
      }
    }

    const sortCategories = categories.sort((firstComponent, secondComponent) => {
      return secondComponent.influence - firstComponent.influence
    }).map(item => item.component)

    return sortCategories
  }

  const handleBuyButtonClick = () => {
    if(!synastryProduct?.id || !data.input) {
      return;
    }

    store.payments.setToken(synastryProduct.id);
    setProductTokenLS(synastryProduct.id.toString());
    store.products.setProductData(synastryProduct);
    setProductDataLS(synastryProduct);
    store.promocode.resetPromocode();
    let step = 1;
    if(data.input.date1 && data.input.time1 && data.input.name1 && data.input.lat1 && data.input.lon1) {
      const dateTime1 = dayjs.utc(`${data.input.date1.split('.').reverse().join('-')} ${data.input.time1}`).toISOString();

      store.synastry.setPartner(0, {
        name: data.input.name1,
        dateTime: dateTime1,
        place: {
          lat: +data.input.lat1,
          lon: +data.input.lon1,
          name: `${data.input.lat1} ${data.input.lon1}`
        },
        gmt: `${getGMT(dateTime1, +data.input.lat1, +data.input.lon1)}`,
      });

      step = 2;
    }

    if(data.input.date2 && data.input.time2 && data.input.name2 && data.input.lat2 && data.input.lon2) {
      const dateTime2 = dayjs.utc(`${data.input.date2.split('.').reverse().join('-')} ${data.input.time2}`).toISOString();

      store.synastry.setPartner(1, {
        name: data.input.name2,
        dateTime: dateTime2,
        place: {
          lat: +data.input.lat2,
          lon: +data.input.lon2,
          name: `${data.input.lat2} ${data.input.lon2}`
        },
        gmt: `${getGMT(dateTime2, +data.input.lat2, +data.input.lon2)}`,
      });

      step = 3;
    }

    setConfirmationStepLS(`${step}`)
    sp.set('update', 'true')
    sp.set('purchasedProductId', `${currentProductData?.id ? currentProductData.id : ''}`)
    windowsStore.open(WINDOW_ID_CONFIRMATION, <ProductConfirmation product={SYNASTRY}/>)
  }

  const successfulTrialPurchase = () => {
    let attempt = 0;

    setPromocodeLS('');
    setPromocodeStateLS('')

    setIsWaiting(true);

    if (freePayTimer.current) {
      clearTimeout(freePayTimer.current);
    }

    const send = () => {
      freePayTimer.current = setTimeout(() => {
        data.paymentId && store.products.checkProduct(data.paymentId)
          .then((res: any) => {
            if (res.isPaid) {
              store.payments.resetPaymentConfig()
              setIsWaiting(false);
              store.synastry.resetSynastry()
              store.promocode.resetPromocode()

              const userId = store.sessionData?.id
              gtmSend({'event': `sinastry_payment-success_Payment`, 'user_id': userId ? userId : ''})

              windowsStore.close(WINDOW_ID_SYNASTRY)
							windowsStore.open(WINDOW_ID_SYNASTRY, <Synastry token={res.purchasedProductId}/>, undefined, true)
            } else {
                if (attempt === 5) {
                  setIsWaiting(false);
                  show({
                    type: 'error',
                    text: "Произошла ошибка. Обратитесь в службу поддержки support@chronos.mg",
                    timeout: 5000
                  });
                } else {
                    attempt++
                    send()
                  }
              }
          })
      }, 1000)
    }

    send()
  }

  const buyTrialSynastry = async () => {
    if (!captchaRef.current) return

    store.payments.setProductCode('SYNASTRY')
    const productCode = 'SYNASTRY';

    captchaRef.current?.reset()
    const captchaToken = await captchaRef.current?.executeAsync()

    const result = await store.payments.getPaymentConfig(productCode, promocode(), false, card.country, data?.id, undefined, data.input, captchaToken);

    data.paymentId && store.payments.setPaymentId(data.paymentId)

    store.payments.paymentCloud(data.input, successfulTrialPurchase, undefined, false, data?.id)
  }

  const getFreeCharge = () => {
    data.paymentId && store.payments.setPaymentId(data.paymentId)

    const dataPost = {
      ...data.input,
      paymentId: getPaymentIdLS() ?? store.payments.paymentId,
      promocodeTitle: promocode,
      language: 'ru',
      promoId: promoId(),
      hash: store.auth.hash,
      trialId: store.synastry.synastryId
    }

  //@ts-ignore
    store.synastry.freeChargeSynastry({
      ...dataPost
    })
      .then(() => successfulTrialPurchase())

  }

  const onClose = () => {
    windowsStore.close(WINDOW_ID_SYNASTRY)
    history.push({pathname: '', search: sp.toString()})
  }

  return (
    <WindowContainer className={'window-container'}>
      <WindowHeader title='Синастрия' onBack={onClose} />
      <Content>
        <Swiper tabs={getTabs()}/>
        <Header>
          <Title>
            {data?.input.name1} и {data?.input.name2}
          </Title>
          <DatesWithAvatar>
            <Dates>
                <div>{data?.input.date1}, {time1}{place1 ? `, ${place1}` : ''}</div>
                {data?.input.date2}, {time2}{place2 ? `, ${place2}` : ''}<br />
            </Dates>
            <Avatar>
              <div className={'container'}>
                {signs && signs.map((sign: any, index: number) => {
                  const SignIcon = sign.icon;
                  const signColor = getSignColor(sign.key);
                  return <SignContainer
                    key={`sign_${index}`}
                    className={index === 0 ? 'first' : 'second'}
                    color={signColor}>
                      <SignIcon/>
                  </SignContainer>
                })}
              </div>
            </Avatar>
          </DatesWithAvatar>
        </Header>


        { newMajorVersion &&
            <UpdateBlock>
              <h3>Доступно обновление!</h3>
              <p>Мы выпустили крупное обновление для документа и специально для вас подготовили скидку</p>
              <Button onClick={handleBuyButtonClick}>
                <span>
                  Обновить за&nbsp;
                  { updatedPrice && <OldPrice>${synastryProduct?.price?.value}₽ </OldPrice>}
                  { updatedPrice ? updatedPrice : synastryProduct?.price?.value}₽
                </span>
              </Button>
            </UpdateBlock>
        }

        <Summary chartData={getChartData()} sections={data.synastry.diagramData.sections} text={data.synastry.diagramData.text}/>

        <StyledIndiPrognosisBanner
          link='https://feed.chronos.ru/indi_prognosis_free?utm_source=synastry'
          startShow={dayjs('2022-12-27 00:01')}
          endShow={dayjs('2023-01-09 23:59')}
        />

        <Categories trial={store.synastry.trialSynastry} className="categories">{getCategory()}</Categories>

        {!props.sharedToken && !store.synastry.trialSynastry && <Footer buyProduct={buySynastry}
                                    setContextMenuPosition={(value: { top: number, left: number }) => setContextMenuPosition(value)}/>}

        {contextMenuPosition && <DropDown contextButtonPosition={contextMenuPosition}
                                                    groupsItems={contextMenuStateButton}
                                                    onClose={() => setContextMenuPosition(null)}/>}

        {data.isTrial && <SynastryFinishing buy={buyTrialSynastry}
                                            getFreeCharge={getFreeCharge}
                                            price={updatedPrice}
                                            discount={discount()}
                                            promocode={promocode()}
                                            isFreeCharge={isFreeCharge()}
                                            setCard={setCard}
                                            card={card}
                                            disabled={isWaiting}/>}
      </Content>

      <ReRecaptcha size="invisible" badge="bottomleft" ref={captchaRef}/>
    </WindowContainer>
  );
})

const Content = styled(MainPadding)`
  /* margin-top: 1rem; TODO: Xo13 */
`



const SignContainer = styled.div<{color: TSignColor}>`
  width: 4rem;
  height: 4rem;
  background: ${p => `var(--circle-zodiacs-elements-${p.color})`};
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 60%;
    height: 60%;
  }
`;

const UpdateBlock = styled.div`
  background-color: var(--button-light-bg);
  padding: 1.3125rem 1rem 1rem;
  border-radius: 0.5rem;

  & > h3 {
    margin: 0 0 0.5rem;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    color: black;
  }

  & > p {
    margin: 0 0 0.75rem;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
    color: var(--text-primary);
  }

  & > button {
    width: 100%;
  }

  margin-bottom: 1rem;
`

const OldPrice = styled.span`
  text-decoration: line-through;
`

const Categories = styled.div<{trial: boolean}>`
  position: relative;

  ${props => props.trial && css`
    height: 12rem;
    overflow: hidden;

    &:after {
      content: '';
      width: 100%;
      height: 5rem;
      position: absolute;
      bottom: 0;
      background: linear-gradient(0deg, rgba(248, 251, 254, 0.8) 50%, rgba(248, 251, 254, 0.7) 30%, rgba(248, 251, 254, 0.6) 20%);
      -webkit-box-shadow: 0px -37px 30px 1px var(--text-secondary-white);
      -moz-box-shadow: 0px -37px 30px 1px var(--text-secondary-white);
      box-shadow: 0px -37px 30px 1px var(--text-secondary-white);
    }

    @media print {
      height: auto;
      overflow: visible;

      &::after {
        content: none;
      }

    }
  `}
`

const Dates = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: var(--text-third);
  margin-top: 8px;

  > div {
    margin-bottom: 3px;
  }
`

const StyledIndiPrognosisBanner = styled(IndiPrognosisBanner)`
  margin-bottom: 2rem;
`;

export const CustomButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 3rem;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0.2px;

  & svg {
    margin: 0 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(180deg);
  }
`

const Header = styled.div`
  position: relative;
  /* margin-top: 1.5rem; TODO: Xo13*/
`


const Title = styled.div`
  font-size: 2rem;
  font-weight: 500;
  text-align: left;
  margin-top: 0.5rem;
`


const DatesWithAvatar  = styled.div`
  display: flex;
  gap: 1rem;
  align-items: top;
  margin: 1rem 0;
`

const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;

  .container {
    position: relative;
    width: 8rem;
    height: 4rem;
  }

  .first {
    position: absolute;
    left: 8px;
    border: 3px solid white;
    z-index: 1;
  }

  .second {
    position: absolute;
    left: 64px;
  }
`
