import { observer } from "mobx-react-lite"
import styled from "styled-components"
import moon from "../../assets/images/celestial_bodies/moon.png"
import sun from "../../assets/images/celestial_bodies/sun.png"
import mercury from "../../assets/images/celestial_bodies/mercury.png"
import venus from "../../assets/images/celestial_bodies/venus.png"
import mars from "../../assets/images/celestial_bodies/mars.png"
import corridor from "../../assets/images/celestial_bodies/corridor.png"
import dayjs from "dayjs"
import { Indi } from "src/libs"
import { astroEventsContent } from "./helpers/content"
import { openAstroEventWindow } from "./AstroEvent"
import { getFormattedInterval } from "../../helpers/dates"
import { getDaysWord } from "../../helpers/words"
import { gtmSend } from "../../helpers/url"
import store from "../../store/Store"


interface WidgetAstroEventsProps {
  className?: string
  day: string
  type: keyof Indi.GetAstroEvents.AstroEvents
  retro?: Indi.GetAstroEvents.Interval
  staticBefore?: Indi.GetAstroEvents.Interval
  staticAfter?: Indi.GetAstroEvents.Interval
  eclipse?: Indi.GetAstroEvents.Interval
  onClick?(): void
}

const celestialBodies = {
  [Indi.GetAstroEvents.AstroEventsKey.LUNAR_ECLIPSE]: moon,
  [Indi.GetAstroEvents.AstroEventsKey.SOLAR_ECLIPSE]: sun,
  [Indi.GetAstroEvents.AstroEventsKey.ECLIPSE_CORRIDOR]: corridor,
  [Indi.GetAstroEvents.AstroEventsKey.MERCURY]: mercury,
  [Indi.GetAstroEvents.AstroEventsKey.VENUS]: venus,
  [Indi.GetAstroEvents.AstroEventsKey.MARS]: mars,
}

export const WidgetAstroEvents = observer((props: WidgetAstroEventsProps) => {
  const {
    className,
    day,
    type,
    retro,
    staticBefore,
    staticAfter,
    eclipse,
    onClick,
  } = props

  const userId = store.sessionData?.id;
  
  const utcDay = dayjs.utc(day)

  const getInterval = () => {
    const isFullFormat = Boolean(
      (
        type ===  Indi.GetAstroEvents.AstroEventsKey.LUNAR_ECLIPSE ||
        type ===  Indi.GetAstroEvents.AstroEventsKey.SOLAR_ECLIPSE
      ) &&
      eclipse?.from &&
      eclipse?.to &&
      !dayjs(eclipse?.from).isSame(dayjs(eclipse?.to), 'day')
    )

    return getFormattedInterval(
      dayjs(retro?.from || eclipse?.from).format('DD.MM.YYYY HH:mm:ss'),
      dayjs(retro?.to || eclipse?.to).format('DD.MM.YYYY HH:mm:ss'),
      isFullFormat,
      true
    )
  }

  const isStatic =
    Boolean(staticBefore?.from && staticBefore?.to && dayjs(day).isBetween(dayjs(staticBefore?.from).startOf('day'), dayjs(staticBefore?.to).endOf('day'))) ||
    Boolean(staticAfter?.from && staticAfter?.to && dayjs(day).isBetween(dayjs(staticAfter?.from).startOf('day'), dayjs(staticAfter?.to).endOf('day')))

  const isRetro =
    dayjs(day).isBetween(dayjs(retro?.from).startOf('day'), dayjs(retro?.to)) ||
    dayjs(day).isBetween(dayjs(retro?.from).startOf('day'), dayjs(retro?.to))

  const getStage = () => {
    const daysLeft = dayjs(retro?.from || eclipse?.from).startOf('day').diff(dayjs(utcDay.format('DD.MM.YYYY'), 'DD.MM.YYYY'), 'day')

    if (isStatic) {
      return 'статика'
    }

    if (isRetro) {
      return 'ретроградность'
    }

    if (0 < daysLeft && daysLeft < 15) {
      return `до начала ${daysLeft} ${getDaysWord(daysLeft, true)}`
    }

    return ''
  }

  const onClickHandler = () => {
    gtmSend({ 'event': astroEventsContent[type].event, 'user_id': userId ? userId : '' })
    openAstroEventWindow(type, getInterval(), astroEventsContent[type].title, isStatic)
    onClick?.()
  }

  return (
    <Container className={`astroEvent ${className ?? ''}`} onClick={onClickHandler}>
      <div>
        <div className='subtitle'>
          {getInterval()} {getStage() && <span>&bull; {getStage()}</span>}
        </div>
        <div className='title'>{astroEventsContent[type].title}</div>
      </div>
      <div>
        <CelestialBody src={celestialBodies[type]} alt='celestial_body'/>
      </div>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  min-height: 5rem;
  background: var(--color-white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 24px;
  padding: 1rem;

  .title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 0.25rem;
  }

  .subtitle {
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--text-third);

    span {
      color: var(--text-secondary);
    }
  }
`

const CelestialBody = styled.img`
  width: 3rem;
  height: 3rem;
`
