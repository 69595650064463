import dayjs from 'dayjs';
import { setDayjsDate } from '../../../helpers/dates';
import { RefObject, useRef, useState } from 'react';

export type TDayType = 'default' | 'disabled' | 'today' | 'selected'

export type TDay = {
  type: TDayType;
  date: dayjs.Dayjs;
}

export enum DayType {
	SELECTED = 'selected',
	TODAY = 'today',
	DEFAULT = 'default',
	DISABLED = 'disabled',
}

export interface Day {
	date: dayjs.Dayjs
	type: DayType
}

export const weekDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

const WEEK_DAYS_FROM_MONDAY = [6, 0, 1, 2, 3, 4, 5]
const DAYS_IN_WEEK = 7

export const MIN_SWIPE_REQUIRED = 35;

export const getDays = (selected: dayjs.Dayjs, firstDay: dayjs.Dayjs, startDate: dayjs.Dayjs) => {
  const days: TDay[] = [];
  for(let i = 0; i < 7; i ++) {
    const date = dayjs(firstDay).add(i, 'day');
    let type: TDayType = 'default';

    if(startDate.startOf('day').add(-7, 'day').isAfter(date.startOf('day'))) {
      type = 'disabled';
    } else if(selected.diff(date, 'day') === 0){
      type = 'selected';
    } else if(dayjs().startOf('day').diff(date, 'day') === 0) {
      type = 'today';
    }

    days.push({
      type,
      date: date
    });
  }

  return days;
}

// export const getMont = (days: TDay[]) => {
//   const firstDayMonth = dayjs(days[0].date).format('MMMM');
//   let counter = 0;
//   //days.forEach(day => {
//   for(const day of days) {
//     if(firstDayMonth === dayjs(day.date).format('MMMM')){
//       counter++;
//       if(counter >= 4) return firstDayMonth;
//     } else {
//       return dayjs(day.date).format('MMMM')
//     }
//   };
//   return '';
// }

export const getCalendarTitle = (date: dayjs.Dayjs, month: number, year: number) => {
	if (dayjs(date).month() !== month || dayjs(date).year() !== year) {
		return dayjs().set('year', year).set('month', month).format('MMMM, YYYY');
	}

  if (dayjs(date).isToday()) {
    return dayjs(date).format('DD MMMM') + ', сегодня';
  }

  if (dayjs(date).isTomorrow()) {
    return dayjs(date).format('DD MMMM') + ', завтра';
  }

  return dayjs(date).format('DD MMMM');
}

const getDayOfWeek = (date: dayjs.Dayjs) => {
	const dayOfWeek = dayjs(date).day();
	return WEEK_DAYS_FROM_MONDAY[dayOfWeek];
}

const getDayFromAnotherMonth = (year: number, month: number, week: number, monthStartsOn: number, weeksInMonth: number, daysInMonth: number) => {
	const getMonth = () => {
		if (week === 0) {
			return month === 0 ? 11 : month - 1
		} else {
				return month === 11 ? 0 : month + 1
		}
	}

	const _month = getMonth()
	const _year = month === 11 ? year - 1 : year

	let start = 1
	let end = setDayjsDate(_year, _month).daysInMonth() - (monthStartsOn - 1)

	if ((week) > weeksInMonth) {
		const lastDay = dayjs().year(year).month(month).date(daysInMonth)
		const extraDays = 7 - lastDay.day()
		start += extraDays
	}

	return () => {
		if (week === 0) {
			return setDayjsDate(_year, _month, end++)
		} else {
				return setDayjsDate(_year, _month, start++)
		}
	}
}

const getDayType = (date: dayjs.Dayjs, selected: dayjs.Dayjs) => {
	if (date.isSame(selected, 'day')) return DayType.SELECTED
	if (date.isToday()) return DayType.TODAY
	if (date.isBefore(dayjs())) return DayType.DISABLED
	return DayType.DEFAULT
}

export const getMonthData = (year: number, month: number, selected: dayjs.Dayjs) => {
	const result: Array<Day>[] = []
	const date = setDayjsDate(year, month)
	const daysInMonth = date.daysInMonth()
	const monthStartsOn = getDayOfWeek(date)
	const weeks = 6
	const weeksInMonth = Math.floor((daysInMonth + monthStartsOn) / DAYS_IN_WEEK)

	let day = 1;

	for (let i = 0; i < weeks; i++) {
		result[i] = [];
		const getDay = getDayFromAnotherMonth(year, month, i, monthStartsOn, weeksInMonth, daysInMonth)

		for (let j = 0; j < DAYS_IN_WEEK; j++) {
			if ((i === 0 && j < monthStartsOn) || day > daysInMonth) {
				const date = getDay()

				result[i][j] = {
					date,
					type: DayType.DISABLED
				}
			} else {
					const date = setDayjsDate(year, month, day++)

					result[i][j] = {
						date,
						type: getDayType(date, selected)
					}
			}
		}
	}

	return result;
}

export function getTouchEventData(
  e:
    | TouchEvent
    | MouseEvent
    | React.TouchEvent<HTMLElement>
    | React.MouseEvent<HTMLElement>
) {
  return 'changedTouches' in e ? e.changedTouches[0] : e;
}

export function getRefValue<C>(ref: RefObject<C>) {
  return ref.current as C;
}

export function useStateRef<S>(
  defaultValue: S
): [S, (value: S) => void, RefObject<S>] {
  const ref = useRef<S>(defaultValue);
  const [state, _setState] = useState<S>(defaultValue);
  const setState = (value: S) => {
    _setState(value);
    ref.current = value;
  };

  return [state, setState, ref];
}
