import { observer } from 'mobx-react-lite';
import { MainPadding } from '../../../../components/layout/elements';
import { blogGroups } from '../../helpers/blog';
import { MainPageBlogGroup } from './main-page-blog-group';

interface MainPageBlogProps {
  rollDown: () => void
}

export const MainPageBlog = observer((props: MainPageBlogProps) => {
  return (
    <MainPadding className="no-print">
      {blogGroups.map((group, index) => (
        <MainPageBlogGroup
          key={`blog-group-${index}`}
          title={group.title}
          articles={group.articles}
          rotation={group.rotation}
          rollDown={props.rollDown}
        />
      ))}
    </MainPadding>
  );
});
